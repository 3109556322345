import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { request } from 'octiv-utilities/Request';

export const useRegionsFind = (input, options) => {
  const {
    filter = {},
    paging = {},
    include = undefined,
    ...queryParams
  } = input || {};

  return useQuery(
    ['regions', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/regions`,
        params: {
          filter: {
            isActive: filter.isActive,
          },
          include,
          sort: input.sort || 'name',
          ...queryParams,
          ...paging,
        },
      }),
    options
  );
};

export const useRegionsCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/regions`,
        data: {
          name: data.name,
          countryCodeIso2: data.countryCodeIso2,
          currencies: data.currencies,
          timezones: data.timezones,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['regions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useRegionsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/regions/${id}`,
        data: {
          name: data.name,
          countryCodeIso2: data.countryCodeIso2,
          currencies: data.currencies,
          timezones: data.timezones,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['regions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useRegionsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/regions/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['regions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useRegionsUpdateActivateDeactivate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/regions/activate-deactivate/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['regions'] });

      options?.onSuccess?.(...res);
    },
  });
};
