import moment from 'moment';
import { Box, Button, Text } from 'octiv-components';
import { useBooking } from 'octiv-hooks';
import { getDateFromTime, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Event = ({
  isWidget,
  leadUserId,
  leadToken,
  leadSessions,
  data,
  data: {
    class: {
      name: className,
      isDisplayInstructorName,
      isVirtual,
      instructor: classInstructor,
      settings,
    },
    name,
    instructor,
    endTime,
    startTime,
    meetingUrl,
    isDisplayBookingDetails,
  },
  onClick,
  onSuccessBook,
  onSuccessCancel,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    attendanceString,
    bookingsPercentage,
    bookingsWithLimit,
    canBook,
    canCancel,
    canCheckIn,
    canCheckOut,
    canDropIn,
    hasAttended,
    isBooked,
    isLoadingBooking,
    isLoadingCancelling,
    isPastBookingThreshold,
    isPastEvent,
    isWaitlist,
    onBookCheckInOut,
    onCancel,
  } = useBooking({
    isWidget,
    leadUserId,
    leadToken,
    leadSessions,
    event: data,
    onSuccessBook,
    onSuccessCancel,
  });

  const canShowButton =
    hasAttended ||
    canBook ||
    canCheckIn ||
    canCheckOut ||
    (canDropIn && !isVirtual) ||
    (canDropIn && isVirtual && !isBooked);

  return (
    <Box
      hasRadius
      isFlex
      flexDirection='column'
      justifyContent='center'
      overflow='hidden'
      {...props}
    >
      <Box isFlex bg='grey6' flexDirection='column' onClick={onClick}>
        {attendanceString || isVirtual ? (
          <Box
            isFlex
            alignItems='center'
            bg='grey5'
            justifyContent='center'
            p={1}
          >
            <Text color='grey1' textAlign='center' variant='caption'>
              {`${isVirtual ? t('virtualClass') : ''}${
                isVirtual && attendanceString ? ' - ' : ''
              }${attendanceString || ''}`}
            </Text>
          </Box>
        ) : null}

        {!isPastEvent && !!meetingUrl ? (
          <Box
            isFlex
            alignItems='center'
            justifyContent='center'
            pl={1}
            pr={1}
            pt={1}
          >
            <Text color='grey1' textAlign='center' variant='caption'>
              {`${t('classHasMeetingUrlAssigned')}${
                isBooked ? ':' : `. ${t('bookingWillAllowAccess')}`
              }`}
              {isBooked && (
                <Text
                  as='a'
                  color='primary'
                  href={meetingUrl}
                  ml={1}
                  target='_blank'
                  textAlign='center'
                  variant='caption'
                >
                  {t('openMeeting')}
                </Text>
              )}
            </Text>
          </Box>
        ) : null}

        <Box isFlex alignItems='center' flex={1} p={2}>
          <Box
            hasRadius
            isFlex
            bg='grey7'
            borderColor={settings?.colours?.border || 'grey7'}
            borderStyle='solid'
            borderWidth={settings?.colours?.border ? '2px' : '0px'}
            flexDirection='column'
            height={11}
            justifyContent='center'
            px={2}
          >
            <Text isBold textAlign='center' variant='caption'>
              {startTime.slice(0, -3)}
            </Text>

            <Text color='grey1' textAlign='center' variant='tiny'>
              {`${moment(getDateFromTime({ time: endTime })).diff(
                moment(getDateFromTime({ time: startTime })),
                'minutes'
              )}m`}
            </Text>
          </Box>

          <Box isFlex flex={1} flexDirection='column' mx={2}>
            <Text isBold variant='caption'>
              {name || className}
            </Text>

            {isDisplayInstructorName ? (
              <Text color='grey1' variant='caption'>
                {getFullName(instructor || classInstructor)}
              </Text>
            ) : null}
          </Box>

          {isDisplayBookingDetails
            ? (!isPastBookingThreshold || canCheckIn) && (
                <Text color='grey1' mr={2} textAlign='right' variant='caption'>
                  {bookingsWithLimit}
                </Text>
              )
            : null}

          {(!isPastBookingThreshold ||
            canCancel ||
            hasAttended ||
            canCheckIn ||
            canCheckOut ||
            canDropIn) && (
            <Box isFlex>
              {canCancel ? (
                <Button
                  hasBorder
                  color='danger'
                  isLoading={isLoadingCancelling}
                  mr={canShowButton ? 2 : undefined}
                  text={t('cancel')}
                  onClick={onCancel}
                />
              ) : null}

              {canShowButton ? (
                <Button
                  color={
                    hasAttended
                      ? 'grey5'
                      : isBooked
                      ? 'primary'
                      : isWaitlist
                      ? 'warning'
                      : 'grey2'
                  }
                  hasBorder={!hasAttended && !canCheckIn && !canCheckOut}
                  icon={hasAttended ? 'check_circle' : undefined}
                  iconProps={{ color: 'primary' }}
                  isDisabled={
                    hasAttended ||
                    isWaitlist ||
                    (isBooked && !canCheckIn && !canCheckOut)
                  }
                  isLoading={isLoadingBooking}
                  text={
                    hasAttended
                      ? undefined
                      : canCheckIn || (!isWidget && canDropIn && !isVirtual)
                      ? t('checkIn')
                      : canCheckOut
                      ? t('checkOut')
                      : isBooked
                      ? t('booked')
                      : isWaitlist
                      ? t('waitlist')
                      : t('book')
                  }
                  onClick={onBookCheckInOut}
                />
              ) : null}
            </Box>
          )}
        </Box>

        {isDisplayBookingDetails ? (
          <Box bg='grey5'>
            <Box
              bg='primary'
              style={{
                height: 2,
                width: bookingsPercentage,
              }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Event;
