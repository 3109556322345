import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Field, Row } from 'octiv-components';
import { paymentTypes } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  initialValues,
  isGoCardless,
  isStripeConnect,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        userIds: initialValues.userIds,
        debitStatusId: undefined,
        debitDayId: undefined,
      }}
      validationSchema={Yup.object().shape({
        userIds: Yup.array().required(t('required')).min(1, t('required')),
        debitStatusId: Yup.string().required(t('required')),
        debitDayId: Yup.string().when('debitStatusId', {
          is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
          then: Yup.string().required(t('required')),
        }),
        nextBillingDate: Yup.string().when('debitStatusId', {
          is: (value) => Number(value) === paymentTypes.CARD,
          then: Yup.string().required(t('required')),
        }),
      })}
    >
      {({ handleSubmit, values: { debitStatusId } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isSelect
                label={t('paymentType')}
                name='debitStatusId'
                options={[
                  {
                    label: t('cashEftCard'),
                    value: paymentTypes.CASH,
                  },
                  ...(isGoCardless || isStripeConnect
                    ? [
                        {
                          label: t('debitOrder'),
                          value: paymentTypes.DEBIT_ORDER,
                        },
                      ]
                    : []),
                  {
                    label: t('noPayment'),
                    value: paymentTypes.NONE,
                  },
                  {
                    label: t('onlinePayment'),
                    value: paymentTypes.ONLINE,
                  },
                  {
                    label: t('cardSubscription'),
                    value: paymentTypes.CARD,
                  },
                ]}
              />
            </Col>

            {debitStatusId === paymentTypes.DEBIT_ORDER && (
              <Col>
                <Field
                  isSelect
                  label={t('debitDate')}
                  name='debitDayId'
                  options={[
                    { label: '1st', value: 1 },
                    { label: '5th', value: 6 },
                    { label: '15th', value: 2 },
                    { label: '25th', value: 3 },
                    { label: '27th', value: 5 },
                    { label: '28th', value: 7 },
                    { label: t('lastDayMonth'), value: 4 },
                  ]}
                />
              </Col>
            )}

            {debitStatusId === paymentTypes.CARD && (
              <Col>
                <Field
                  isDate
                  label={t('nextBillingDate')}
                  minDate={moment()}
                  name='nextBillingDate'
                />
              </Col>
            )}

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
