import _ from 'lodash';
import { InlineUser, Table, Text, Tooltip } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getMandateStatusDetails,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, setInvoices, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        Cell: ({ row: { original } }) => <InlineUser user={original} />,
      },
      {
        Header: t('sentOn'),
        id: 'paymentTokenLinkSentOn',
        Cell: ({ row: { original } }) =>
          getDateReadableDayMonthYear({
            date: original?.userTenant?.paymentTokenLinkSentOn,
          }),
      },
      {
        Header: t('status'),
        accessor: 'mandate',
        Cell: ({ row: { original } }) => {
          const { isActive, isInactive, status } = getMandateStatusDetails({
            status: original.userTenant?.mandate?.status,
            sentAt: original?.userTenant?.paymentTokenLinkSentOn,
          });

          return (
            <Text
              as='span'
              color={isActive ? 'success' : isInactive ? 'danger' : 'grey1'}
            >
              {status}
            </Text>
          );
        },
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({
          row: {
            original: { id, userTenant },
          },
        }) => {
          const { mandate } = userTenant;

          return (
            <Tooltip
              stopPropagation
              options={[
                ...(mandate?.status === 'active'
                  ? [{ label: t('view'), value: 'view' }]
                  : []),
                ...(!mandate?.status || mandate?.status === 'pending'
                  ? [
                      { label: t('openOnboardingLink'), value: 'openLink' },
                      { label: t('sendOnboardingLink'), value: 'sendLink' },
                    ]
                  : []),
                ...(['active', 'cancelled'].includes(mandate?.status)
                  ? [{ label: t('delete'), value: 'delete' }]
                  : []),
              ]}
              placement='left'
              onClick={({ value }) =>
                onClickAction({
                  action: value,
                  id,
                  token: mandate?.paymentMethod,
                  viewMandateLink:
                    mandate?.paymentMethodDetails[
                      _.camelCase(mandate?.paymentMethodDetails.type)
                    ].url,
                })
              }
            />
          );
        },
      },
    ],
    [onClickAction, t]
  );

  return (
    <Table
      {...props}
      columns={tableColumns}
      data={tableData}
      isVisiblePaginationTop={false}
    />
  );
};
