import { Alert, Modal } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import {
  useFinancesFindStripeConnectAccountCapabilities,
  useFinancesUpdateStripeConnectAccountCapabilities,
} from 'octiv-hooks/requests/Finances';
import { useLocationPaymentGatewaysFindStripeConnectDetails } from 'octiv-hooks/requests/LocationPaymentGateways';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import FormCapabilities from './FormCapabilities';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const [toggleCapabilities, setToggleCapabilities, resetToggleCapabilities] =
    useToggle();

  const { data: settings, isFetching: isFetchingSettings } =
    useLocationPaymentGatewaysFindStripeConnectDetails();

  const {
    data: accountCapabilities,
    isFetching: isFetchingAccountCapabilities,
  } = useFinancesFindStripeConnectAccountCapabilities(
    {
      locationId: toggleCapabilities.data?.locationId,
    },
    {
      enabled: !!toggleCapabilities.data?.locationId,
    }
  );

  const {
    isLoading: isUpdatingAccountCapabilities,
    mutate: putAccountCapabilitiesRequest,
  } = useFinancesUpdateStripeConnectAccountCapabilities({
    onSuccess: resetToggleCapabilities,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const hasNotOnboarded = !!settings?.find(
    (item) => item.hasOnboarded === false
  );

  const onClickAction = ({ action, locationId }) => {
    switch (action) {
      case 'manageAccountCapabilities':
        setToggleCapabilities({
          data: { locationId },
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggleCapabilities.isVisible && (
        <Modal
          isSidebar
          title={t('manageAccountCapabilities')}
          onClose={resetToggleCapabilities}
        >
          <FormCapabilities
            accountCapabilities={accountCapabilities || []}
            isFetchingAccountCapabilities={isFetchingAccountCapabilities}
            isLoading={isUpdatingAccountCapabilities}
            onSubmit={(values) =>
              putAccountCapabilitiesRequest({
                locationId: toggleCapabilities.data.locationId,
                ...values,
              })
            }
          />
        </Modal>
      )}

      <Setting title='Octiv Pay: Powered by Stripe Connect'>
        {hasNotOnboarded && (
          <Alert
            mb={4}
            subtitle={t('locationNotOnboardedTo', {
              paymentGateway: 'Stripe Connect',
            })}
            variant='danger'
          />
        )}

        <Table
          hasAlternateBackground
          data={settings || []}
          isLoading={isFetchingSettings}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
