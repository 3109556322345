import { Box, Button, Container } from 'octiv-components';
import Text from 'octiv-components/text/index';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import {
  useFinancesCreateStripeConnectAttachPaymentMethod,
  useFinancesCreateStripeConnectSetupIntent,
  useFinancesDeletePaystackSetupIntent,
  useFinancesDeleteStripeConnectSetupIntent,
  useFinancesFindPaystackPaymentMethods,
  useFinancesFindStripeConnectPaymentMethods,
  useFinancesFindStripeConnectPlatformCardPayments,
  useFinancesPaystackCreateSetupIntent,
} from 'octiv-hooks/requests/Finances';
import { paymentGateways } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import FormQuery from './FormQueryLocation';
import TableCardsGlobal from './TableCardsGlobal';
import TableCardsLocation from './TableCardsLocation';

export const CardsConnect = () => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { id: userId } = useSignedInUser();
  const {
    selectedLocation,
    tenant: { locations },
  } = useActiveUserTenant();
  const [searchParams] = useSearchParams();
  const locationIdFromUrl = searchParams.get('locationId')
    ? Number(searchParams.get('locationId'))
    : undefined;

  const locationOptions = locations?.filter(
    (location) =>
      location.paymentGatewayId === paymentGateways.STRIPE_CONNECT ||
      location.locationPaymentGateways
        .filter(
          (locationPaymntGateway) =>
            locationPaymntGateway.context === 'adhoc_payment'
        )
        .map((locationPaymntGateway) => locationPaymntGateway.paymentGatewayId)
        .includes(paymentGateways.PAYSTACK)
  );

  const [query, setQuery] = useState({
    locationId:
      locationIdFromUrl ??
      (locationOptions.includes(selectedLocation)
        ? selectedLocation.id
        : locationOptions[0]?.id),
  });

  const matchedLocation = locationOptions.find(
    (location) => location.id === query.locationId
  );

  const isStripeConnect =
    matchedLocation?.paymentGatewayId === paymentGateways.STRIPE_CONNECT;
  const isPaystack = matchedLocation?.locationPaymentGateways
    .map((item) => item.paymentGatewayId)
    .includes(paymentGateways.PAYSTACK);

  const paymentMethodsFindStripeConnect =
    useFinancesFindStripeConnectPaymentMethods(
      {
        refetchInterval: 30000,
        filter: {
          userId,
          locationId: query.locationId,
          type: 'card',
        },
      },
      {
        enabled: isStripeConnect && !isPaystack,
      }
    );

  const paymentMethodsFindPaystack = useFinancesFindPaystackPaymentMethods(
    {
      refetchInterval: 30000,
      filter: {
        userId,
        locationId: query.locationId,
        type: 'card',
      },
    },
    {
      enabled: isPaystack && !isStripeConnect,
    }
  );

  const paymentMethodsFind = isPaystack
    ? paymentMethodsFindPaystack
    : isStripeConnect
    ? paymentMethodsFindStripeConnect
    : null;

  const paymentMethodsCreateStripeConnect =
    useFinancesCreateStripeConnectSetupIntent({
      enabled: isStripeConnect && !isPaystack,
      onSuccess: (response) => {
        window.open(response.url, '_blank');
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const paymentMethodsCreatePaystack = useFinancesPaystackCreateSetupIntent({
    enabled: isPaystack && !isStripeConnect,
    onSuccess: (response) => {
      window.open(response.url?.authorizationUrl, '_blank');
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const paymentMethodsCreate = isPaystack
    ? paymentMethodsCreatePaystack
    : paymentMethodsCreateStripeConnect;

  const attachPaymentMethod = useFinancesCreateStripeConnectAttachPaymentMethod(
    {
      enabled: isStripeConnect && !isPaystack,
      onSuccess: () => {
        setTimeout(paymentMethodsFind.refetch, 2000);
      },
      meta: {
        useOnSuccessToast: true,
      },
    }
  );

  const paymentMethodsFindGlobal =
    useFinancesFindStripeConnectPlatformCardPayments(
      {
        userId,
      },
      {
        enabled: isStripeConnect && !isPaystack,
        onSuccess: (response) => {
          if (
            response.data.length === 0 &&
            !paymentMethodsCreate.data &&
            !paymentMethodsFind.isFetching &&
            paymentMethodsFind.data.length === 0
          ) {
            paymentMethodsCreate.mutate({
              locationId: query.locationId,
              paymentMethod: 'card',
              userId,
            });
          }
        },
      }
    );

  const paymentMethodsDeletePaystack = useFinancesDeletePaystackSetupIntent({
    onSuccess: () => {
      paymentMethodsFind.refetch();
    },
  });

  const paymentMethodsDeleteStripeConnect =
    useFinancesDeleteStripeConnectSetupIntent({
      onSuccess: () => {
        paymentMethodsFind.refetch();
        paymentMethodsFindGlobal.refetch();
      },
    });

  const paymentMethodsDelete = isPaystack
    ? paymentMethodsDeletePaystack
    : paymentMethodsDeleteStripeConnect;

  const onClickAction = ({ action, id }) => {
    const paymentMethod = paymentMethodsFind?.data?.data?.find(
      (item) => item.token === id
    );

    switch (action) {
      case 'delete':
      case 'deleteCard':
        paymentMethodsDelete.mutate({
          token: paymentMethod?.token,
        });
        break;
      default:
        break;
    }
  };

  const onClickActionGlobal = ({ action, id }) => {
    const paymentMethod = paymentMethodsFindGlobal?.data?.data?.find(
      (item) => item.token === id
    );

    switch (action) {
      case 'delete':
      case 'deleteCard':
        paymentMethodsDelete.mutate({
          token: paymentMethod?.token,
        });
        break;

      case 'linkToLocation':
        attachPaymentMethod.mutate({
          token: paymentMethod?.token,
          locationId: query.locationId,
          userId,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Container
      appBarProps={{
        title: t('cards'),
        breadcrumbs: [isPaystack ? '' : t('user')],
        children: (
          <Box ml='auto'>
            <Box isFlex>
              <Button
                hasBorder
                mr={2}
                text={`+ ${t('addCard')}`}
                onClick={() => {
                  paymentMethodsCreate.mutate({
                    locationId: query.locationId,
                    paymentMethod: 'card',
                    userId,
                  });
                }}
              />
            </Box>
          </Box>
        ),
      }}
      isLoading={
        paymentMethodsFindGlobal?.isFetching || attachPaymentMethod?.isLoading
      }
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptions.map((item) => ({
          value: item.id,
          label: item.name,
        }))}
        onSubmit={setQuery}
      />
      <TableCardsLocation
        data={
          paymentMethodsFind?.isFetching ? [] : paymentMethodsFind?.data?.data
        }
        isLoading={paymentMethodsFind?.isFetching}
        onClickAction={onClickAction}
      />

      {!isPaystack ? (
        <Box marginTop={'2rem'}>
          <Text fontSize={'1.5rem'} marginBottom={'2rem'} marginTop={'2rem'}>
            {t('myCards')}
          </Text>

          <TableCardsGlobal
            data={
              paymentMethodsFindGlobal?.isFetching
                ? []
                : paymentMethodsFindGlobal?.data?.data
            }
            isLoading={paymentMethodsFindGlobal?.isFetching}
            onClickAction={onClickActionGlobal}
          />
        </Box>
      ) : null}
    </Container>
  );
};

export default CardsConnect;
