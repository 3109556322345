import { Button, Modal, ProcessingBar } from 'octiv-components';
import { useToggle } from 'octiv-hooks';
import { useFinancesDeletePaymentToken } from 'octiv-hooks/requests/Finances';
import { useHealthProvidersFind } from 'octiv-hooks/requests/HealthProviders';
import { useLocationCategoriesFind } from 'octiv-hooks/requests/LocationCategories';
import {
  useLocationsCreate,
  useLocationsDelete,
  useLocationsFind,
  useLocationsUpdate,
} from 'octiv-hooks/requests/Locations';
import { useTenantsFindById } from 'octiv-hooks/requests/Tenants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormLocation from './FormLocation';
import TableLocations from './TableLocations';

export default ({ tenantId, timezones }) => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();

  const { data: healthProviders, isFetching: isFetchingHealthProviders } =
    useHealthProvidersFind({ filter: { isActive: true } });

  const { data: locationCategories, isFetching: isFetchingLocationCategories } =
    useLocationCategoriesFind();

  // #region Locations
  const {
    data: locations,
    isFetching: isFetchingLocations,
    refetch: getLocationsRequest,
  } = useLocationsFind({
    filter: { tenantId },
    include:
      'healthProviders,locationPaymentGateways.settings,timezone,category',
    append: 'finance_payment_token',
    paging: { perPage: -1 },
  });

  const locationsCreate = useLocationsCreate({
    onSuccess: () => {
      getLocationsRequest();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const tenantsFind = useTenantsFindById(
    { id: tenantId },
    {
      enabled: true,
    }
  );

  const locationsUpdate = useLocationsUpdate({
    onSuccess: () => {
      getLocationsRequest();
      resetToggle();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { isLoading: isDeletingLocation, mutate: deleteLocationRequest } =
    useLocationsDelete({
      onSuccess: getLocationsRequest,
      meta: {
        useOnSuccessToast: true,
      },
    });
  // #endregion

  const {
    isLoading: isDeletingFinancePaymentToken,
    mutate: deleteFinancePaymentTokenRequest,
  } = useFinancesDeletePaymentToken({
    onSuccess: getLocationsRequest,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onPutPostRequest = (values) => {
    if (toggle.type === 'create') {
      locationsCreate.mutate({
        tenantId,
        ...values,
        timezoneId:
          values?.timezoneId === undefined
            ? tenantsFind?.data?.timezoneId
            : values?.timezoneId,
      });
    } else {
      locationsUpdate.mutate({
        id: toggle.data.id,
        ...values,
        timezoneId:
          values?.timezoneId === undefined ? null : values?.timezoneId,
      });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setToggle({
          type: 'update',
          data: locations?.data.find((item) => item.id === id),
        });
        break;

      case 'deletePaymentToken':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteFinancePaymentTokenRequest({ id });
        }
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteLocationRequest({ id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {(isDeletingFinancePaymentToken || isDeletingLocation) && (
        <ProcessingBar />
      )}

      {toggle.isVisible && (
        <Modal
          isSidebar
          title={`${
            toggle.type === 'create' ? t('create') : t('update')
          } Location`}
          onClose={resetToggle}
        >
          {['create', 'update']?.includes(toggle?.type) && (
            <FormLocation
              healthProviders={healthProviders || []}
              initialValues={toggle.data}
              isCreate={toggle.type === 'create'}
              isFetchingHealthProviders={isFetchingHealthProviders}
              isFetchingLocationCategories={isFetchingLocationCategories}
              isLoading={locationsCreate.isLoading || locationsUpdate.isLoading}
              locationCategories={locationCategories?.data || []}
              locationId={toggle?.data?.id}
              timezones={timezones}
              onSubmit={onPutPostRequest}
            />
          )}
        </Modal>
      )}

      <Button
        mb={4}
        text='Add New Location'
        onClick={() => setToggle({ type: 'create' })}
      />

      <TableLocations
        data={locations?.data || []}
        isLoading={isFetchingLocations}
        onClickAction={onClickAction}
      />
    </>
  );
};
