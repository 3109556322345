import { loadStripe } from '@stripe/stripe-js/pure';
import { Images } from 'octiv-assets';
import {
  Alert,
  Box,
  Button,
  Card,
  Container,
  Image,
  ProcessingSpinner,
  Text,
  Toast,
} from 'octiv-components';
import { useMediaQuery, useQuery } from 'octiv-hooks';
import {
  useFinancesCreateGoCardlessMandateCallback,
  useFinancesCreatePaystackInitializeTransaction,
  useFinancesCreateStripeConnectCreateCheckoutSession,
  useFinancesCreateStripeCreateCheckoutSession,
  useFinancesFindPaystackVerifyTransaction,
} from 'octiv-hooks/requests/Finances';
import {
  usePaymentsFindByIdInvoiceForPayment,
  usePublicCreateGoCardlessProcessPayment,
  usePublicFindByIdPaymentsPaymentGateway,
} from 'octiv-hooks/requests/Public';
import { domain, paymentGateways } from 'octiv-utilities/Constants';
import {
  getDateReadableDayMonthYear,
  getFullName,
  getPaymentGatewayName,
  toCurrency,
} from 'octiv-utilities/Functions';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';

import Table from './Table';

const textProps = {
  color: 'grey1',
  mb: 1,
};

export default () => {
  const { t } = useTranslation();

  const theme = useContext(ThemeContext);
  const { xsDown } = useMediaQuery();
  const { invoiceId } = useParams();
  const locationPaymentGatewayId = useQuery('gid');
  const success = useQuery('success');
  const goCardlessRedirectFlowId = useQuery('redirect_flow_id');
  const paystackReference = useQuery('reference');

  const isSuccess = success === 'true';

  const [invoice, setInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const stripeLibrary = useRef();

  const isUnpaid = ['outstanding', 'unpaid'].includes(invoice.status);

  const { refetch: getInvoiceRequest } = usePaymentsFindByIdInvoiceForPayment(
    {
      id: invoiceId,
      includePaymentGateways: !locationPaymentGatewayId,
    },
    {
      enabled: false,
      onSuccess: (response) => {
        setInvoice(response);
        setIsLoading(false);
      },
      onError: () => setIsLoading(false),
    }
  );

  useFinancesFindPaystackVerifyTransaction(
    {
      filter: { reference: paystackReference },
    },
    {
      enabled: isSuccess && isUnpaid && !!paystackReference,
      onSuccess: () => {
        getInvoiceRequest();
      },
    }
  );

  const {
    data: locationPaymentGateway,
    isFetching: isFetchingLocationPaymentGateway,
  } = usePublicFindByIdPaymentsPaymentGateway(
    {
      id: locationPaymentGatewayId,
    },
    {
      enabled: invoice.amount > 0 && isUnpaid && !!locationPaymentGatewayId,
      onSuccess: (response) => {
        const { paymentGatewayId, publicKey } = response || {};

        if (paymentGatewayId === paymentGateways.STRIPE) {
          stripeLibrary.current = loadStripe(publicKey);
        }
      },
    }
  );

  const {
    isLoading: isCreatingPaystackInitializeTransaction,
    mutate: postPaystackInitializeTransactionRequest,
  } = useFinancesCreatePaystackInitializeTransaction({
    onSuccess: (response) => {
      if (response?.authorizationURL) {
        window.location.assign(response.authorizationURL);
      }
    },
  });

  const {
    isLoading: isCreatingStripeSession,
    mutate: postStripeSessionRequest,
  } = useFinancesCreateStripeCreateCheckoutSession({
    onSuccess: async (response) => {
      const stripe = await stripeLibrary.current;

      const result = await stripe.redirectToCheckout({
        sessionId: response.id,
      });

      if (result.error) {
        toast.error(
          <Toast
            body={result.error.message}
            title={t('error')}
            variant='danger'
          />
        );
      }
    },
  });

  const {
    isLoading: isCreatingStripeConnectSession,
    mutate: postStripeConnectSessionRequest,
  } = useFinancesCreateStripeConnectCreateCheckoutSession({
    onSuccess: (response) => {
      if (response && response.url) {
        window.location.assign(response.url);
      } else {
        toast.success(
          <Toast
            body={t('paymentIsBeingProcessed')}
            title={t('thankYou')}
            variant='success'
          />
        );

        getInvoiceRequest();
      }
    },
  });

  const {
    isLoading: isCreatingGoCardlessProcessPayment,
    mutate: postGoCardlessProcessPaymentRequest,
  } = usePublicCreateGoCardlessProcessPayment({
    onSuccess: (response) => {
      if (response && response.link) {
        window.location.assign(response.link);
      } else {
        toast.success(
          <Toast
            body={t('paymentIsBeingProcessed')}
            title={t('thankYou')}
            variant='success'
          />
        );

        getInvoiceRequest();
      }
    },
  });

  const {
    isLoading: isCreatingGoCardlessMandate,
    mutate: postGoCardlessMandateRequest,
  } = useFinancesCreateGoCardlessMandateCallback({
    onSuccess: () => {
      if (invoiceId) {
        window.location.assign(
          `${domain}/payment/${invoiceId}${
            locationPaymentGatewayId ? `?gid=${locationPaymentGatewayId}` : ''
          }`
        );
      } else {
        window.location.assign(`${domain}/payment/success`);
      }
    },
  });

  useEffect(() => {
    if (goCardlessRedirectFlowId) {
      postGoCardlessMandateRequest({
        redirectFlowId: goCardlessRedirectFlowId,
      });
    } else if (isSuccess) {
      setTimeout(() => {
        getInvoiceRequest();
      }, 2500);
    } else {
      getInvoiceRequest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    amount,
    code,
    createdAt,
    description,
    discriminator,
    dropIn,
    dueOn,
    items,
    location: rootLocation,
    nonce,
    status,
    user,
    nonMember,
    leadMember,
    userLocation,
    tenant,
    paymentGatewaySettings,
  } = invoice || {};

  useEffect(() => {
    const stripeSetting = paymentGatewaySettings?.find(
      (p) => p.paymentGatewayId === 6
    );
    const hasStripe = stripeSetting !== null;

    if (hasStripe && stripeSetting?.publicKey) {
      stripeLibrary.current = loadStripe(stripeSetting?.publicKey);
    }
  }, [paymentGatewaySettings]);

  const location = rootLocation || userLocation?.location;

  const currencyCode = tenant?.memberBillingCurrency?.code;

  const renderPaymentButton = (props) => {
    if (!isUnpaid) return null;
    const buttonText = `${t('pay')} ${t('with')} ${getPaymentGatewayName({
      id: props.gateway?.paymentGatewayId,
    })}`;

    return (
      <Box {...props}>
        {isFetchingLocationPaymentGateway ? (
          <Button isLoading mx='auto' />
        ) : props.gateway?.paymentGatewayId ===
          paymentGateways.STRIPE_CONNECT ? (
          <Button
            isLoading={isCreatingStripeConnectSession}
            mx='auto'
            text={buttonText}
            onClick={() =>
              postStripeConnectSessionRequest({
                invoiceId,
                settingsId: props?.gateway?.paymentGatewaySettingsId,
              })
            }
          />
        ) : props.gateway?.paymentGatewayId === paymentGateways.GO_CARDLESS ? (
          <Button
            isLoading={isCreatingGoCardlessProcessPayment}
            mx='auto'
            text={buttonText}
            onClick={() =>
              postGoCardlessProcessPaymentRequest({
                invoiceId,
                settingsId: props?.gateway?.paymentGatewaySettingsId,
              })
            }
          />
        ) : props.gateway?.paymentGatewayId === paymentGateways.STRIPE ? (
          <Button
            isLoading={isCreatingStripeSession}
            mx='auto'
            text={buttonText}
            onClick={() =>
              postStripeSessionRequest({
                invoiceId,
                settingsId: props.gateway?.paymentGatewaySettingsId,
              })
            }
          />
        ) : props.gateway?.paymentGatewayId === paymentGateways.PAYSTACK ? (
          <Button
            isLoading={isCreatingPaystackInitializeTransaction}
            mx='auto'
            text={buttonText}
            onClick={() =>
              postPaystackInitializeTransactionRequest({
                invoiceId,
                settingsId: props.gateway?.paymentGatewaySettingsId,
              })
            }
          />
        ) : props.gateway?.paymentGatewayId === paymentGateways.SAGE ||
          props.gateway?.paymentGatewayId === paymentGateways.NETCASH ? (
          <form
            action='https://paynow.netcash.co.za/site/paynow.aspx'
            method='post'
          >
            <input
              required
              name='m1'
              type='hidden'
              value={props.gateway?.payNowServiceKey}
            />
            <input
              required
              name='m2'
              type='hidden'
              value={props.gateway?.softwareVendorKey}
            />
            <input required name='p2' type='hidden' value={nonce} />
            <input
              required
              name='p3'
              type='hidden'
              value={
                description ||
                `Invoice for ${
                  nonMember?.name || getFullName(user || leadMember?.user)
                }`
              }
            />
            <input required name='p4' type='hidden' value={amount} />
            <input required name='Budget' type='hidden' value='N' />
            <input required name='m4' type='hidden' value={invoiceId} />
            <input
              required
              name='m5'
              type='hidden'
              value={nonMember?.name || getFullName(user || leadMember.user)}
            />
            <input required name='m6' type='hidden' value={code} />
            <button className='form-button' type='submit'>
              {buttonText}
            </button>
          </form>
        ) : null}
      </Box>
    );
  };

  const renderPayment = () => {
    if (!isUnpaid) return null;

    return (
      <Box mb={18} mt={14}>
        <Text isBold textAlign='center' variant='jumbo'>
          {toCurrency({
            value: amount,
            code: currencyCode,
          })}
        </Text>

        <Text color='grey1' mb={4} textAlign='center' variant='caption'>
          {t('amountDue')}
        </Text>

        <Box
          isFlex
          alignItems='center'
          flexDirection='row'
          justifyContent='center'
        >
          {locationPaymentGatewayId ? (
            <>
              {renderPaymentButton({
                mt: 4,
                gateway: locationPaymentGateway,
              })}
            </>
          ) : (
            paymentGatewaySettings?.map((item) => (
              <Box key={item?.paymentGatewayId} mx='0.5rem'>
                {renderPaymentButton({
                  gateway: item,
                })}
              </Box>
            ))
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Container
      isLoading={
        isFetchingLocationPaymentGateway || isCreatingGoCardlessMandate
      }
    >
      <Image
        alt={t('octivLogo')}
        backgroundSize='contain'
        height={10}
        m='auto'
        my={4}
        src={theme.isDark ? Images.logoLight : Images.logoDark}
        width={34}
        onClick={() =>
          window.open('https://octivfitness.com', 'octivMarketingWebsite')
        }
      />

      {isLoading ? (
        <ProcessingSpinner m='auto' />
      ) : !code ? (
        <Text isBold textAlign='center' variant='heading'>
          {t('invoiceNotFound')}
        </Text>
      ) : (
        <>
          {renderPayment()}

          {discriminator === 'dropInInvoice' && (
            <Alert
              buttonRightProps={
                !isUnpaid && {
                  text: t('book'),
                  onClick: () =>
                    window.location.assign(
                      `/widget/schedule?publicToken=${dropIn?.publicToken}&leadToken=${dropIn?.leadToken}`
                    ),
                }
              }
              m='auto'
              maxWidth={800}
              mb={4}
              subtitle={
                isUnpaid ? t('dropInInvoiceUnpaid') : t('dropInInvoicePaid')
              }
              title={t('dropIn')}
              variant='info'
            />
          )}

          <Card m='auto' maxWidth={800} my={4} title='Invoice Details'>
            <Alert
              mb={4}
              subtitle={
                isUnpaid
                  ? t('thisInvoiceHasNotBeenPaid')
                  : status === 'paid'
                  ? t('thisInvoiceHasBeenPaid')
                  : t('thisInvoiceIsBeingProcessed')
              }
              variant={
                isUnpaid ? 'danger' : status === 'paid' ? 'success' : 'warning'
              }
            />

            {xsDown && location?.logoUrl && (
              <Image
                hasRadius
                alt={t('logo')}
                mb={4}
                size={15}
                src={location?.logoUrl}
              />
            )}

            <Box isFlex>
              <Box>
                <Text isBold mb={4} variant='heading'>
                  {`${t('invoice')} #: ${code}`}
                </Text>

                <Text {...textProps}>
                  {`${t('created')}: ${getDateReadableDayMonthYear({
                    date: createdAt,
                  })}`}
                </Text>

                <Text {...textProps}>
                  {`${t('due')}: ${getDateReadableDayMonthYear({
                    date: dueOn,
                  })}`}
                </Text>

                <Text {...textProps}>{`${t('member')}: ${
                  nonMember?.name || getFullName(user || leadMember.user)
                }`}</Text>

                <Text {...textProps}>{`${t('member')} ${t('email')}: ${
                  user?.email || leadMember?.user?.email || nonMember?.email
                }`}</Text>

                <Text mb={2} mt={4}>
                  {`${tenant?.name} - ${location?.name}`}
                </Text>

                {location?.vat && (
                  <Text {...textProps}>{`${t('vatNumber')}: ${
                    location?.vat
                  }`}</Text>
                )}

                <Text {...textProps} as='div' whiteSpace='pre-line'>
                  {location?.invoiceInformation}
                </Text>
              </Box>

              {!xsDown && location?.logoUrl && (
                <Image
                  hasRadius
                  alt={t('logo')}
                  ml='auto'
                  size={25}
                  src={location?.logoUrl}
                />
              )}
            </Box>

            {items && (
              <Box mb={2} mt={4}>
                <Table currencyCode={currencyCode} data={items} />
              </Box>
            )}

            <Text isBold textAlign='right' variant='subheading'>
              {`${t('amount')}: ${toCurrency({
                value: Number(amount),
                code: currencyCode,
              })}`}
            </Text>

            <Box
              isFlex
              alignItems='center'
              flexDirection='row'
              justifyContent='center'
            >
              {locationPaymentGatewayId ? (
                <>
                  {renderPaymentButton({
                    mt: 4,
                    gateway: locationPaymentGateway,
                  })}
                </>
              ) : (
                paymentGatewaySettings?.map((item) => (
                  <Box key={item?.paymentGatewayId} mx='0.5rem'>
                    {renderPaymentButton({
                      gateway: item,
                    })}
                  </Box>
                ))
              )}
            </Box>
          </Card>
        </>
      )}
    </Container>
  );
};
