import akiles from './akiles.png';
import august from './august.png';
import igloohome from './igloohome.png';
import kwikset from './kwikset.png';
import lockly from './lockly.png';
import nuki from './nuki.png';
import saltoKs from './salto.svg';
import schlage from './schlage.png';
import smartThings from './smartthings.png';
import tedee from './tedee.png';
import ttlock from './ttlock.png';
import wyze from './wyze.png';
import yaleHome from './yale.png';

export default {
  akiles,
  august,
  igloohome,
  kwikset,
  lockly,
  nuki,
  schlage,
  smartThings,
  tedee,
  ttlock,
  wyze,
  yaleHome,
  saltoKs,
};
