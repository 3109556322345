import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useUsersFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['users', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/users`,
        params: {
          filter: {
            not_in_access_system_id: filter.acsId,
            tenantId: filter.tenantId || tenantId,
            userId: filter.userId,
            userTenantHasSessionsAvailableForDate:
              filter.userTenantHasSessionsAvailableForDate,
            isMinimalData: filter.isMinimalData || undefined,

            regionId: filter.regionId,
            birthdaysBetween: filter.birthdaysBetween,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            leadMemberType: filter.leadMemberType,
            leadMemberStatus: filter.leadMemberStatus,

            userTenantLocationId: filter.userTenantLocationId,
            userTenantStatusId: filter.userTenantStatusId,
            userTenantTypeId: filter.userTenantTypeId,
            userTenantDebitStatusId: filter.userTenantDebitStatusId,
            userTenantProgrammeId: filter.userTenantProgrammeId,
            userTenantAssignedUserId: filter.userTenantAssignedUserId,
            userTenantPackageId: filter.userTenantPackageId,
          },
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useUsersFindStaff = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const {
    filter = {},
    paging = {},
    sort = 'name',
    ...queryParams
  } = input || {};

  return useQuery(
    ['users', 'findStaff', input],
    () =>
      request({
        method: 'get',
        url: `/api/users`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userTenantTypeId: filter.userTenantTypeId || '2,3,5,6,8',
            userTenantStatusId:
              filter.userTenantStatusId || filter.statusId || 2,
            isMinimalData: filter.isMinimalData || undefined,

            userTenantHasSessionsAvailableForDate:
              filter.userTenantHasSessionsAvailableForDate,
            regionId: filter.regionId,
            birthdaysBetween: filter.birthdaysBetween,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            userTenantLocationId: filter.userTenantLocationId,
            userTenantDebitStatusId: filter.userTenantDebitStatusId,
            userTenantProgrammeId: filter.userTenantProgrammeId,
            userTenantAssignedUserId: filter.userTenantAssignedUserId,
            userTenantPackageId: filter.userTenantPackageId,
          },
          sort,
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useUsersFindMembers = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const {
    filter = {},
    paging = {},
    sort = 'name',
    ...queryParams
  } = input || {};

  return useQuery(
    ['users', 'findMembers', input],
    () =>
      request({
        method: 'get',
        url: `/api/users`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userTenantTypeId: filter.userTenantTypeId || 4,
            userTenantStatusId: filter.userTenantStatusId || 2,
            isMinimalData: filter.isMinimalData || undefined,
            deleted: filter.deleted,
            userTenantHasSessionsAvailableForDate:
              filter.userTenantHasSessionsAvailableForDate,
            regionId: filter.regionId,
            birthdaysBetween: filter.birthdaysBetween,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            userTenantLocationId: filter.userTenantLocationId,
            userTenantDebitStatusId: filter.userTenantDebitStatusId,
            userTenantProgrammeId: filter.userTenantProgrammeId,
            userTenantAssignedUserId: filter.userTenantAssignedUserId,
            userTenantPackageId: filter.userTenantPackageId,
          },
          sort,
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useUsersFindLeads = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const {
    filter = {},
    paging = {},
    sort = 'name',
    ...queryParams
  } = input || {};

  return useQuery(
    ['users', 'findLeads', input],
    () =>
      request({
        method: 'get',
        url: `/api/users`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userTenantTypeId: filter.userTenantTypeId || '9',
            userTenantStatusId:
              filter.userTenantStatusId || filter.statusId || 2,

            userTenantHasSessionsAvailableForDate:
              filter.userTenantHasSessionsAvailableForDate,
            regionId: filter.regionId,
            birthdaysBetween: filter.birthdaysBetween,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            userTenantLocationId: filter.userTenantLocationId,
            userTenantDebitStatusId: filter.userTenantDebitStatusId,
            userTenantProgrammeId: filter.userTenantProgrammeId,
            userTenantAssignedUserId: filter.userTenantAssignedUserId,
            userTenantPackageId: filter.userTenantPackageId,
          },
          sort,
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useUsersCreateDuplicateUsersMerge = (options) => {
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: '/api/users/duplicate-users/merge',
        data: {
          primaryUserId: data.primaryUserId,
          mergeUserIds: _.isEmpty(data.mergeUserIds)
            ? undefined
            : data.mergeUserIds,
          updateUsers: _.isEmpty(data.updateUser) ? undefined : data.updateUser,
        },
      });
    },
  });
};

export const useUsersCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/users`,
        data: {
          typeId: data.typeId,
          memberId: data.memberId,
          idNumber: data.idNumber,
          address: data.address,
          healthProviderId: data.healthProviderId,
          name: data.name,
          surname: data.surname,
          bio: data.bio,
          genderId: data.genderId,
          dateOfBirth: data.dateOfBirth,
          email: data.email,
          mobile: data.mobile,
          emergencyContactName: data.emergencyContactName,
          emergencyContactMobile: data.emergencyContactMobile,
          image: data.image,
          password: data.password,
          assignedCoachId: data.assignedTrainerId,
          locationId: data.locationId,
          programmeId: data.programmeId,
          packageId: data.packageId,
          regionId: data.regionId,
          notes: data.notes,
          paymentDetails: data.paymentDetails,
          contractDetails: data.contractDetails,
          discountDetails: data.discountDetails,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['usersFindValidate'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersCreateSignup = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/users/sign-up`,
        data: {
          typeId: data.typeId,
          idNumber: data.idNumber,
          address: data.address,
          healthProviderId: data.healthProviderId,
          name: data.name,
          surname: data.surname,
          bio: data.bio,
          genderId: data.genderId,
          dateOfBirth: data.dateOfBirth,
          email: data.email,
          mobile: data.mobile,
          emergencyContactName: data.emergencyContactName,
          emergencyContactMobile: data.emergencyContactMobile,
          image: data.image,
          password: data.password,
          assignedCoachId: data.assignedTrainerId,
          locationId: data.locationId,
          programmeId: data.programmeId,
          packageId: data.packageId,
          regionId: data.regionId,
          notes: data.notes,
          paymentDetails: data.paymentDetails,
          termsAndConditionsForContract: data.termsAndConditionsForContract,
          termsAndConditionsForWaiver: data.termsAndConditionsForWaiver,
          contractDetails: data.contractDetails,
          discountDetails: data.discountDetails,
          tenantId: data.tenantId,
          userId: data.userId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['usersFindValidate'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'delete',
        url: `/api/users`,
        data: { userIds: data.userIds },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersFindDuplicateUsers = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['usersFindValidate', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/duplicate-users`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
          },
        },
      }),
    options
  );
};

export const useUsersFindDuplicateUsersUserTenants = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['usersFindValidate', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/duplicate-users/user-tenants`,
        params: {
          primaryUserId: input.primaryUserId,
          filter: {
            tenantId: filter.tenantId || tenantId,
          },
        },
      }),
    options
  );
};

export const useUsersFindValidate = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  // TODO: consider cleaning/putting into a util
  const emailParts = input?.email?.split?.('@');
  const domain = emailParts?.[1];
  const urlEncodedEmail = `${encodeURIComponent(emailParts?.[0])}@${domain}`;

  return useQuery(
    ['usersFindValidate', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/validate`,
        params: {
          tenantId: input.tenantId || tenantId,
          email: urlEncodedEmail,
          password: input.password,
        },
      }),
    options
  );
};

export const useUsersFindMe = (input, options) => {
  return useQuery(
    ['users', 'findMe', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/me`,
      }),
    options
  );
};

export const useUsersFindRenewToken = (input, options) => {
  return useQuery(
    ['users', 'findRenewToken', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/renew-token`,
      }),
    options
  );
};

export const useUsersCreateExport = (options) => {
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const {
        filter = {},
        paging = {},
        sort = 'name',
        ...queryParams
      } = input || {};

      return request({
        method: 'get',
        url: `/api/users/export`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            userTenantTypeId: filter.userTenantTypeId || 4,
            userTenantStatusId: filter.userTenantStatusId || 2,

            regionId: filter.regionId,
            birthdaysBetween: filter.birthdaysBetween,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            userTenantLocationId: filter.userTenantLocationId,
            userTenantDebitStatusId: filter.userTenantDebitStatusId,
            userTenantProgrammeId: filter.userTenantProgrammeId,
            userTenantAssignedUserId: filter.userTenantAssignedUserId,
            userTenantPackageId: filter.userTenantPackageId,
          },
          sort,
          ...paging,
          ...queryParams,
        },
      });
    },
  });
};

export const useUsersFindTransferSearch = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['users', 'findTransferSearch', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/transfer-search`,
        params: { filter: { email: filter.email } },
      }),
    options
  );
};

export const useUsersFindByIdLinkedMembers = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['users', 'findByIdLinkedMembers', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/linked-members/${id}`,
      }),
    options
  );
};

export const useUsersUpdateLinkedMembers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/users/linked-members/${id}`,
        data: { userIds: data.userIds },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersDeleteLinkedMembers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/users/linked-members/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersFindGetTrainers = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(
    ['users', 'findGetTrainers', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/get-coaches`,
        params: { filter: { tenantId: filter.tenantId || tenantId } },
      }),
    options
  );
};

export const useUsersFindBirthdays = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['users', 'findBirthdays', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/birthdays`,
        params: {
          filter: {
            startDate: filter.startDate,
            endDate: filter.endDate,
            locationId: filter.locationId,
          },
        },
      }),
    options
  );
};

export const useUsersFindLoggedIn = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['users', 'findLoggedIn', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/logged-in`,
        params: {
          filter: {
            injuryDetails: filter.injuryDetails,
            waiverDetails: filter.waiverDetails,
            bookingsDetails: filter.bookingsDetails,
            financeDetails: filter.financeDetails,
            tenantDetails: filter.tenantDetails,
          },
        },
      }),
    options
  );
};

export const useUsersFindLinkedAccounts = (input, options) => {
  return useQuery(
    ['users', 'findLinkedAccounts', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/linked-accounts`,
      }),
    options
  );
};

export const useUsersFindByIdSwitchToLinkedAccount = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['users', 'findByIdSwitchToLinkedAccount', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/switch-to-linked-account/${id}`,
      }),
    options
  );
};

export const useUsersFindByIdCoronavirusQuestionnaireResults = (
  input,
  options
) => {
  const { id, filter = {} } = input || {};

  return useQuery(
    ['users', 'findByIdCoronavirusQuestionnaireResults', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/${id}/coronavirus-questionnaire-results`,
        params: { page: filter.page, perPage: filter.perPage },
      }),
    options
  );
};

export const useUsersFindById = (input, options) => {
  const { id, filter = {} } = input || {};

  return useQuery(
    ['users', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/users/${id}`,
        params: {
          filter: {
            injuryDetails: filter.injuryDetails,
            waiverDetails: filter.waiverDetails,
            bookingsDetails: filter.bookingsDetails,
            financeDetails: filter.financeDetails,
            tenantDetails: filter.tenantDetails,
          },
        },
      }),
    options
  );
};

export const useUsersUpdateSwitch = (options) => {
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'get',
        url: `/api/users/${id}/switch`,
      });
    },
  });
};

export const useUsersUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/users/${id}`,
        data: {
          typeId: data.typeId,
          memberId: data.memberId,
          idNumber: data.idNumber,
          healthProviderId: data.healthProviderId,
          name: data.name,
          surname: data.surname,
          bio: data.bio,
          genderId: data.genderId,
          dateOfBirth: data.dateOfBirth,
          address: data.address,
          email: data.email,
          mobile: data.mobile,
          emergencyContactName: data.emergencyContactName,
          emergencyContactMobile: data.emergencyContactMobile,
          landingScreen: data.landingScreen,
          password: data.password,
          assignedCoachId: data.assignedTrainerId,
          notes: data.notes,
          isHighRisk: data.isHighRisk,
          locationId: data.locationId,
          programmeId: data.programmeId,
          regionId: data.regionId,
          locationAccessPrivileges: data.locationAccessPrivileges,
          userAccessPrivileges: data.userAccessPrivileges,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateImage = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: async (input) => {
      const { id, image } = input || {};

      const formData = new FormData();

      formData.append('image', image);

      return request({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        url: `/api/users/${id}`,
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersCreateLinkAccount = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/users/link-account`,
        data: {
          userId: data.userId,
          userIdToLink: data.userIdToLink,
          password: data.password,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersCreateTransfer = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/users/transfer/${id}`,
        data: {
          name: data.name,
          surname: data.surname,
          email: data.email,
          programmeId: data.programmeId,
          locationId: data.locationId,
          packageId: data.packageId,
          specialRate: data.specialRate,
          debitStatusId: data.debitStatusId,
          bankId: data.bankId,
          accountTypeId: data.accountTypeId,
          accountNumber: data.accountNumber,
          accountHolderName: data.accountHolderName,
          waiver: data.waiver,
          debitDayId: data.debitDayId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersCreateAssignMember = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/users/assign-member/${id}`,
        data: {
          name: data.name,
          surname: data.surname,
          mobile: data.mobile,
          email: data.email,
          genderId: data.genderId,
          dateOfBirth: data.dateOfBirth,
          locationId: data.locationId,
          programmeId: data.programmeId,
          packageId: data.packageId,
          statusId: data.statusId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateUnlinkAccount = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/users/unlink-account/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateAcceptTermsAndConditions = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'put',
        url: `/api/users/accept-terms-and-conditions`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateUpdateStatus = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/users/update-status`,
        data: { userIds: data.userIds, statusId: data.statusId },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateApproveAllPending = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: () => {
      return request({
        method: 'put',
        url: `/api/users/approve-all-pending`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateHighRisk = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/users/high-risk`,
        data: { userIds: data.userIds, isHighRisk: data.isHighRisk },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdatePaymentType = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/users/payment-type`,
        data: {
          userIds: data.userIds,
          debitStatusId: data.debitStatusId,
          debitDayId: data.debitDayId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateSendStripeLink = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/users/send-stripe-link`,
        data: { userIds: data.userIds },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateChangePassword = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/users/change-password`,
        data: { userIds: data.userIds, password: data.password },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUsersUpdateActivateDeactivate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/users/activate-deactivate/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};
