import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useLeadsFind = (input, options) => {
  const {
    filter = {},
    paging = {},
    sort = undefined,
    include = undefined,
    ...queryParams
  } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['leads', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/leads`,
        params: {
          filter: {
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            locationId: filter.locationId || undefined,
            tenantId: filter.tenantId || tenantId,
            status: filter.status || undefined,
            type: filter.type || undefined,
            order: filter.order,
            isRedacted: filter.isRedacted,
          },
          include,
          sort,
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useLeadsCreate = (options) => {
  const { tenantId } = useActiveUserTenant();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/leads`,
        data: {
          tenantId,
          status: data.statusId,
          locationId: data.locationId,
          referredById: data.referredById,
          source: data.source,
          name: data.name,
          surname: data.surname,
          genderId: data.genderId,
          email: data.email,
          mobile: data.mobile,
          notes: data.notes,
          dateOfBirth: data.dateOfBirth,
          lastContactedAt: data.lastContactedAt,
          nextFollowUpAt: data.nextFollowUpAt,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsUpdate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/leads/${id}`,
        data: {
          tenantId,
          referredById: data.referredById,
          status: data.statusId,
          source: data.source,
          name: data.name,
          surname: data.surname,
          email: data.email,
          mobile: data.mobile,
          dateOfBirth: data.dateOfBirth,
          genderId: data.genderId,
          lastContactedAt: data.lastContactedAt,
          nextFollowUpAt: data.nextFollowUpAt,
          locationId: data.locationId,
          notes: data.notes,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsCreateSignup = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/leads/sign-up`,
        data: {
          tenantId: data.tenantId,
          locationId: data.locationId,
          name: data.name,
          surname: data.surname,
          email: data.email,
          mobile: data.mobile,
          genderId: data.genderId,
          notes: data.notes,
          isRequestDemo: Number(data.isRequestDemo),
          termsAndConditionsForWaiver: data.termsAndConditionsForWaiver,
          gRecaptchaResponse: data.gRecaptchaResponse,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsFindExport = (input, options) => {
  const { filter = {}, paging = {}, include = undefined } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['leads', 'findMembers', input],
    () =>
      request({
        method: 'get',
        url: `/api/leads/export`,
        params: {
          filter: {
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            locationId: filter.locationId || undefined,
            tenantId: filter.tenantId || tenantId,
            status: filter.status || undefined,
            type: filter.type || undefined,
            sort: filter.sort,
            order: filter.order,
          },
          include,
          ...paging,
        },
      }),
    options
  );
};

export const useLeadsCreateImport = (options) => {
  const { tenantId } = useActiveUserTenant();
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();
      formData.append('tenantId', data.tenantId || tenantId);
      formData.append('file', data.csvFile);

      return request({
        method: 'post',
        url: `/api/leads/import`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsCreateGenerateInvoice = (options) => {
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      const formData = new FormData();
      formData.append('dueOn', data.dueOn);
      formData.append('isSend', data.isSend);
      formData.append('amount', data.amount);

      return request({
        method: 'post',
        url: `/api/leads/${id}/generate-invoice`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
  });
};

export const useLeadsCreateMembers = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-tenants`,
        data: {
          tenantId,
          referredById: data.referredById,
          source: data.source,
          name: data.name,
          surname: data.surname,
          email: data.email,
          mobile: data.mobile,
          dateOfBirth: data.dateOfBirth,
          genderId: data.genderId,
          lastContactedOnDate: data.lastContactedOnDate,
          nextFollowUpDate: data.nextFollowUpDate,
          locationId: data.locationId,
          notes: data.notes,
          userTypeId: 9,
          // isRequestDemo
          // lead (required if user_type_id is provided)
          // termsAndConditionsForWaiver
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsFindMembersTypes = (input, options) => {
  return useQuery(
    ['leads', 'findMembersTypes', input],
    () =>
      request({
        method: 'get',
        url: `/api/leads/members/types`,
      }),
    options
  );
};

export const useLeadsFindByIdMembersViewBookings = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['leads', 'findByIdMembersViewBookings', input],
    () =>
      request({
        method: 'get',
        url: `/api/leads/members/view-bookings/${id}`,
      }),
    options
  );
};

export const useLeadsFindMembersExport = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['leads', 'findMembersExport', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-tenants/export/leads`,
        params: {
          filter: {
            tenantId,
            userTenantTypeId: filter.userTenantTypeId,
            search: filter.search,
            locationId: filter.locationId,
            status: filter.status,
            leadType: filter.leadMemberType,
            leadStatus: filter.leadStatus,
            order: filter.order,
            page: filter.page,
          },
          sort: filter.sort,
          ...paging,
        },
      }),
    options
  );
};

export const useLeadsFindByIdMembers = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['leads', 'findByIdMembers', input],
    () =>
      request({
        method: 'get',
        url: `/api/leads/members/${id}`,
      }),
    options
  );
};

export const useLeadsDeleteMembers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/leads/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsCreateMembersGenerateInvoice = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/leads/members/generate-invoice/${id}`,
        data: {
          amount: data.amount,
          dueDate: data.dueDate,
          isSend: data.isSend,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsCreateDropIn = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/leads/create-drop-in`,
        data: {
          tenantId: data.tenantId,
          locationId: data.locationId,
          packageId: data.packageId,
          name: data.name,
          surname: data.surname,
          email: data.email,
          mobile: data.mobile,
          notes: data.notes,
          termsAndConditions: data.termsAndConditions,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsCreateClassBooking = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/leads/create-class-booking`,
        data: {
          classDateId: data.classDateId,
          leadToken: data.leadToken,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsUpdateCancelClassBooking = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/leads/cancel-class-booking`,
        data: {
          classBookingId: data.classBookingId,
          leadToken: data.leadToken,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsFindDropInPackages = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['leads', 'findDropInPackages', input],
    () =>
      request({
        method: 'get',
        url: `/api/leads/drop-in/packages`,
        params: {
          tenantId: filter.tenantId,
          leadToken: filter.leadToken,
        },
      }),
    options
  );
};

export const useLeadsUpdateMembersBulkChangeStatus = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/leads/bulk-status-change`,
        data: { memberIds: data.memberIds, status: data.status },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsUpdateMembersConvert = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/leads/${id}/convert`,
        data: {
          locationId: input?.locationId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLeadsDeleteMembersBulkDelete = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'delete',
        url: `/api/leads/bulk-delete`,
        data: {
          leadMemberIds: data.leadMemberIds,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['leads'] });

      options?.onSuccess?.(...res);
    },
  });
};
