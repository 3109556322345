import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ locationOptions, initialValues, ...props }) => {
  const { t } = useTranslation();
  return (
    <Formik
      {...props}
      initialValues={{
        locationId: initialValues?.locationId,
      }}
      validationSchema={Yup.object().shape({
        regionId: Yup.string(),
        affiliateId: Yup.string(),
        statusId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>
          </Row>
          <br />
        </form>
      )}
    </Formik>
  );
};
