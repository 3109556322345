import moment from 'moment';
import { Container, Divider, SendComm } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { usePaging } from 'octiv-hooks';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import {
  canSetMemberModal,
  getDateYearMonthDay,
} from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const { setMemberModal } = useMemberModal();

  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    userTenantLocationId: selectedLocation?.id,
    startDate: getDateYearMonthDay({ canFallback: true }),
    endDate: getDateYearMonthDay({ date: moment().add(1, 'month') }),
  });

  const usersFindMembers = useUsersFindMembers({
    filter: {
      userTenantLocationId: query.userTenantLocationId,
      birthdaysBetween: `${query.startDate},${query.endDate}`,
      deleted: 0,
    },
    paging,
    sort: 'birthday',
  });

  return (
    <Container
      appBarProps={{
        title: t('birthdays'),
        breadcrumbs: [t('members')],
        children: (
          <SendComm
            members={
              !usersFindMembers.isFetching && usersFindMembers.data?.data
            }
            ml='auto'
          />
        ),
      }}
      isLoading={usersFindMembers.isFetching}
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />

      <Divider mb={4} />

      <Table
        data={usersFindMembers.data?.data}
        isLoading={usersFindMembers.isFetching}
        paging={usersFindMembers?.data?.paging}
        setPaging={setPaging}
        onClickRow={({ original }) => {
          if (
            canSetMemberModal({ userTenant: original.userTenant, hasAccess })
          ) {
            setMemberModal({ userTenantId: original?.userTenant?.id });
          }
        }}
      />
    </Container>
  );
};
