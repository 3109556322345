import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useUserTenantsCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-tenants`,
        data: {
          userId: data.userId,
          tenantId: data.tenantId || tenantId,
          typeId: data.typeId,
          assignedUserId: data.assignedUserId,
          locationId: data.locationId,
          programmeId: data.programmeId,
          packageId: data.packageId,
          notes: data.notes,
          bio: data.bio,
          memberId: data.memberId,
          debitStatus: data.debitStatus,
          contractDetails: data.contractDetails,
          paymentDetails: data.paymentDetails,
          discountDetails: data.discountDetails,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsFindById = (input, options) => {
  const { id, ...params } = input || {};

  return useQuery(
    ['userTenants', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-tenants/${id}`,
        params,
      }),
    options
  );
};

export const useUserTenantsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/${id}`,
        data: {
          tenantId: data.tenantId,
          typeId: data.typeId,
          userStatusId: data.statusId,
          programmeId: data.programmeId,
          memberId: data.memberId,
          bio: data.bio,
          notes: data.notes,
          assignedUserId: data.assignedUserId,
          highRisk: data.highRisk,
          defaultLocationId: data.defaultLocationId,
          regionId: data.regionId,
          landingScreen: data.landingScreen,
          locationId: data.locationId,
          locationAccessPrivileges: data.locationAccessPrivileges,
          userAccessPrivileges: data.userAccessPrivileges,
          ...(data.referredByUserId ||
          data.status ||
          data.source ||
          data.lastContactedDate ||
          data.nextFollowUpDate
            ? {
                lead: {
                  referredByUserId: data.referredByUserId,
                  status: data.status,
                  source: data.source,
                  lastContactedDate: data.lastContactedDate,
                  nextFollowUpDate: data.nextFollowUpDate,
                },
              }
            : {}),
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsUpdateBulk = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/bulk`,
        data: {
          tenantId,
          userTenantIds: data.userTenantIds,
          userTypeId: data.typeId,
          userStatusId: data.statusId,
          programmeId: data.programmeId,
          memberId: data.memberId,
          bio: data.bio,
          notes: data.notes,
          assignedUserId: data.assignedUserId,
          highRisk: data.highRisk,
          defaultLocationId: data.defaultLocationId,
          regionId: data.regionId,
          landingScreen: data.landingScreen,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsFindFinances = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['userTenants', 'findFinances', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-tenants/finances`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            typeId: filter.typeId,
            statusId: filter.statusId,
            programmeId: filter.programmeId,
            debitStatusId: filter.debitStatusId,
            userId: filter.userId,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
          },
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useUserTenantsFindContractsWaivers = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['userTenants', 'findContractsWaivers', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-tenants/contracts-waivers`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            typeId: filter.typeId,
            statusId: filter.statusId,
            dateToQuery: filter.dateToQuery,
            startDate: filter.startDate,
            endDate: filter.endDate,
            userId: filter.userId,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
          },
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useUserTenantsUpdateBulkProgramme = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/programme`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          programmeId: data.programmeId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsUpdateBulkHighRisk = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/high-risk`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          isHighRisk: data.isHighRisk,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsUpdateBulkStatus = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/status`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          statusId: data.statusId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsUpdatePaymentType = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/payment-type`,
        data: {
          tenantId: data.tenantId || tenantId,
          userIds: data.userIds,
          debitStatusId: data.debitStatusId,
          debitDayId: data.debitDayId,
          nextBillingDate: data.nextBillingDate,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsUpdateBulkStatusChange = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/bulk-status-change`,
        data: {
          tenantId: data.tenantId || tenantId,
          userTenantIds: data.userTenantIds,
          status: data.status, // pending, drop_in, contacted, follow_up, request_demo, other
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/user-tenants/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsDeleteBulk = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'delete',
        url: `/api/user-tenants/bulk`,
        data: {
          tenantId,
          userTenantIds: data.userTenantIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsFindByIdPaymentDetails = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['userTenants', 'findByIdPaymentDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-tenants/${id}/payment-details`,
      }),
    options
  );
};

export const useUserTenantsUpdatePaymentDetails = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      const formData = new FormData();

      formData.append('tenantId', data.tenantId || tenantId);
      if (data.discountDetails) {
        if (data.discountDetails.amount) {
          formData.append(
            'discountDetails[amount]',
            data.discountDetails.amount
          );
        }

        if (data.discountDetails.discountType) {
          formData.append(
            'discountDetails[discountType]',
            data.discountDetails.discountType
          );
        }

        if (data.discountDetails.discountId) {
          formData.append(
            'discountDetails[discountId]',
            data.discountDetails.discountId
          );
        }
      }

      if (data.paymentDetails.debitStatusId) {
        formData.append(
          'paymentDetails[debitStatusId]',
          data.paymentDetails.debitStatusId
        );
      }
      if (data.paymentDetails.invoicingType) {
        formData.append(
          'paymentDetails[invoicingType]',
          data.paymentDetails.invoicingType
        );
      }
      if (data.paymentDetails.autoInvoicingDay) {
        formData.append(
          'paymentDetails[autoInvoicingDay]',
          data.paymentDetails.autoInvoicingDay
        );
      }
      if (data.paymentDetails.autoInvoicingDueDay) {
        formData.append(
          'paymentDetails[autoInvoicingDueDay]',
          data.paymentDetails.autoInvoicingDueDay
        );
      }
      if (data.paymentDetails.bankId) {
        formData.append('paymentDetails[bankId]', data.paymentDetails.bankId);
      }
      if (data.paymentDetails.accountTypeId) {
        formData.append(
          'paymentDetails[accountTypeId]',
          data.paymentDetails.accountTypeId
        );
      }
      if (data.paymentDetails.accountNumber) {
        formData.append(
          'paymentDetails[accountNumber]',
          data.paymentDetails.accountNumber
        );
      }
      if (data.paymentDetails.accountHolderName) {
        formData.append(
          'paymentDetails[accountHolderName]',
          data.paymentDetails.accountHolderName
        );
      }
      if (data.paymentDetails.branchCode) {
        formData.append(
          'paymentDetails[branchCode]',
          data.paymentDetails.branchCode
        );
      }
      if (data.paymentDetails.iban) {
        formData.append('paymentDetails[iban]', data.paymentDetails.iban);
      }
      if (data.paymentDetails.bic) {
        formData.append('paymentDetails[bic]', data.paymentDetails.bic);
      }
      if (data.paymentDetails.address) {
        formData.append('paymentDetails[address]', data.paymentDetails.address);
      }
      if (data.paymentDetails.debitDayId) {
        formData.append(
          'paymentDetails[debitDayId]',
          data.paymentDetails.debitDayId
        );
      }
      if (data.paymentDetails.file) {
        formData.append('paymentDetails[file]', data.paymentDetails.file);
      }
      if (data.paymentDetails.proRatedAmount) {
        formData.append(
          'paymentDetails[proRatedAmount]',
          data.paymentDetails.proRatedAmount
        );
      }
      if (data.paymentDetails.upfrontPaymentMethod) {
        formData.append(
          'paymentDetails[upfrontPaymentMethod]',
          data.paymentDetails.upfrontPaymentMethod
        );
      }
      if (data.paymentDetails.upfrontPaymentAmount) {
        formData.append(
          'paymentDetails[upfrontPaymentAmount]',
          data.paymentDetails.upfrontPaymentAmount
        );
      }
      if (data.paymentDetails.upfrontPaymentStartDate) {
        formData.append(
          'paymentDetails[upfrontPaymentStartDate]',
          data.paymentDetails.upfrontPaymentStartDate
        );
      }
      if (data.paymentDetails.upfrontPaymentPeriodType) {
        formData.append(
          'paymentDetails[upfrontPaymentPeriodType]',
          data.paymentDetails.upfrontPaymentPeriodType
        );
      }
      if (data.paymentDetails.upfrontPaymentPeriod) {
        formData.append(
          'paymentDetails[upfrontPaymentPeriod]',
          data.paymentDetails.upfrontPaymentPeriod
        );
      }
      if (data.paymentDetails.nextBillingDate) {
        formData.append(
          'paymentDetails[nextBillingDate]',
          data.paymentDetails.nextBillingDate
        );
      }

      return request({
        method: 'post',
        url: `/api/user-tenants/${id}/payment-details`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsUpdateRenewUpfrontPayment = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/user-tenants/${id}/renew-upfront-payment`,
        data: {
          paymentMethod: data.paymentMethod,
          amount: data.amount,
          upfrontPaymentPeriod: data.upfrontPaymentPeriod,
          upfrontPaymentPeriodType: data.upfrontPaymentPeriodType,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserTenantsSendWelcomeEmail = (options) => {
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-tenants/send-welcome-email`,
        data: {
          users: data.users,
          tenantId: data.tenantId || tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      options?.onSuccess?.(...res);
    },
  });
};
