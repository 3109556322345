import { DownloadApp } from 'octiv-components';
import {
  AuthLogin,
  AuthResetPassword,
  HealthProvidersAttendance,
  PaymentGoCardlessMandate,
  PaymentInvoice,
  PaymentResolution,
  PaymentStripeMandate,
  Terms,
  WidgetLeads,
  WidgetSchedule,
  WidgetSignUp,
  WidgetWorkouts,
} from 'octiv-containers';
import React from 'react';
import { Navigate } from 'react-router-dom';

export const getStacksPublic = ({ onRedirectPath, onLoginPath } = {}) => [
  {
    options: {
      isVisibleInNavigation: false,
      isVisibleNavigation: false,
      isSecure: false,
    },
    screens: [
      // Auth
      {
        name: 'AuthLogin',
        path: '/login',
        element: <AuthLogin />,
      },
      {
        name: 'AuthResetPassword',
        path: '/reset-password',
        element: <AuthResetPassword />,
      },

      // Widgets
      {
        name: 'WidgetSignUp',
        path: '/widget/sign-up',
        element: <WidgetSignUp />,
      },
      {
        name: 'WidgetPackages',
        path: '/widget/packages',
        element: <WidgetSignUp isPackagesOnly />,
      },
      {
        name: 'WidgetSchedule',
        path: '/widget/schedule',
        element: <WidgetSchedule />,
      },
      {
        name: 'WidgetWorkout',
        path: '/widget/workouts',
        element: <WidgetWorkouts />,
      },
      {
        name: 'WidgetLeads',
        path: '/widget/leads',
        element: <WidgetLeads />,
      },

      // Payment
      {
        name: 'PaymentSuccess',
        path: '/payment/success',
        element: <PaymentResolution isSuccess />,
      },
      {
        name: 'PaymentDeclined',
        path: '/payment/declined',
        element: <PaymentResolution isDeclined />,
      },
      {
        name: 'PaymentInvoiceNotFound',
        path: '/payment/invoice-not-found',
        element: <PaymentResolution isInvoiceNotFound />,
      },
      {
        name: 'PaymentGoCardlessMandateCallback',
        path: '/payment/gocardless-mandate/callback',
        element: <PaymentInvoice />,
      },
      {
        name: 'PaymentGoCardlessMandate',
        path: '/payment/gocardless-mandate/:userId/:tenantId',
        element: <PaymentGoCardlessMandate />,
      },
      {
        name: 'PaymentStripeMandate',
        path: '/payment/stripe-mandate/:userId/:locationId',
        element: <PaymentStripeMandate />,
      },
      {
        name: 'PaymentInvoice',
        path: '/payment/:invoiceId',
        element: <PaymentInvoice />,
      },

      // Health Providers
      {
        name: 'HealthProvidersAttendance',
        path: '/attendance',
        element: <HealthProvidersAttendance />,
      },

      // Other
      {
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
        element: <Terms />,
      },
      {
        name: 'TermsOfUse',
        path: '/terms-of-use',
        element: <Terms type='termsOfUse' />,
      },
      {
        name: 'DownloadApp',
        path: '/download',
        element: <DownloadApp isFullscreen />,
      },
    ],
  },

  {
    options: {
      isVisibleInNavigation: false,
      isSecure: false,
    },
    screens: [
      {
        name: 'BaseUrl',
        path: '/',
        element: (
          <Navigate replace state={{ onLoginPath }} to={onRedirectPath} />
        ),
      },
      {
        name: 'NotFound',
        path: '*',
        element: (
          <Navigate replace state={{ onLoginPath }} to={onRedirectPath} />
        ),
      },
    ],
  },
];
