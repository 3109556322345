import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useScheduledUserActionsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['scheduledUserActions', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/scheduled-user-actions`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            userId: filter.userId,
            status: filter.status,
            action: filter.action,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
          },
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useScheduledUserActionsCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/scheduled-user-actions`,
        data: {
          tenantId: data.tenantId || tenantId,
          action: data.action,
          userIds: data.userIds,
          actionDate: data.actionDate,
          proRataFee: data.proRataFee,
          releaseDate: data.releaseDate,
          note: data.note,
          isExtendPackageEndDate: data.isExtendPackageEndDate,
          isExcludedFromFutureBatches: data.isExcludedFromFutureBatches,
          lastDebitDate: data.lastDebitDate,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useScheduledUserActionsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/scheduled-user-actions/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['scheduledUserActions'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};
