import { Box, Button, Container, Modal } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  useInjuriesCreate,
  useInjuriesFind,
} from 'octiv-hooks/requests/Injuries';
import { useUsersFindMembers } from 'octiv-hooks/requests/Users';
import { canSetMemberModal } from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { views } from '../../userMember';
import FormNew from './FormNew';
import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const {
    selectedLocation,
    tenantId,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const { setMemberModal } = useMemberModal();

  const [toggle, setToggle, resetToggle] = useToggle();
  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    userTenantLocationId: selectedLocation?.id,
    userId: undefined,
  });

  const usersFindMembers = useUsersFindMembers({
    filter: {
      userTenantLocationId: query.userTenantLocationId,
      isMinimalData: true,
      deleted: 0,
    },
    paging: { perPage: -1 },
  });

  const injuriesFind = useInjuriesFind({
    filter: {
      ...query,
      tenantId,
      locationId: query.userTenantLocationId,
    },
    paging,
    include: 'user,injuryUpdates',
  });

  const injuriesCreate = useInjuriesCreate({ onSuccess: resetToggle });

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('logNewInjury')} onClose={resetToggle}>
          <FormNew
            isFetchingMembers={usersFindMembers.isFetching}
            isLoading={injuriesCreate.isLoading}
            members={usersFindMembers.data?.data}
            onSubmit={(values) =>
              injuriesCreate.mutate({ ...values, tenantId })
            }
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('injuries'),
          breadcrumbs: [t('members')],
          children: (
            <Box ml='auto'>
              <Button
                text={t('logNewInjury')}
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ),
        }}
        isLoading={injuriesFind.isFetching || injuriesCreate.isLoading}
      >
        <FormQuery
          initialValues={query}
          isFetchingMembers={usersFindMembers.isFetching}
          locationOptions={locationOptionsAll}
          members={usersFindMembers.data?.data}
          onSubmit={setQuery}
        />

        <Box height={4} />

        <Table
          data={injuriesFind.data?.data || []}
          isLoading={injuriesFind.isFetching}
          paging={injuriesFind.data?.paging}
          setPaging={setPaging}
          onClickRow={({ original }) =>
            canSetMemberModal({ userTenant: original?.userTenant, hasAccess })
              ? setMemberModal({
                  userId: original?.userId,
                  userTenantId: original?.userTenant?.id,
                  initialView: views.INJURIES,
                  onClose: injuriesFind.refetch,
                })
              : null
          }
        />
      </Container>
    </>
  );
};
