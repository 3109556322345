import { Formik } from 'formik';
import moment from 'moment';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ initialValues, locationOptions, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        locationId: initialValues?.locationId || undefined,
        billingDate: initialValues?.billingDate || undefined,
        search: initialValues?.search || undefined,
      }}
      validationSchema={Yup.object().shape({
        locationId: Yup.string().required(t('required')),
        billingDate: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit, setFieldTouched, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                doFinally={() => {
                  setFieldTouched('debitBatchId', false);
                  setFieldValue('debitBatchId', undefined);
                }}
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={4}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isDate
                hasCancelIcon={false}
                label={t('billingDate')}
                maxDate={moment().add(1, 'M').endOf('month')}
                minDate={moment().startOf('month')}
                name='billingDate'
              />
            </Col>

            <Col md={4}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
