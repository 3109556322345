import { Box, Table, Text } from 'octiv-components';
import { paymentGateways } from 'octiv-utilities/Constants';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value.name,
      },
      {
        Header: t('status'),
        Cell: ({
          row: {
            original: { location, paymentToken, onboardingForm },
          },
        }) => {
          if (paymentToken) {
            return (
              <Text as='span' color='success'>
                {t('onboarded')}
              </Text>
            );
          }
          if (location.billingPaymentGatewayId === paymentGateways.NO_PAYMENT) {
            return <Text as='span'>{t('notApplicable')}</Text>;
          }

          if (location.billingPaymentGatewayId === paymentGateways.PAYFAST) {
            return <Box dangerouslySetInnerHTML={{ __html: onboardingForm }} />;
          }

          if (location.billingPaymentGatewayId === paymentGateways.PAYSTACK) {
            return (
              <Text
                as='span'
                color='danger'
                onClick={() =>
                  onClickAction({
                    id: location?.id,
                    action: 'openPaystackOnboardingLink',
                  })
                }
              >
                {t('onboardNow')}
              </Text>
            );
          }

          if (
            location.billingPaymentGatewayId === paymentGateways.STRIPE_CONNECT
          ) {
            return (
              <Text
                as='span'
                color='danger'
                onClick={() =>
                  onClickAction({
                    id: location?.id,
                    action: 'openStripeConnectOnboardingLink',
                  })
                }
              >
                {t('onboardNow')}
              </Text>
            );
          }

          return null;
        },
      },
    ],
    [t, onClickAction]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
