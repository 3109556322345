import { Table, Text, Tooltip } from 'octiv-components';
import CardIcon from 'octiv-components/cardIcon';
import { getCardStatus, getCardStatusColor } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, query, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('brand'),
        accessor: 'paymentMethodInfo.brand',
        Cell: ({ value }) => <CardIcon code={value} size={'3rem'} />,
      },
      {
        Header: t('card'),
        accessor: 'paymentMethodInfo.last4',
        Cell: ({ value }) => {
          return <Text>**** **** **** {value}</Text>;
        },
      },
      {
        Header: t('expires'),
        accessor: 'paymentMethodInfo.expMonth',
        Cell: ({ row: { original } }) => {
          const { expMonth, expYear } = original?.paymentMethodInfo || {};
          const color = getCardStatusColor(expMonth, expYear);

          return (
            <Text color={color}>
              {expMonth} / {expYear}
            </Text>
          );
        },
      },
      {
        Header: t('status'),
        accessor: 'paymentMethodInfo.expYear',
        Cell: ({ row: { original } }) => {
          const { expMonth, expYear } = original?.paymentMethodInfo || {};
          const status = getCardStatus(expMonth, expYear);
          const color = getCardStatusColor(expMonth, expYear);

          return (
            // TODO: incorporate "Default"
            <Text color={color}>{t(status)}</Text>
          );
        },
      },
      {
        Header: t('actions'),
        accessor: 'token',
        Cell: ({ value: token }) => (
          <Tooltip
            options={[
              { label: t('linkToLocation'), value: 'linkToLocation' },
              ...(tableData?.length > 1
                ? [{ label: t('delete'), value: 'delete' }]
                : []),
            ]}
            onClick={({ value }) => onClickAction({ action: value, id: token })}
          />
        ),
      },
    ],
    [onClickAction, tableData, t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
