import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  initialValues,
  isFetchingPackages,
  packages,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        userIds: initialValues.userIds,
        packageIds: undefined,
        endingOn: getDateYearMonthDay({ canFallback: true }),
      }}
      validationSchema={Yup.object().shape({
        userIds: Yup.array().required(t('required')).min(1, t('required')),
        packageIds: Yup.array().required(t('required')).min(1, t('required')),
        endingOn: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isMulti
                isSelect
                isLoading={isFetchingPackages}
                label={t('packages')}
                name='packageIds'
                options={packages?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col>
              <Field isDate label={`${t('endDate')}`} name='endingOn' />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
