import { AcsProviderImages, Images } from 'octiv-assets';
import {
  Box,
  Button,
  Container,
  Icon,
  Image,
  ProcessingSpinner,
  Text,
} from 'octiv-components';
import { useAccessControlFindSystems } from 'octiv-hooks/requests/AccessControl';
import { toCamelCase } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TableAccessSystems from './TableAccessSystems';

export default ({ onBack, query, locationOptionsAll, manageDeviceDetails }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const accessControlFindSystems = useAccessControlFindSystems({
    filter: { ...query, type: 1 },
  });

  const locationMap = useMemo(() => {
    const map = {};
    locationOptionsAll.forEach((location) => {
      if (location.value) {
        map[location.value] = location.label;
      }
    });
    return map;
  }, [locationOptionsAll]);

  const processedData = useMemo(() => {
    return (accessControlFindSystems?.data?.data || []).map((system) => ({
      ...system,
      location: locationMap[system.locationId] || t('notAssigned'),
    }));
  }, [accessControlFindSystems?.data?.data, locationMap, t]);

  const onClickAction = ({ action, id, locationId }) => {
    switch (action) {
      case 'viewEdit':
      default:
        navigate(`/access-control/systems/${id}?locationId=${locationId}`);
    }
  };

  return (
    <Container
      appBarProps={{
        title: t('manageDevices'),
        breadcrumbs: [t('accessControl')],
        children: (
          <Box mb={5} ml='auto'>
            <Box isFlex>
              <Button
                hasBorder
                mr={2}
                text={t('goBack')}
                onClick={() => onBack()}
              />
            </Box>
          </Box>
        ),
      }}
    >
      {accessControlFindSystems.isFetching ? (
        <Box isFlex alignItems='center' height='70vh' justifyContent='center'>
          <ProcessingSpinner size={20} />
        </Box>
      ) : processedData.length > 0 ? (
        <>
          <Box isFlex alignItems='center' my={1}>
            <Image
              hasRadius
              usePlaceholderUser
              backgroundSize='contain'
              mr={3}
              size={10}
              src={
                manageDeviceDetails?.providerName
                  ? AcsProviderImages[
                      toCamelCase(manageDeviceDetails?.providerName)
                    ]
                  : Images.user
              }
            />
            <Text>{processedData[0]?.account?.name}</Text>
          </Box>
          <Text color='grey1'>{manageDeviceDetails?.userDetails}</Text>

          <br />
          <Box isFlex>
            <Box
              hasBorder
              hasRadius
              isFlex
              borderColor='grey5'
              borderStyle='solid'
              borderWidth='1px'
              flex={1}
              flexDirection='column'
              minWidth='200px'
              mr={4}
              p={4}
            >
              <Box borderBottom='1px solid' borderColor='grey4' mb={3} pb={2}>
                <Text isBold>{t('status')}</Text>
                <Box isFlex alignItems='center' mt={1}>
                  <Icon
                    color={
                      manageDeviceDetails?.isConnected ? 'success' : 'danger'
                    }
                    customSize='0.7rem'
                    mr={1}
                    name='circle'
                  />
                  <Text lineHeight={2} variant='caption'>
                    {manageDeviceDetails?.isConnected
                      ? t('connected')
                      : t('offline')}
                  </Text>
                </Box>
              </Box>

              <Box borderBottom='1px solid' borderColor='grey4' mb={3} pb={2}>
                <Text isBold>{t('pairedOn')}</Text>
                <Text color='grey1' mt={1}>
                  {processedData[0]?.account?.createdAt}
                </Text>
              </Box>
              <Box>
                <Text isBold>{t('location')}</Text>
                <Text color='grey1' mt={1}>
                  {processedData[0]?.location}
                </Text>
              </Box>
            </Box>

            <TableAccessSystems
              data={processedData}
              isLoading={accessControlFindSystems.isFetching}
              onClickAction={onClickAction}
            />
          </Box>
        </>
      ) : (
        <Container
          isFlex
          alignItems='center'
          border={[1]}
          borderColor='grey4'
          flexDirection='column'
          height='80vh'
          justifyContent='center'
        >
          <Icon customSize='5rem' mb={3} name='devices' />
          <Text isBold fontSize='2rem' mb={2} textAlign='center'>
            {t('noManagedDevicesFound')}
          </Text>
          <Text fontSize='1rem' textAlign='center'>
            {t('devicesNotSynced')}
          </Text>
          <Text fontSize='1rem' textAlign='center'>
            {t('processTakesTime')}
          </Text>
        </Container>
      )}
    </Container>
  );
};
