import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useClassDatesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {} } = input || {};

  return useQuery(
    ['classDates', 'find', input],
    ({ signal }) =>
      request({
        method: 'get',
        url: `/api/class-dates`,
        signal,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            between: filter.between,
            packageIds: filter.packageIds,
            instructorId: filter.instructorId,
            supportingInstructorId: filter.supportingInstructorId,
            classId: filter.classId,
            isSession: filter.isSession,
            tagIds: filter.tagIds,
            classBookingsForLeadToken: filter.classBookingsForLeadToken,
            isVisibleInApp: filter.isVisibleInApp,
          },
          ...paging,
        },
      }),
    options
  );
};

export const useClassDatesFindByIdClassBookingDetails = (input, options) => {
  const { id, include, append } = input || {};

  return useQuery(
    ['classDates', 'findByIdClassBookingDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/class-dates/${id}/class-booking-details`,
        params: {
          include,
          append,
        },
      }),
    options
  );
};

export const useClassDatesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['classDates', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/class-dates/${id}`,
      }),
    options
  );
};

export const useClassDatesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/class-dates/${id}`,
        data: {
          startTime: data.startTime,
          endTime: data.endTime,
          limit: data.limit,
          meetingUrl: data.meetingUrl,
          instructorId: data.instructorId,
          supportingInstructorId: data.supportingInstructorId,
          name: data.name,
          description: data.description,
          tagIds: data.tagIds,
          autoCancelThresholdMin: Number(data.autoCancelThresholdMin) || 0,
          minBookedMembersCount: Number(data.minBookedMembersCount) || 0,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassDatesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/class-dates/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassDatesCreateSendMessageToTrainer = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/class-dates/${id}/send-message-to-coach`,
        data: { message: data.message },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassDatesCreateSendMessageToClassBookings = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/class-dates/${id}/send-message-to-class-bookings`,
        data: { message: data.message },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassDatesCreateSendMessageToWaitingList = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/class-dates/${id}/send-message-to-waiting-list`,
        data: { message: data.message },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassDatesUpdateBulkCoachChange = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/class-dates/bulk-coach-change`,
        data: {
          tenantId,
          classDateIds: data.classDateIds,
          coachId: data.trainerId,
          instructorId: data.headTrainerUserTenantId,
          // TODO: below seems wrong
          // headCoachUserId: data.trainerId || data.headTrainerUserTenantId || id,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassDatesUpdateBulkLimitChange = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/class-dates/bulk-limit-change`,
        data: {
          classDateIds: data.classDateIds,
          limit: data.limit,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useClassDatesDeleteBulkDelete = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'delete',
        url: `/api/class-dates/bulk-delete`,
        data: {
          classDateIds: data.classDateIds,
          tenantId: data.tenantId || tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['classDates'] });

      options?.onSuccess?.(...res);
    },
  });
};
