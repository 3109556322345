import {
  Box,
  Button,
  Calendar,
  Chip,
  Container,
  Modal,
  Toast,
  Tooltip,
  Workout,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMediaQuery, useToggle } from 'octiv-hooks';
import { useExerciseCategoriesFind } from 'octiv-hooks/requests/ExerciseCategories';
import { useExercisesFind } from 'octiv-hooks/requests/Exercises';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import {
  useWorkoutsCreate,
  useWorkoutsDelete,
  useWorkoutsFind,
  useWorkoutsUpdate,
} from 'octiv-hooks/requests/Workouts';
import {
  getDateYearMonthDay,
  sanitizeWorkout,
} from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import FormQuery from './FormQuery';
import FormWorkout from './FormWorkout';

export default () => {
  const { t } = useTranslation();
  const { xsDown } = useMediaQuery();

  const hasAccess = useContext(AccessContext);
  const [toggle, setToggle, resetToggle] = useToggle();
  const [workouts, setWorkouts] = useState([]);
  const [query, setQuery] = useState({
    programmeId: undefined,
    startsAfter: undefined,
    endsBefore: undefined,
  });
  const isCreateWorkout = toggle.type === 'create';

  const workoutsFind = useWorkoutsFind(
    {
      filter: query,
      paging: { perPage: -1 },
    },
    {
      enabled: !!query.startsAfter,
      onSuccess: ({ data }) =>
        setWorkouts(
          data.map((workout) => ({
            allDay: true,
            start: workout.date,
            extendedProps: sanitizeWorkout({
              workout,
              identifyBenchmarks: true,
            }),
          }))
        ),
    }
  );

  const exerciseCategoriesFind = useExerciseCategoriesFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const exercisesFind = useExercisesFind({
    filter: { isBenchmark: 1, isActive: 1 },
    paging: { perPage: -1 },
  });

  const programmesFind = useProgrammesFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const workoutsCreate = useWorkoutsCreate({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const workoutsUpdate = useWorkoutsUpdate({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const workoutsDelete = useWorkoutsDelete({
    onSuccess: resetToggle,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const putPostWorkoutRequest = (values) => {
    if (toggle.type === 'create') {
      workoutsCreate.mutate(values);
    } else {
      workoutsUpdate.mutate({ id: toggle.data?.id, ...values });
    }
  };

  const onClickAction = ({ action }) => {
    switch (action) {
      case 'duplicate':
        setToggle({
          type: 'create',
          data: {
            ...toggle.data,
            id: undefined,
            date: undefined,
            exercises: toggle.data.exercises.map((exercise) => ({
              ...exercise,
              id: undefined,
            })),
          },
        });

        toast.info(<Toast title={t('workoutDuplicated')} variant='info' />);
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          workoutsDelete.mutate({ id: toggle.data.id });
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      {toggle.isVisible && (
        <Modal
          renderRightTitle={() =>
            !isCreateWorkout && (
              <Box isFlex>
                <Chip
                  hasAlternateBackground
                  color='primary'
                  icon='fullscreen'
                  mr={2}
                  title={t('trainerView')}
                  onClick={() =>
                    window.open(`/workouts/trainer/${toggle.data.id}`)
                  }
                />

                <Tooltip
                  options={[
                    { label: t('duplicate'), value: 'duplicate' },
                    { label: t('delete'), value: 'delete' },
                  ]}
                  onClick={({ value }) => onClickAction({ action: value })}
                >
                  <Chip
                    hasAlternateBackground
                    color='primary'
                    icon='more_vertical'
                    mr={2}
                    title={t('actions')}
                  />
                </Tooltip>
              </Box>
            )
          }
          title={isCreateWorkout ? t('createWorkout') : t('updateWorkout')}
          onClose={resetToggle}
        >
          <FormWorkout
            benchmarks={exercisesFind.data?.data}
            exerciseCategories={exerciseCategoriesFind.data?.data}
            initialValues={{
              ...toggle.data,
              programmeId: toggle.data?.programme?.id || query.programmeId,
            }}
            isCreateWorkout={isCreateWorkout}
            isFetchingBenchmarks={exercisesFind.isFetching}
            isFetchingExerciseCategories={exerciseCategoriesFind.isFetching}
            isFetchingProgrammes={programmesFind.isFetching}
            isLoading={workoutsCreate.isLoading || workoutsUpdate.isLoading}
            programmes={programmesFind.data?.data}
            onSubmit={putPostWorkoutRequest}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: t('manageWorkouts'),
          breadcrumbs: [t('workouts')],
          children: hasAccess.featureWorkoutsActions ? (
            <Box ml='auto'>
              <Button
                text={t('createNew')}
                onClick={() => setToggle({ type: 'create' })}
              />
            </Box>
          ) : undefined,
        }}
        isLoading={
          workoutsFind.isFetching ||
          workoutsUpdate.isLoading ||
          workoutsCreate.isLoading ||
          workoutsDelete.isLoading
        }
      >
        <FormQuery
          initialValues={query}
          isFetchingProgrammes={programmesFind.isFetching}
          programmes={programmesFind.data?.data}
          onSubmit={({ programmeId }) =>
            setQuery((prev) => ({ ...prev, programmeId }))
          }
        />

        <Box height={4} />

        <Calendar
          allDaySlot
          dateClick={
            hasAccess.featureWorkoutsActions
              ? ({ dateStr }) =>
                  setToggle({
                    type: 'create',
                    data: { date: dateStr },
                  })
              : undefined
          }
          datesSet={({ start, end }) =>
            setQuery((prev) => ({
              ...prev,
              startsAfter: getDateYearMonthDay({ date: start }),
              endsBefore: getDateYearMonthDay({ date: end }),
            }))
          }
          eventClick={
            hasAccess.featureWorkoutsActions
              ? ({ event: { extendedProps } }) =>
                  setToggle({
                    type: 'update',
                    data: extendedProps,
                  })
              : undefined
          }
          eventContent={({ event: { extendedProps }, view: { type } }) => (
            <Workout
              data={extendedProps}
              isOverview={type === 'dayGridMonth'}
              p={2}
            />
          )}
          events={workouts || []}
          initialView={xsDown ? 'listWeek' : 'timeGridWeek'}
          slotMaxTime='24:00:00'
          slotMinTime='24:00:00'
        />
      </Container>
    </>
  );
};
