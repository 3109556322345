import {
  Box,
  ConfirmationDialog,
  Icon,
  InlineUser,
  Table,
  Tooltip,
  VaccinationIcon,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { messageTypes } from './index';

export default ({
  data,
  date,
  isLateCancellations,
  isPrint,
  isWaitingList,
  onClickAction,
  canDisplayExtraDetailsInClass,
  ...props
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [noShowUserId, setNoShowUserId] = useState(undefined);
  const { t } = useTranslation();

  const { toCurrency } = useActiveUserTenant();

  const handleConfirm = useCallback(
    (id, isNoShow) => {
      setNoShowUserId(id);
      if (isNoShow) {
        onClickAction({ action: 'bookingNoShow', id });
      } else {
        setOpenDialog(true);
      }
    },
    [onClickAction]
  );

  const handleDialogClose = () => {
    setNoShowUserId(undefined);
    setOpenDialog(false);
  };

  const handleDialogConfirm = useCallback(() => {
    onClickAction({ action: 'bookingNoShow', id: noShowUserId });
    setOpenDialog(false);
  }, [onClickAction, noShowUserId]);

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('attendee'),
        id: 'attendee',
        Cell: ({ row: { original } }) => (
          <InlineUser
            {...original}
            isVisibleNonMemberEmail
            isVisibleOverdue
            dateBirthdayComparison={date}
            isDiscoveryLead={original?.leadMember?.source === 'discovery'}
            isVisibleBookingInfo={!isPrint}
            isVisibleUserImage={!isPrint}
          />
        ),
      },
      {
        Header: t('totalBookings'),
        id: 'nthBookingAtLocation',
        accessor: 'nthBookingAtLocation',
      },
      {
        Header: t('risk'),
        id: 'risk',
        Cell: ({
          row: {
            original: { user, coronavirusVaccinationDetails },
          },
        }) =>
          user?.userTenant && (
            <Box isFlex>
              <VaccinationIcon {...coronavirusVaccinationDetails} mr={2} />

              {user.userTenant.isHighRisk ? (
                <Icon color='warning' mr={2} name='warning' />
              ) : null}

              {user.isInjured ? <Icon color='danger' name='healing' /> : null}
            </Box>
          ),
      },
      {
        Header: t('packages'),
        id: 'packages',
        accessor: 'user.userTenant.userPackages',
        whiteSpaceWrap: true,
        Cell: ({ value }) =>
          value
            ? value
                .map(
                  (item) =>
                    `${item?.package?.name} (${item.sessionsAvailableText})${
                      item.endDate
                        ? ` - ${t('end')}: ${getDateReadableDayMonthYear({
                            date: item.endDate,
                          })})`
                        : ''
                    }`
                )
                .join(', ')
            : '',
      },
      {
        Header: t('outstanding'),
        id: 'outstanding',
        Cell: ({
          row: {
            original: { user },
          },
        }) =>
          user?.isOverdue
            ? toCurrency({ value: user?.outstandingBalance })
            : null,
      },
      {
        Header: t('mobile'),
        id: 'mobile',
        accessor: 'user.mobile',
      },
      {
        Header: t('attendance'),
        id: 'attendance',
        accessor: 'user',
        Cell: ({
          row: {
            original: { id, checkedInAt, status },
          },
        }) => (
          <Box isFlex>
            <Icon
              color={checkedInAt ? 'success' : 'grey3'}
              name='check_circle'
              px={1}
              tooltip='Check-In'
              onClick={() =>
                onClickAction({
                  action: checkedInAt
                    ? 'bookingCheckInCancel'
                    : 'bookingCheckIn',
                  id,
                })
              }
            />

            <Icon
              color={status.id === 5 ? 'warning' : 'grey3'}
              name='visibility_off'
              px={1}
              tooltip={t('noShow')}
              onClick={() => handleConfirm(id, status.id === 5)}
            />
          </Box>
        ),
      },
      {
        Header: t('actions'),
        id: 'actions',
        Cell: ({
          row: {
            original: { id, user },
          },
        }) => (
          <Tooltip
            options={[
              ...(isWaitingList
                ? [
                    {
                      label: t('convertToBooking'),
                      value: 'bookingWaitingConvertToBooking',
                    },
                  ]
                : []),
              {
                label: isWaitingList
                  ? t('removeFromWaitingList')
                  : t('cancelBooking'),
                value: isWaitingList ? 'bookingWaitingCancel' : 'bookingCancel',
              },
              {
                label: `${t('message')} ${user?.name}`,
                value: isWaitingList
                  ? messageTypes.memberWaitingList
                  : messageTypes.memberBooking,
              },
            ]}
            onClick={({ value }) =>
              onClickAction({
                action: value,
                id,
                user,
              })
            }
          />
        ),
      },
    ],
    [date, isPrint, isWaitingList, onClickAction, toCurrency, t, handleConfirm]
  );

  const hiddenColumns = useMemo(
    () => [
      ...(isPrint || isWaitingList || isLateCancellations
        ? ['attendance']
        : []),
      ...(isPrint || isLateCancellations ? ['actions'] : []),
      ...(!isPrint ? ['mobile'] : []),
      ...(!canDisplayExtraDetailsInClass ? ['packages', 'outstanding'] : []),
    ],
    [canDisplayExtraDetailsInClass, isPrint, isWaitingList, isLateCancellations]
  );

  return (
    <>
      <Table
        highlightTestFunc={({ original: { isUserOverdueAtLocation } }) =>
          isUserOverdueAtLocation === true
        }
        {...(isPrint ? { bg: 'white' } : {})}
        columns={tableColumns}
        data={tableData}
        hiddenColumns={hiddenColumns}
        noDataText={t('noBookings')}
        {...props}
      />
      <ConfirmationDialog
        isConfirm
        message={t('noShowError')}
        open={openDialog}
        title={t('noShow')}
        onCancel={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
    </>
  );
};
