import { Formik } from 'formik';
import { Alert, Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ initialValues = {}, isLoading, ...props }) => {
  const { t } = useTranslation();

  const isNetcash =
    initialValues?.location?.paymentGatewayId === 4 ||
    initialValues?.paymentGateway?.id === 4;

  const isStripeConnect =
    initialValues?.location?.paymentGatewayId === 9 ||
    initialValues?.paymentGateway?.id === 9;

  const intitialDate = isStripeConnect
    ? getDateYearMonthDay({ date: new Date() })
    : getDateYearMonthDay({ date: initialValues.processedAt }) || undefined;

  return (
    <Formik
      {...props}
      initialValues={{
        id: initialValues.id,
        ...(isNetcash
          ? { isProcessAsSameDayBatch: true }
          : {
              date: intitialDate,
            }),
      }}
      validationSchema={Yup.object().shape({
        id: Yup.string(),
        ...(isNetcash
          ? {
              isProcessAsSameDayBatch: Yup.bool().required(t('required')),
            }
          : { date: Yup.string().required(t('required')) }),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            {isNetcash ? (
              <Col>
                <Field
                  isRadio
                  name='isProcessAsSameDayBatch'
                  options={[
                    {
                      label: t('processSameDayBatch'),
                      value: true,
                    },
                    {
                      label: t('processTwoDayBatch'),
                      value: false,
                    },
                  ]}
                />
              </Col>
            ) : (
              <Col>
                {initialValues.processedAt && (
                  <Alert
                    mb={4}
                    subtitle={t('choosingNewDate')}
                    variant='warning'
                  />
                )}

                <Field
                  isDate
                  isDisabled={isStripeConnect}
                  label={t('date')}
                  name='date'
                />
              </Col>
            )}

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
