import { Formik } from 'formik';
import { Button, Field } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, isCreate, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        isForSignUp: initialValues.isForSignUp ? 1 : 0,
        ...(isCreate
          ? {}
          : {
              isActive: initialValues.isActive,
            }),
      }}
      validationSchema={Yup.object().shape({
        isForSignUp: Yup.number(),
        ...(isCreate
          ? {}
          : {
              isActive: Yup.number().required(t('required')),
            }),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            isCheckbox
            name='isForSignUp'
            options={[
              {
                label: t('useGatewayForLimitedPackagePurchases', {
                  paymentGateway: 'GoCardless',
                }),
                value: 1,
              },
            ]}
          />

          {!isCreate && (
            <Field
              isHorizontal
              isRadio
              boxContainerProps={{ mb: 4 }}
              label={t('gatewayStatus')}
              name='isActive'
              options={[
                { label: t('active'), value: 1 },
                { label: t('deactivated'), value: 0 },
              ]}
            />
          )}

          <Button
            isDisabled={false}
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
