import { Modal, ProcessingSpinner } from 'octiv-components';
import { useUserPackageModal } from 'octiv-context/UserPackageModal';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import {
  useUserPackagesBulkCreate,
  useUserPackagesCreate,
  useUserPackagesUpdate,
} from 'octiv-hooks/requests/UserPackages';
import {
  useUserTenantsFindById,
  useUserTenantsFindByIdPaymentDetails,
} from 'octiv-hooks/requests/UserTenants';
import { paymentTypes } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormPackage from './FormPackage';

export default () => {
  const { t } = useTranslation();

  const {
    userId,
    userIds,
    userTenantId,
    userLocationId,
    validPackageIds,
    initialUserPackage,
    onClose,
    resetUserPackageModal,
  } = useUserPackageModal();

  const [tenantPackages, setTenantPackages] = useState();

  const isCreate = !initialUserPackage?.id;
  const isBulk = userIds !== undefined;

  const onCloseModal = () => {
    resetUserPackageModal();
    if (onClose) onClose();
  };

  const userTenantsFindById = useUserTenantsFindById(
    {
      id: userTenantId,
      include: 'locations',
    },
    {
      enabled: !userLocationId && !isBulk,
    }
  );

  const locationId = isBulk
    ? undefined
    : userLocationId || userTenantsFindById.data?.locations?.[0]?.id;

  const userTenantsFindByIdPaymentDetails =
    useUserTenantsFindByIdPaymentDetails(
      {
        id: userTenantId,
      },
      {
        enabled: isCreate && !isBulk,
      }
    );

  const isCashMember =
    userTenantsFindByIdPaymentDetails.data?.paymentDetails?.userDebitStatus
      ?.id === paymentTypes.CASH;
  const isDebitOrderMember =
    userTenantsFindByIdPaymentDetails.data?.paymentDetails?.userDebitStatus
      ?.id === paymentTypes.DEBIT_ORDER;
  const isTokenizedCardMember =
    userTenantsFindByIdPaymentDetails.data?.paymentDetails?.userDebitStatus
      ?.id === paymentTypes.CARD;

  const packagesFind = usePackagesFind(
    {
      filter: isCreate
        ? {
            typeId: '1,2,3',
            isHidden: 0,
            isActive: 1,
            locationId,
          }
        : { packageId: initialUserPackage.package.id },
      paging: { perPage: -1 },
    },
    {
      onSuccess: (response) => {
        setTenantPackages(
          validPackageIds?.[0]
            ? response?.data?.filter((item) =>
                validPackageIds.includes(item.id)
              )
            : response?.data
        );
      },
      enabled: !!locationId || isBulk,
    }
  );

  const userPackagesCreate = useUserPackagesCreate();

  const userPackagesBulkCreate = useUserPackagesBulkCreate({
    onSuccess: onCloseModal,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const userPackagesUpdate = useUserPackagesUpdate({
    onSuccess: onCloseModal,
  });

  const onSubmitFormPackage = ({
    invoice: { shouldGenerate, ...invoice },
    ...packageDetails
  }) => {
    if (isCreate) {
      if (isBulk) {
        userPackagesBulkCreate.mutate({
          userIds,
          ...packageDetails,
          ...(shouldGenerate ? { invoice } : {}),
        });
      } else {
        userPackagesCreate.mutate(
          {
            userId,
            ...packageDetails,
            ...(shouldGenerate ? { invoice } : {}),
          },
          {
            onSuccess: (response) => {
              const invoiceCode = response?.invoices?.[0]?.code;
              if (invoice.paymentType === 'adhocPayment' && invoiceCode) {
                window.open(
                  `/accounts/invoices?requestPaymentCode=${invoiceCode}`
                );
              }

              onCloseModal();
            },
            onSuccessToast:
              invoice.paymentType === 'adhocPayment'
                ? undefined
                : {
                    title: t('actionSuccessful'),
                  },
          }
        );
      }
    } else {
      userPackagesUpdate.mutate({
        id: initialUserPackage.id,
        ...packageDetails,
      });
    }
  };

  return (
    <Modal
      isSidebar
      title={`${isCreate ? t('createPackage') : t('updatePackage')}`}
      onClose={onCloseModal}
    >
      {userTenantsFindById.isFetching ||
      userTenantsFindByIdPaymentDetails.isFetching ? (
        <ProcessingSpinner mx='auto' />
      ) : (
        <FormPackage
          initialValues={initialUserPackage}
          isBulk={isBulk}
          isCashMember={isCashMember}
          isCreate={isCreate}
          isDebitOrderMember={isDebitOrderMember}
          isFetchingTenantPackages={packagesFind.isFetching}
          isFetchingUserPaymentDetails={
            userTenantsFindByIdPaymentDetails.isFetching
          }
          isLoading={
            userPackagesCreate.isLoading ||
            userPackagesBulkCreate.isLoading ||
            userPackagesUpdate.isLoading
          }
          isTokenizedCardMember={isTokenizedCardMember}
          tenantPackages={tenantPackages}
          onSubmit={onSubmitFormPackage}
        />
      )}
    </Modal>
  );
};
