import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

// #region Accounts
export const useAccessControlDeleteAccount = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/accounts/${data.id}/delete`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlCreateAccount = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/accounts`,
        data: {
          locationId: data.locationId,
          providerId: data.providerId,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControlAccounts'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlFindAccounts = (input, options) => {
  const { filter = {}, paging = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['accessControlAccounts', 'findACSAccounts', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/accounts`,
        params: {
          filter: {
            locationId: filter.locationId,
            tenantId,
          },
          ...paging,
        },
      }),
    options
  );
};
// #endregion

// #region Systems
export const useAccessControlFindSystems = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['accessControl', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/systems`,
        params: {
          include: 'account',
          filter: {
            locationId: filter.locationId,
            type: filter.type,
            accountId: filter.accountId,
            tenantId,
          },
        },
      }),
    options
  );
};

export const useAccessControlFindSystemsById = (input, options) => {
  const { filter } = input || {};

  return useQuery(
    ['accessControlSystems', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/systems/${filter.id}`,
      }),
    options
  );
};

export const useAccessControlUpdateSystems = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/access-control/systems/${input.id}`,
        data: {
          locationId: data.locationId,
          name: data.name,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControlSystems'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlFindByIdSystemUsers = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['accessControl', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/systems/${filter.id}/users`,
        params: {
          filter: {
            locationId: filter.locationId,
            status: filter.status,
            tenantId,
          },
        },
      }),
    options
  );
};
// #endregion

// #region Users

export const useUserListFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['users', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/users`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            userTenantPackageId: filter.userTenantPackageId,
            userTenantLocationId: filter.userTenantLocationId,
            userTenantStatusId: filter.userTenantStatusId,
            userTenantTypeId: filter.userTenantTypeId,
            ...(filter.systemId && {
              not_in_access_system_id: filter.systemId,
            }),
          },
          ...paging,
          ...queryParams,
        },
      }),
    options
  );
};

export const useAccessControlDeleteSystemUsers = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${data.id}/users/delete`,
        data: {
          acsUserIds: data.acsUserIds,
          tenantId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlCreateSystemsByIdUsersLink = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${data.id}/users/link`,
        data: {
          acsUserId: data.acsUserIds,
          userId: data.userId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlCreateUsers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${input.id}/users`,
        data: {
          acsGroupIds: data.acsGroupIds,
          accessMethod: data.accessMethod, // TODO: confirm what the options are / will be
          userIds: data.userIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlCreateUsersSuspend = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${data.id}/users/suspend`,
        data: {
          acsUserIds: data.acsUserIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlCreateUsersUnsuspend = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${data.id}/users/unsuspend`,
        data: {
          acsUserIds: data.acsUserIds,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};
// #endregion

// #region UserGroups
export const useAccessControlFindSystemGroups = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['accessControl', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/systems/${filter.id}/groups`,
        params: {
          filter: {
            acs_user_id: filter.manageUserId,
          },
        },
      }),
    options
  );
};
export const useAccessControlFindEntrances = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['accessControlEntrances', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/systems/${filter.id}/entrances`,
      }),
    options
  );
};

export const useAccessControlDeleteSystemGroups = (input, options) => {
  const { filter = {} } = input || {};
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['accessControl', 'find', input],
    () =>
      request({
        method: 'delete',
        url: `/api/access-control/systems/${input.id}/groups`,
        params: {
          filter: {
            locationId: filter.locationId,
            tenantId,
          },
        },
      }),
    options
  );
};
export const useAccessControlGetUsersInAGroup = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['accessControl', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/systems/${filter.id}/groups/users`,
        params: {
          filter: {
            acs_group_id: filter.acsGroupId,
          },
        },
      }),
    options
  );
};

export const useAccessControlCreateSystemGroupAddUsers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${input.id}/groups/add-users`,
        data: {
          acsUserIds: data.acsUserIds,
          acs_group_id: data.acsGroupId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlCreateSystemGroupRemoveUsers = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${input.id}/groups/remove-users`,
        data: {
          acsUserIds: data.acsUserIds,
          acs_group_id: data.acsGroupId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControl'] });

      options?.onSuccess?.(...res);
    },
  });
};
// #endregion

// #region Credentials
export const useAccessControlFindSystemCredentials = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['accessControlCredentials', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/access-control/systems/${filter.id}/credentials`,
        params: {
          filter: {
            acs_user_id: filter.acsUserId,
          },
        },
      }),
    options
  );
};

export const useAccessControlCredentialsAccessMethod = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `api/access-control/systems/${input.id}/credentials`,
        data: {
          acsUserIds: data.acsUserIds,
          accessMethod: data.accessMethod,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControlCredentials'] });
      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlDeleteSystemCredentials = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${input.id}/credentials/delete`,
        data: {
          acs_credential_id: data.acsCredentialId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControlCredentials'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useAccessControlCreateSystemCredentials = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/access-control/systems/${input.id}/groups/credentials`,
        data: {
          acsUserIds: data.acsUserIds,
          accessMethod: data.accessMethod,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControlCredentials'] });

      options?.onSuccess?.(...res);
    },
  });
};
// #endregion

// #region Packages
export const useAccessControlFindSystemPackages = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...rest } = input || {};

  return useQuery(
    ['packages', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/packages`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            isActive: 1,
          },
          ...paging,
          ...rest,
        },
      }),
    options
  );
};

export const useAccessControlGetPackageIdsInAGroup = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['accessControlPackages', 'find', input],
    () =>
      request({
        method: 'get',
        returnFullResponse: true,
        url: `/api/access-control/systems/${filter.id}/groups/show`,
        params: {
          filter: {
            acs_group_id: filter.acsGroupId,
          },
        },
      }),
    options
  );
};

export const useAccessControlUpdateSystemPackages = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'patch',
        url: `/api/access-control/systems/${input.id}/groups/update`,
        data: {
          acs_group_id: data.acsGroupId,
          package_ids: data.packageIds,
          is_default_for_staff: data.isDefaultForStaff,
          is_default_for_members: data.isDefaultForMembers,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['accessControlPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

// #endregion
