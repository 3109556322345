import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectAccountManagement,
  ConnectComponentsProvider,
  ConnectDocuments,
} from '@stripe/react-connect-js';
import {
  Card,
  Container,
  Divider,
  ProcessingSpinner,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useFinancesCreateStripeConnectAccountSession } from 'octiv-hooks/requests/Finances';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import FormQuery from './FormQuery';

export default () => {
  const { t } = useTranslation();
  const { colors } = useContext(ThemeContext);

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
  });

  const stripeConnectInstance = React.useRef();

  const financesStripeConnectAccountSession =
    useFinancesCreateStripeConnectAccountSession();

  const fetchClientSecret = React.useCallback(async () => {
    let clientSecret;

    try {
      const response = await financesStripeConnectAccountSession.mutateAsync({
        locationId: query.locationId,
      });

      clientSecret = response?.clientSecret;
      // eslint-disable-next-line no-empty
    } catch (error) {}

    return clientSecret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.locationId]);

  React.useEffect(() => {
    stripeConnectInstance.current = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_CONNECT_PUBLISHABLE_KEY,
      fetchClientSecret,
      appearance: {
        variables: {
          actionPrimaryColorText: colors.primary,
          actionSecondaryColorText: colors.primary20,
          badgeBorderRadius: '6px',
          borderRadius: '6px',
          buttonBorderRadius: '6px',
          buttonPrimaryColorBackground: colors.primary,
          buttonPrimaryColorBorder: colors.primary,
          buttonPrimaryColorText: colors.primaryForeground,
          buttonSecondaryColorBackground: colors.grey6,
          buttonSecondaryColorBorder: colors.primary,
          buttonSecondaryColorText: colors.primary,
          colorBackground: colors.grey6,
          colorBorder: colors.grey1,
          colorDanger: colors.danger,
          colorPrimary: colors.primary,
          colorText: colors.font,
          fontFamily: '"Inter", sans-serif',
          fontSizeBase: '14px',
          formBorderRadius: '6px',
          formHighlightColorBorder: colors.primary,
          overlayBorderRadius: '6px',
          spacingUnit: '10px',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.locationId]);

  return (
    <Container
      appBarProps={{
        title: `Stripe ${t('account')}`,
        breadcrumbs: [t('settings')],
      }}
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptions}
        onSubmit={setQuery}
      />

      <Divider pb={4} />

      {stripeConnectInstance.current &&
      financesStripeConnectAccountSession.data?.clientSecret ? (
        <ConnectComponentsProvider
          connectInstance={stripeConnectInstance.current}
        >
          <Card>
            <Text variant='heading'>
              {t('account')} {t('settings')}
            </Text>

            <Text
              color='grey1'
              lineHeight='normal'
              mb={6}
              mt={1}
              variant='body'
            >
              {t('stripeAccountSettingsDescription')}
            </Text>

            <ConnectAccountManagement />
          </Card>

          <Divider pb={8} />

          <Card>
            <Text variant='heading'>{t('documents')}</Text>

            <Text
              color='grey1'
              lineHeight='normal'
              mb={6}
              mt={1}
              variant='body'
            >
              {t('stripeDocumentsDescription')}
            </Text>

            <ConnectDocuments />
          </Card>
        </ConnectComponentsProvider>
      ) : (
        <ProcessingSpinner mx='auto' size={6} />
      )}
    </Container>
  );
};
