import { Chip, Tooltip } from 'octiv-components';
import { languageOptions } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = ({ isVisibleTitle = true, ...props }) => {
  const { t, i18n } = useTranslation();

  const [isChangingLanguage, setIsChangingLanguage] = useState(false);

  const onChangeLanguage = async ({ value }) => {
    setIsChangingLanguage(true);

    await i18n.changeLanguage(value);

    window.location.reload();
  };

  return (
    <Tooltip
      options={[{ title: t('languageSelection') }, ...languageOptions]}
      onClick={onChangeLanguage}
    >
      <Chip
        hasAlternateBackground
        icon='language'
        isLoading={isChangingLanguage}
        title={isVisibleTitle ? i18n.resolvedLanguage.toUpperCase() : undefined}
        {...props}
      />
    </Tooltip>
  );
};

export default LanguageSelector;
