import { Formik } from 'formik';
import { Alert, Button, Col, Field, Row, Text } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  initialValues = {},
  locationOptions,
  onClickTerms,
  isSignUpUseWaivers,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        name: undefined,
        surname: undefined,
        email: undefined,
        mobile: undefined,
        locationId: initialValues.locationId,
        notes: undefined,
        ...(isSignUpUseWaivers ? { termsAndConditions: false } : {}),
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        surname: Yup.string().required(t('required')),
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
        mobile: Yup.string(),
        locationId: Yup.string().required(t('required')),
        notes: Yup.string(),
        ...(isSignUpUseWaivers
          ? {
              termsAndConditions: Yup.bool()
                .oneOf([true], t('termsMustBeAccepted'))
                .required(t('required')),
            }
          : {}),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Alert subtitle={t('linkToInvoiceAndBooking')} variant='info' />
            </Col>

            <Col lg={4} md={6}>
              <Field
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('firstName')} name='name' />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('surname')} name='surname' />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('emailAddress')} name='email' />
            </Col>

            <Col lg={4} md={6}>
              <Field label={t('mobileNumber')} name='mobile' />
            </Col>

            <Col>
              <Field as='textarea' label={t('notes')} name='notes' />
            </Col>

            {isSignUpUseWaivers && (
              <Col>
                <Field
                  isCheckbox
                  name='termsAndConditions'
                  options={[{ label: t('iAcceptTheTermsOfThe'), value: true }]}
                  renderRight={() => (
                    <Text
                      isBold
                      color='primary'
                      ml={1}
                      mt='1px'
                      textDecoration='underline'
                      onClick={onClickTerms}
                    >
                      {t('waiver')}
                    </Text>
                  )}
                />
              </Col>
            )}

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
