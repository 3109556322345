import { Table, Text, Tooltip } from 'octiv-components';
import { getPaymentGatewayName } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
      },
      {
        Header: t('category'),
        accessor: 'category',
        Cell: ({ value }) => <Text>{value?.name}</Text>,
      },
      {
        Header: t('#members'),
        accessor: 'activeMembersCount',
      },
      {
        Header: t('billingPaymentGateway'),
        accessor: 'billingPaymentGatewayId',
        Cell: ({ value }) => (
          <Text>{getPaymentGatewayName({ id: value })}</Text>
        ),
      },
      {
        Header: t('actions'),
        accessor: 'id',
        Cell: ({
          row: {
            original: { id, financePaymentToken },
          },
        }) => (
          <Tooltip
            options={[
              { label: t('edit'), value: 'edit' },
              ...(financePaymentToken
                ? [
                    {
                      label: 'Delete billing card details',
                      value: 'deletePaymentToken',
                    },
                  ]
                : []),
              { label: t('delete'), value: 'delete' },
            ]}
            onClick={({ value }) => {
              if (value === 'deletePaymentToken') {
                onClickAction({ action: value, id: financePaymentToken?.id });
              } else {
                onClickAction({ action: value, id });
              }
            }}
          />
        ),
      },
    ],
    [onClickAction, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
