import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { filterLocationsWithAccessControl } from 'octiv-utilities/Functions';
import React, { useState } from 'react';

import ConnectedAccounts from './connectedAccounts/index';
import ManageDevices from './manageDevices/index';

export default () => {
  const [isActive, setIsActive] = useState(1);
  const [manageDeviceDetails, setManageDeviceDetails] = useState({});
  const {
    selectedLocation,

    tenant: { locations },
  } = useActiveUserTenant();

  const locationOptionsWithAccessControl = filterLocationsWithAccessControl(
    locations,
    true
  );

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
  });

  return (
    <>
      {isActive === 1 && (
        <ConnectedAccounts
          locationOptionsAll={locationOptionsWithAccessControl}
          query={query}
          setIsActive={() => setIsActive(2)}
          setManageDeviceDetails={setManageDeviceDetails}
          setQuery={setQuery}
        />
      )}
      {isActive === 2 && (
        <ManageDevices
          locationOptionsAll={locationOptionsWithAccessControl}
          manageDeviceDetails={manageDeviceDetails}
          query={query}
          onBack={() => setIsActive(1)}
        />
      )}
    </>
  );
};
