import _ from 'lodash';
import { Box } from 'octiv-components';
import React, { useCallback, useState } from 'react';

import TextInput from '../TextInput';

const FieldDebounced = ({
  onChange,
  renderLeft,
  renderRight,
  placeholder,
  ...props
}) => {
  const [value, setValue] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    _.debounce((newValue) => onChange(newValue), 750),
    []
  );

  const onChangeText = (e) => {
    setValue(e.target.value);
    debouncedOnChange(e.target.value);
  };

  return (
    <Box hasRadius isFlex bg='grey5' p={2} {...props}>
      {renderLeft && renderLeft()}

      <TextInput
        hasPadding={false}
        placeholder={placeholder}
        value={value}
        onChange={onChangeText}
      />

      {renderRight && renderRight()}
    </Box>
  );
};

export default FieldDebounced;
