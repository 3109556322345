import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        logo: initialValues.logo || undefined,
      }}
      validationSchema={Yup.object().shape({
        logo: Yup.string().required(t('required')),
      })}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field isDrop label={t('locationLogo')} name='logo' />
            </Col>
          </Row>
          <Col>
            <Button
              isDisabled={!values?.logo?.path}
              isLoading={isLoading}
              mt={2}
              text={t('submit')}
              type='submit'
            />
          </Col>
        </form>
      )}
    </Formik>
  );
};
