import {
  // AccessControlKiosk,
  // AccessControlSystem,
  AccessControl,
  AccountsCreditNotes,
  AccountsDebitBatches,
  AccountsDebitBatchRecon,
  AccountsGoCardlessMandates,
  AccountsInvoices,
  AccountsMandates,
  AccountsPayments,
  AccountsPayouts,
  AccountsSettlements,
  AccountsStatements,
  AccountsStripeMandates,
  AccountsStripePayouts,
  AccountsSubscriptions,
  Changelog,
  CheckIn,
  CommsCreate,
  CommsManage,
  CommsNoticeboard,
  Dashboard,
  EventModalPrint,
  LeadsImport,
  LeadsManage,
  MembersBirthdays,
  MembersContractsWaivers,
  MembersCreate,
  MembersFinances,
  MembersInjuries,
  MembersManage,
  MembersPackages,
  MembersScheduledActions,
  // ReportsAccessControl,
  ReportsAttendanceClassAttendance,
  ReportsAttendanceMemberAttendance,
  ReportsAttendanceMemberNonAttendance,
  ReportsAttendanceOverview,
  ReportsAttendanceTrainer,
  ReportsDashboard,
  ReportsExercisesPersonalBests,
  ReportsExports,
  ReportsFinanceDebtors,
  ReportsFinanceDiscounts,
  ReportsFinancePackageRevenue,
  ReportsFinancePaymentTypes,
  ReportsFinanceStore,
  SchedulingBulkActions,
  SchedulingCalendar,
  SchedulingCoronavirus,
  SchedulingRecurringBookings,
  SchedulingRecurringEvents,
  SchedulingRoster,
  SettingsAccounts,
  SettingsComms,
  SettingsContractsWaivers,
  SettingsCoronavirus,
  SettingsDropIns,
  SettingsExports,
  SettingsOctiv,
  SettingsPackages,
  SettingsPaymentGateways,
  SettingsPaymentGatewaysCallback,
  SettingsScheduling,
  SettingsSignUp,
  SettingsStore,
  SettingsStripeAccount,
  SettingsWidgets,
  SettingsWorkouts,
  Staff,
  StorePos,
  StoreProducts,
  StoreSales,
  Tasks,
  UserProfile,
  UserTenants,
  WorkoutsBenchmarksManage,
  WorkoutsBenchmarksValidate,
  WorkoutsImport,
  WorkoutsLeaderboard,
  WorkoutsManage,
  WorkoutsTrainer,
  WorkoutsWhiteboard,
} from 'octiv-containers';
import { SettingsFacility } from 'octiv-containers/settings';
import React from 'react';

import { getStacksPublic } from './Public';

export const getStacksStaff = ({
  t,
  onLoginPath,
  hasAccess,
  hasAccessContolFeature,
  isGoCardless,
  isPaystack,
  isSepa,
  isStripeConnect,
  regionId,
  typeId,
  isTrainer,
}) =>
  typeId === 8
    ? [
        {
          title: t('checkIn'),
          icon: 'qr_code',
          screens: [
            {
              name: 'CheckIn',
              path: '/check-in',
              element: <CheckIn />,
            },
          ],
        },
        {
          title: t('checkIn'),
          icon: 'qr_code',
          screens: [
            {
              name: 'CheckInHiddenNavigation',
              path: '/check-in-hidden-navigation',
              element: <CheckIn />,
            },
          ],
          options: {
            isVisibleInNavigation: false,
            isVisibleNavigation: false,
          },
        },
        {
          title: 'My Account',
          icon: 'account_circle',
          screens: [
            {
              name: 'UserProfile',
              path: '/user/profile',
              element: <UserProfile />,
              options: {
                title: 'Profile',
              },
            },
            {
              name: 'UserTenants',
              path: '/user/accounts',
              element: <UserTenants />,
              options: {
                title: 'Accounts',
              },
            },
          ],
        },

        ...getStacksPublic({ onRedirectPath: '/check-in', onLoginPath }),
      ]
    : [
        {
          title: t('dashboard'),
          icon: 'dashboard',
          screens: [
            {
              name: 'Dashboard',
              path: '/dashboard',
              element: <Dashboard />,
            },
          ],
        },

        ...(hasAccess.containerMembers
          ? [
              {
                title: t('members'),
                icon: 'assignment_ind',
                screens: [
                  {
                    name: 'MembersManage',
                    path: '/members/manage',
                    element: <MembersManage />,
                    options: {
                      title: t('manage'),
                    },
                  },
                  ...(hasAccess.screenMembersCreate
                    ? [
                        {
                          name: 'MembersCreate',
                          path: '/members/create',
                          element: <MembersCreate />,
                          options: {
                            title: t('create'),
                          },
                        },
                      ]
                    : []),
                  {
                    name: 'MembersFinances',
                    path: '/members/finances',
                    element: <MembersFinances />,
                    options: {
                      title: t('finances'),
                    },
                  },
                  {
                    name: 'MembersContractsWaivers',
                    path: '/members/contracts-waivers',
                    element: <MembersContractsWaivers />,
                    options: {
                      title: t('contractsWaivers'),
                    },
                  },
                  {
                    name: 'MembersScheduledActions',
                    path: '/members/scheduled-actions',
                    element: <MembersScheduledActions />,
                    options: {
                      title: t('scheduledActions'),
                    },
                  },
                  {
                    name: 'MembersPackages',
                    path: '/members/packages',
                    element: <MembersPackages />,
                    options: {
                      title: t('packages'),
                    },
                  },
                  ...(hasAccess.screenMembersBirthdays
                    ? [
                        {
                          name: 'MembersBirthdays',
                          path: '/members/birthdays',
                          element: <MembersBirthdays />,
                          options: {
                            title: t('birthdays'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenMembersInjuries
                    ? [
                        {
                          name: 'MembersInjuries',
                          path: '/members/injuries',
                          element: <MembersInjuries />,
                          options: {
                            title: t('injuries'),
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(hasAccess.containerStaff
          ? [
              {
                title: t('staff'),
                icon: 'group',
                screens: [
                  {
                    name: 'StaffManage',
                    path: '/staff',
                    element: <Staff />,
                  },
                ],
              },
            ]
          : []),

        ...(hasAccess.containerWorkouts
          ? [
              {
                title: t('workouts'),
                icon: 'fitness_center',
                screens: [
                  {
                    name: 'WorkoutsManage',
                    path: '/workouts/manage',
                    element: <WorkoutsManage />,
                    options: {
                      title: t('manage'),
                    },
                  },
                  {
                    name: 'WorkoutsImport',
                    path: '/workouts/import',
                    element: <WorkoutsImport />,
                    options: {
                      title: t('import'),
                    },
                  },
                  {
                    name: 'WorkoutsWhiteboard',
                    path: '/workouts/whiteboard',
                    element: <WorkoutsWhiteboard />,
                    options: {
                      title: t('whiteboard'),
                    },
                  },
                  {
                    name: 'WorkoutsLeaderboard',
                    path: '/workouts/leaderboard',
                    element: <WorkoutsLeaderboard />,
                    options: {
                      title: t('leaderboard'),
                    },
                  },
                  {
                    isDivider: true,
                    options: {
                      title: t('benchmarks'),
                    },
                  },
                  {
                    name: 'WorkoutsBenchmarksManage',
                    path: '/workouts/benchmarks/manage',
                    element: <WorkoutsBenchmarksManage />,
                    options: {
                      title: t('manage'),
                    },
                  },
                  {
                    name: 'WorkoutsBenchmarksValidate',
                    path: '/workouts/benchmarks/validate',
                    element: <WorkoutsBenchmarksValidate />,
                    options: {
                      title: t('validate'),
                    },
                  },
                  {
                    name: 'WorkoutsTrainer',
                    path: '/workouts/trainer/:id',
                    element: <WorkoutsTrainer />,
                    options: {
                      isVisibleInNavigation: false,
                      isVisibleNavigation: false,
                    },
                  },
                ],
              },
            ]
          : []),

        ...(hasAccess.containerScheduling
          ? [
              {
                title: t('scheduling'),
                icon: 'today',
                screens: [
                  {
                    name: 'SchedulingCalendar',
                    path: '/scheduling/calendar',
                    element: <SchedulingCalendar />,
                    options: {
                      title: t('calendar'),
                    },
                  },
                  ...(hasAccess.featureSchedulingBulkActions || isTrainer
                    ? [
                        {
                          name: 'SchedulingBulkActions',
                          path: '/scheduling/bulk-actions',
                          element: <SchedulingBulkActions />,
                          options: {
                            title: t('bulkActions'),
                          },
                        },
                      ]
                    : []),
                  {
                    name: 'SchedulingRecurringEvents',
                    path: '/scheduling/recurring-events',
                    element: <SchedulingRecurringEvents />,
                    options: {
                      title: t('recurringEvents'),
                    },
                  },
                  {
                    name: 'SchedulingRecurringBookings',
                    path: '/scheduling/recurring-bookings',
                    element: <SchedulingRecurringBookings />,
                    options: {
                      title: t('recurringBookings'),
                    },
                  },
                  {
                    name: 'SchedulingRoster',
                    path: '/scheduling/roster',
                    element: <SchedulingRoster />,
                    options: {
                      title: t('roster'),
                    },
                  },
                  ...(hasAccess.containerCoronavirus
                    ? [
                        {
                          name: 'SchedulingCoronavirus',
                          path: '/scheduling/coronavirus',
                          element: <SchedulingCoronavirus />,
                          options: {
                            title: t('coronavirus'),
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(hasAccess.containerTasks
          ? [
              {
                title: t('tasks'),
                icon: 'assignment',
                screens: [
                  {
                    name: 'Tasks',
                    path: '/tasks',
                    element: <Tasks />,
                  },
                ],
              },
            ]
          : []),

        ...(hasAccess.containerAccessControl && hasAccessContolFeature
          ? [
              {
                title: t('accessControl'),
                icon: 'key',
                screens: [
                  ...(hasAccess.screenAccessControlSystems
                    ? [
                        {
                          name: 'AccessControl',
                          path: '/access-control',
                          element: <AccessControl />,
                        },
                        // {
                        //   name: 'AccessControl',
                        //   path: '/access-control/systems/:id',
                        //   element: <AccessControlSystem />,
                        //   options: {
                        //     isVisibleInNavigation: false,
                        //     isVisibleNavigation: false,
                        //     title: t('systems'),
                        //   },
                        // },
                      ]
                    : []),
                  // ...(hasAccess.screenAccessControlKiosk
                  //   ? [
                  //       {
                  //         name: 'AccessControlKiosk',
                  //         path: '/access-control/kiosk',
                  //         element: <AccessControlKiosk />,
                  //         options: {
                  //           isVisibleInNavigation: true,
                  //           isVisibleNavigation: false,
                  //           title: t('kiosk'),
                  //         },
                  //       },
                  //       {
                  //         name: 'AccessControlKiosk',
                  //         path: '/access-control/kiosk/:locationId',
                  //         element: <AccessControlKiosk />,
                  //         options: {
                  //           isVisibleInNavigation: false,
                  //           isVisibleNavigation: false,
                  //           title: t('kiosk'),
                  //         },
                  //       },
                  //     ]
                  //   : []),
                ],
              },
            ]
          : []),

        ...(hasAccess.containerAccounts
          ? [
              {
                title: t('accounts'),
                icon: 'money',
                screens: [
                  ...(hasAccess.screenAccountsInvoices
                    ? [
                        {
                          name: 'AccountsInvoices',
                          path: '/accounts/invoices',
                          element: <AccountsInvoices />,
                          options: {
                            title: t('invoices'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenAccountsCreditNotes
                    ? [
                        {
                          name: 'AccountsCreditNotes',
                          path: '/accounts/credit-notes',
                          element: <AccountsCreditNotes />,
                          options: {
                            title: t('creditNotes'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenAccountsPayments
                    ? [
                        {
                          name: 'AccountsPayments',
                          path: '/accounts/payments',
                          element: <AccountsPayments />,
                          options: {
                            title: t('payments'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenAccountsDebitBatches
                    ? [
                        {
                          name: 'AccountsDebitBatches',
                          path: '/accounts/debit-batches',
                          element: <AccountsDebitBatches />,
                          options: {
                            title: t('debitBatches'),
                          },
                        },
                        ...(isSepa
                          ? [
                              {
                                name: 'AccountsDebitBatchRecon',
                                path: '/accounts/debit-batch-recon',
                                element: <AccountsDebitBatchRecon />,
                                options: {
                                  title: t('debitBatchRecon'),
                                },
                              },
                            ]
                          : []),
                      ]
                    : []),
                  {
                    name: 'AccountsStatements',
                    path: '/accounts/statements',
                    element: <AccountsStatements />,
                    options: {
                      title: t('statements'),
                    },
                  },
                  ...(isGoCardless
                    ? [
                        {
                          name: 'AccountsPayouts',
                          path: '/accounts/payouts',
                          element: <AccountsPayouts />,
                          options: {
                            title: t('payouts'),
                          },
                        },
                        {
                          name: 'AccountsGoCardlessMandates',
                          path: '/accounts/gocardless-mandates',
                          element: <AccountsGoCardlessMandates />,
                          options: {
                            title: t('goCardlessMandates'),
                          },
                        },
                      ]
                    : []),
                  ...(isPaystack
                    ? [
                        {
                          name: 'AccountsSettlements',
                          path: '/accounts/settlements',
                          element: <AccountsSettlements />,
                          options: {
                            title: t('settlements'),
                          },
                        },
                      ]
                    : []),
                  ...(isSepa
                    ? [
                        {
                          name: 'AccountsMandates',
                          path: '/accounts/mandates',
                          element: <AccountsMandates />,
                          options: {
                            title: t('mandates'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenAccountsSubscriptions &&
                  (isPaystack || isStripeConnect)
                    ? [
                        {
                          name: 'AccountsPayments',
                          path: '/accounts/subscriptions',
                          element: <AccountsSubscriptions />,
                          options: {
                            title: t('subscriptions'),
                          },
                        },
                      ]
                    : []),
                  ...(isStripeConnect
                    ? [
                        {
                          name: 'AccountsStripeMandates',
                          path: '/accounts/stripe-mandates',
                          element: <AccountsStripeMandates />,
                          options: {
                            title: t('mandates'),
                          },
                        },
                        {
                          name: 'AccountsStripePayouts',
                          path: '/accounts/stripe-payouts',
                          element: <AccountsStripePayouts />,
                          options: {
                            title: t('payouts'),
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(hasAccess.containerComms
          ? [
              {
                title: t('comms'),
                icon: 'forum',
                screens: [
                  {
                    name: 'CommsManage',
                    path: '/comms/manage',
                    element: <CommsManage />,
                    options: {
                      title: t('manage'),
                    },
                  },
                  {
                    name: 'CommsCreate',
                    path: '/comms/create',
                    element: <CommsCreate />,
                    options: {
                      title: t('create'),
                    },
                  },
                  {
                    name: 'CommsUpdate',
                    path: '/comms/update/:id',
                    element: <CommsCreate />,
                    options: {
                      isVisibleInNavigation: false,
                    },
                  },
                  ...(hasAccess.screenCommsNoticeboard
                    ? [
                        {
                          name: 'CommsNoticeboard',
                          path: '/comms/noticeboard',
                          element: <CommsNoticeboard />,
                          options: {
                            title: t('noticeboard'),
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        ...(hasAccess.containerLeads
          ? [
              {
                title: t('leads'),
                icon: 'how_to_reg',
                screens: [
                  {
                    name: 'LeadsManage',
                    path: '/leads/manage',
                    element: <LeadsManage />,
                    options: {
                      title: t('manage'),
                    },
                  },
                  {
                    name: 'LeadsImport',
                    path: '/leads/import',
                    element: <LeadsImport />,
                    options: {
                      title: t('import'),
                    },
                  },
                ],
              },
            ]
          : []),

        ...(hasAccess.containerStore
          ? [
              {
                title: t('store'),
                icon: 'shopping_cart',
                screens: [
                  {
                    name: 'StorePos',
                    path: '/store/pos',
                    element: <StorePos />,
                    options: {
                      title: t('storePos'),
                    },
                  },
                  {
                    name: 'StoreProducts',
                    path: '/store/products',
                    element: <StoreProducts />,
                    options: {
                      title: t('products'),
                    },
                  },
                  {
                    name: 'StoreSales',
                    path: '/store/sales',
                    element: <StoreSales />,
                    options: {
                      title: t('sales'),
                    },
                  },
                ],
              },
            ]
          : []),

        ...(hasAccess.containerReports
          ? [
              {
                title: t('reports'),
                icon: 'pie_chart',
                screens: [
                  ...(hasAccess.screenReportsDashboard
                    ? [
                        {
                          name: 'ReportsDashboard',
                          path: '/reports/dashboard',
                          element: <ReportsDashboard />,
                          options: {
                            title: t('dashboard'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenReportsExports
                    ? [
                        {
                          name: 'ReportsExports',
                          path: '/reports/exports',
                          element: <ReportsExports />,
                          options: {
                            title: t('exports'),
                          },
                        },
                      ]
                    : []),
                  {
                    isDivider: true,
                    options: {
                      title: t('attendance'),
                    },
                  },
                  {
                    name: 'ReportsAttendanceOverview',
                    path: '/reports/attendance/overview',
                    element: <ReportsAttendanceOverview />,
                    options: {
                      title: t('overview'),
                    },
                  },
                  {
                    name: 'ReportsAttendanceClassAttendance',
                    path: '/reports/attendance/class-attendance',
                    element: <ReportsAttendanceClassAttendance />,
                    options: {
                      title: t('class'),
                    },
                  },
                  // TODO: Add this once the API request has been optimised
                  // {
                  //   name: 'ReportsAttendanceFirstBookings',
                  //   path: '/reports/attendance/first-bookings',
                  //   element: <ReportsAttendanceFirstBookings />,
                  //   options: {
                  //     title: t('firstBookings'),
                  //   },
                  // },
                  {
                    name: 'ReportsAttendanceMemberAttendance',
                    path: '/reports/attendance/member-attendance',
                    element: <ReportsAttendanceMemberAttendance />,
                    options: {
                      title: t('member'),
                    },
                  },
                  {
                    name: 'ReportsAttendanceMemberNonAttendance',
                    path: '/reports/attendance/member-non-attendance',
                    element: <ReportsAttendanceMemberNonAttendance />,
                    options: {
                      title: t('memberNonAttendance'),
                    },
                  },
                  ...(hasAccess.screenReportsAttendanceTrainer
                    ? [
                        {
                          name: 'ReportsAttendanceTrainer',
                          path: '/reports/attendance/trainer-report',
                          element: <ReportsAttendanceTrainer />,
                          options: {
                            title: t('trainer'),
                          },
                        },
                      ]
                    : []),
                  // {
                  //   name: 'ReportsAccessControl',
                  //   path: '/reports/attendance/access-control',
                  //   element: <ReportsAccessControl />,
                  //   options: {
                  //     title: t('accessControl'),
                  //   },
                  // },
                  ...(hasAccess.screenReportsFinanceDebtors ||
                  hasAccess.screenReportsFinancePackagesRevenue ||
                  hasAccess.screenReportsFinanceDiscounts ||
                  hasAccess.screenReportsFinancePaymentTypes ||
                  hasAccess.screenReportsFinanceStore
                    ? [
                        {
                          isDivider: true,
                          options: {
                            title: t('finance'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenReportsFinanceDebtors
                    ? [
                        {
                          name: 'ReportsFinanceDebtors',
                          path: '/reports/finance/debtors',
                          element: <ReportsFinanceDebtors />,
                          options: {
                            title: t('debtors'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenReportsFinanceDiscounts
                    ? [
                        {
                          name: 'ReportsFinanceDiscounts',
                          path: '/reports/finance/discounts',
                          element: <ReportsFinanceDiscounts />,
                          options: {
                            title: t('discounts'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenReportsFinancePackagesRevenue
                    ? [
                        {
                          name: 'ReportsFinancePackageRevenue',
                          path: '/reports/finance/package-revenue',
                          element: <ReportsFinancePackageRevenue />,
                          options: {
                            title: t('packageRevenue'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenReportsFinancePaymentTypes
                    ? [
                        {
                          name: 'ReportsFinancePaymentTypes',
                          path: '/reports/finance/payment-types',
                          element: <ReportsFinancePaymentTypes />,
                          options: {
                            title: t('paymentTypes'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenReportsFinanceStore
                    ? [
                        {
                          name: 'ReportsFinanceStore',
                          path: '/reports/finance/store',
                          element: <ReportsFinanceStore />,
                          options: {
                            title: t('storeSales'),
                          },
                        },
                      ]
                    : []),
                  {
                    isDivider: true,
                    options: {
                      title: t('exercises'),
                    },
                  },
                  // ...(hasAccess.screenReportsPerformanceReport
                  //   ? [
                  //       {
                  //         name: 'ReportsExercisesPerformance',
                  //         path: '/reports/exercises/performace',
                  //         element: <ReportsExercisesPerformance />,
                  //         options: {
                  //           title: t('performance'),
                  //         },
                  //       },
                  //     ]
                  //   : []),
                  {
                    name: 'ReportsExercisesPersonalBests',
                    path: '/reports/exercises/personal-bests',
                    element: <ReportsExercisesPersonalBests />,
                    options: {
                      title: t('personalBests'),
                    },
                  },
                ],
              },
            ]
          : []),

        {
          title: t('checkIn'),
          icon: 'qr_code',
          screens: [
            {
              name: 'CheckIn',
              path: '/check-in',
              element: <CheckIn />,
            },
          ],
        },
        {
          title: t('checkIn'),
          icon: 'qr_code',
          screens: [
            {
              name: 'CheckInHiddenNavigation',
              path: '/check-in-hidden-navigation',
              element: <CheckIn />,
            },
          ],
          options: {
            isVisibleInNavigation: false,
            isVisibleNavigation: false,
          },
        },

        ...(hasAccess.containerSettings
          ? [
              {
                title: t('settings'),
                icon: 'settings',
                screens: [
                  ...(hasAccess.screenSettingsAccounts
                    ? [
                        {
                          name: 'SettingsAccounts',
                          path: '/settings/accounts',
                          element: <SettingsAccounts />,
                          options: {
                            title: t('accounts'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.containerComms && hasAccess.screenSettingsComms
                    ? [
                        {
                          name: 'SettingsComms',
                          path: '/settings/comms',
                          element: <SettingsComms />,
                          options: {
                            title: t('comms'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenSettingsContractsWaivers
                    ? [
                        {
                          name: 'SettingsContractsWaivers',
                          path: '/settings/contracts-waivers',
                          element: <SettingsContractsWaivers />,
                          options: {
                            title: t('contractsAndWaivers'),
                          },
                        },
                      ]
                    : []),
                  {
                    name: 'SettingsCoronavirus',
                    path: '/settings/coronavirus',
                    element: <SettingsCoronavirus />,
                    options: {
                      title: t('coronavirus'),
                    },
                  },
                  ...(hasAccess.containerScheduling &&
                  hasAccess.screenSettingsDropIns
                    ? [
                        {
                          name: 'SettingsDropIns',
                          path: '/settings/drop-ins',
                          element: <SettingsDropIns />,
                          options: {
                            title: t('dropIns'),
                          },
                        },
                      ]
                    : []),
                  ...(regionId === 2
                    ? [
                        {
                          name: 'SettingsExports',
                          path: '/settings/exports',
                          element: <SettingsExports />,
                          options: {
                            title: t('exports'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenSettingsFacility
                    ? [
                        {
                          name: 'SettingsFacility',
                          path: '/settings/facility',
                          element: <SettingsFacility />,
                          options: {
                            title: t('facility'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenSettingsOctiv
                    ? [
                        {
                          name: 'SettingsOctiv',
                          path: '/settings/octiv',
                          element: <SettingsOctiv />,
                          options: {
                            title: 'Octiv',
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenSettingsPackages
                    ? [
                        {
                          name: 'SettingsPackages',
                          path: '/settings/packages',
                          element: <SettingsPackages />,
                          options: {
                            title: t('packages'),
                          },
                        },
                      ]
                    : []),
                  {
                    name: 'SettingsPaymentGatewaysCallback',
                    path: '/settings/payment-gateways/callback',
                    element: <SettingsPaymentGatewaysCallback />,
                    options: {
                      title: t('paymentGateways'),
                      isVisibleNavigation: false,
                      isVisibleInNavigation: false,
                    },
                  },
                  {
                    name: 'SettingsPaymentGateways',
                    path: '/settings/payment-gateways',
                    element: <SettingsPaymentGateways />,
                    options: {
                      title: t('paymentGateways'),
                    },
                  },
                  ...(hasAccess.containerScheduling &&
                  hasAccess.screenSettingsBookings
                    ? [
                        {
                          name: 'SettingsScheduling',
                          path: '/settings/scheduling',
                          element: <SettingsScheduling />,
                          options: {
                            title: t('scheduling'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenSettingsSignUp
                    ? [
                        {
                          name: 'SettingsSignUp',
                          path: '/settings/sign-up',
                          element: <SettingsSignUp />,
                          options: {
                            title: t('signUp'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.containerStore
                    ? [
                        {
                          name: 'SettingsStore',
                          path: '/settings/store',
                          element: <SettingsStore />,
                          options: {
                            title: t('store'),
                          },
                        },
                      ]
                    : []),
                  ...(isStripeConnect
                    ? [
                        {
                          name: 'SettingsStripeAccount',
                          path: '/settings/stripe-account',
                          element: <SettingsStripeAccount />,
                          options: {
                            title: `Stripe ${t('account')}`,
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.screenSettingsWidgets
                    ? [
                        {
                          name: 'SettingsWidgets',
                          path: '/settings/widgets',
                          element: <SettingsWidgets />,
                          options: {
                            title: t('widgets'),
                          },
                        },
                      ]
                    : []),
                  ...(hasAccess.containerWorkouts &&
                  hasAccess.screenSettingsWorkouts
                    ? [
                        {
                          name: 'SettingsWorkouts',
                          path: '/settings/workouts',
                          element: <SettingsWorkouts />,
                          options: {
                            title: t('workouts'),
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),

        {
          options: {
            isVisibleInNavigation: false,
          },
          screens: [
            {
              name: 'UserProfile',
              path: '/user/profile',
              element: <UserProfile />,
            },
            {
              name: 'UserTenants',
              path: '/user/accounts',
              element: <UserTenants />,
            },
            {
              name: 'Changelog',
              path: '/changelog',
              element: <Changelog />,
            },
          ],
        },

        {
          options: {
            isVisibleInNavigation: false,
            isVisibleNavigation: false,
          },
          screens: [
            {
              name: 'EventModalPrint',
              path: '/event/:id/print',
              element: <EventModalPrint />,
            },
          ],
        },

        ...getStacksPublic({ onRedirectPath: '/dashboard', onLoginPath }),
      ];
