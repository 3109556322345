import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingProgrammes,
  locationOptions,
  programmes,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      validationSchema={Yup.object().shape({
        locationId: Yup.string(),
        statusId: Yup.string().required(t('required')),
        programmeId: Yup.string(),
        debitStatusId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('status')}
                name='statusId'
                options={[
                  { label: t('all'), value: 'all' },
                  { label: t('pending'), value: 1 },
                  { label: t('active'), value: 2 },
                  { label: t('suspended'), value: 3 },
                  { label: t('onHold'), value: 6 },
                  { label: t('deactivated'), value: 4 },
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingProgrammes}
                label={t('programme')}
                name='programmeId'
                options={[
                  { label: t('all'), value: undefined },
                  ...(programmes?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })) || []),
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('paymentType')}
                name='debitStatusId'
                options={[
                  { label: t('all'), value: undefined },
                  { label: t('cashEftCard'), value: 1 },
                  { label: t('debitOrder'), value: 2 },
                  { label: t('noPayment'), value: 4 },
                  { label: t('upfrontPayment'), value: 5 },
                  { label: t('onlinePayment'), value: 6 },
                  { label: t('cardSubscription'), value: 7 },
                ]}
              />
            </Col>

            <Col md={3}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
