import { Table, Text } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getCardStatus,
  getCardStatusColor,
  getDateReadableDayMonthYearShort,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();
  const { toCurrency } = useActiveUserTenant();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        accessor: 'user',
        sortBy: 'user_name',
        Cell: ({
          row: {
            original: { user, leadMember },
          },
        }) => {
          return (
            <Text
              as='span'
              onClick={
                canSetMemberModal({ userTenant: user?.userTenant, hasAccess })
                  ? () => setMemberModal({ userTenantId: user?.userTenant?.id })
                  : null
              }
            >
              {getFullName(leadMember || user)}
            </Text>
          );
        },
      },
      {
        Header: t('package'),
        accessor: 'package.name',
        sortBy: 'package_name',
      },
      {
        Header: t('invoiceCode'),
        accessor: 'invoice',
        Cell: ({ value, row: { original } }) => {
          const { expMonth, expYear } =
            original?.paymentMethod?.paymentMethodInfo || {};

          const color = getCardStatusColor(expMonth, expYear);
          return (
            <Text
              as='span'
              color={color}
              onClick={() =>
                window.open(`/accounts/invoices?invoiceId=${value?.id}`)
              }
            >
              {t(value.code)}
            </Text>
          );
        },
      },
      {
        Header: t('status'),
        accessor: 'invoice.status',
        Cell: ({ value }) => (
          <Text
            as='span'
            color={
              value === 'pending' || value === 'submitted'
                ? 'warning'
                : value === 'paid'
                ? 'success'
                : value === 'unpaid'
                ? 'danger'
                : undefined
            }
          >
            {t(value)}
          </Text>
        ),
      },
      {
        Header: t('amount'),
        accessor: 'invoice.amount',
        Cell: ({ value }) => {
          return <Text as='span'>{toCurrency({ value })}</Text>;
        },
      },
      {
        Header: t('billingDate'),
        accessor: 'invoice.date',
        Cell: ({ value, row: { original } }) => {
          const { expMonth, expYear } =
            original?.paymentMethod?.paymentMethodInfo || {};

          return (
            <Text as='span' color={getCardStatusColor(expMonth, expYear)}>
              {getDateReadableDayMonthYearShort({ date: value })}
            </Text>
          );
        },
      },
      {
        Header: t('cardStatus'),
        accessor: 'paymentMethod.paymentMethodInfo',
        Cell: ({ row: { original } }) => {
          const { expMonth, expYear } =
            original?.paymentMethod?.paymentMethodInfo || {};

          if (
            !original?.paymentMethod?.paymentMethodInfo &&
            (!expMonth || !expYear)
          )
            return null;

          return (
            <Text as='span' color={getCardStatusColor(expMonth, expYear)}>
              {original.paymentMethod
                ? t(getCardStatus(expMonth, expYear))
                : t('pending')}
            </Text>
          );
        },
      },
      {
        Header: t('actions'),
        Cell: ({
          row: {
            original: {
              paymentMethod,
              invoice,
              user: { id: userId },
            },
          },
        }) => {
          return paymentMethod &&
            invoice?.amount > 0 &&
            invoice?.status === 'unpaid' ? (
            <Text
              as='span'
              color={'danger'}
              onClick={() =>
                onClickAction({ action: 'retryPayment', id: invoice.id })
              }
            >
              {t('retryPayment')}
            </Text>
          ) : !paymentMethod ? (
            <Text
              as='span'
              color={'success'}
              onClick={() =>
                onClickAction({ action: 'sendCardOnboardingLink', id: userId })
              }
            >
              {t('sendOnboardingLink')}
            </Text>
          ) : null;
        },
      },
    ],
    [t, hasAccess, setMemberModal, toCurrency, onClickAction]
  );

  const sortBy = useMemo(
    () => [
      {
        id: 'user',
        desc: false,
      },
    ],
    []
  );

  return (
    <Table {...props} columns={tableColumns} data={tableData} sortBy={sortBy} />
  );
};
