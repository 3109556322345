import { Images } from 'octiv-assets';
import {
  Changelog,
  DiscoveryVitality,
  SchedulingMember,
  SignContract,
  SignMandate,
  SignWaiver,
  UserCardsConnect,
  UserProfile,
  UserTenants,
  WorkoutsMember,
} from 'octiv-containers';
import React from 'react';

import { getStacksPublic } from './Public';

export const getStacksMember = ({
  t,
  onLoginPath,
  hasAccess,
  landingScreen,
}) => {
  let onRedirectPath = '/my-profile';

  if (hasAccess.containerWorkouts && landingScreen === 'workouts') {
    onRedirectPath = '/workouts';
  } else if (hasAccess.containerScheduling && landingScreen !== 'workouts') {
    onRedirectPath = '/schedule';
  } else if (hasAccess.containerWorkouts) {
    onRedirectPath = '/workouts';
  } else if (hasAccess.containerScheduling) {
    onRedirectPath = '/schedule';
  }

  return [
    {
      title: t('schedule'),
      icon: 'today',
      screens: [
        {
          name: 'SchedulingMember',
          path: '/schedule',
          element: <SchedulingMember />,
        },
      ],
    },

    {
      title: t('workouts'),
      icon: 'fitness_center',
      screens: [
        {
          name: 'WorkoutsMember',
          path: '/workouts',
          element: <WorkoutsMember />,
        },
      ],
    },

    {
      title: 'Discovery Vitality',
      image: Images.vitalityLogomark,
      screens: [
        {
          name: 'DiscoveryVitality',
          path: '/discovery-vitality',
          element: <DiscoveryVitality useContainer />,
        },
      ],
    },

    {
      options: {
        isVisibleInNavigation: false,
        isVisibleNavigation: true,
        isSecure: true,
      },
      title: 'UserCards',
      icon: 'Card',
      screens: [
        {
          name: 'UserEditCards',
          path: '/user/cards/connect',
          element: <UserCardsConnect />,
        },
      ],
    },

    {
      options: {
        isVisibleInNavigation: false,
      },
      screens: [
        {
          name: 'UserProfile',
          path: '/user/profile',
          element: <UserProfile />,
        },
        {
          name: 'UserTenants',
          path: '/user/accounts',
          element: <UserTenants />,
        },
        {
          name: 'Changelog',
          path: '/changelog',
          element: <Changelog />,
        },
        {
          name: 'SignContract',
          path: '/sign/contract/:id',
          element: <SignContract />,
        },
        {
          name: 'SignWaiver',
          path: '/sign/waiver/:id',
          element: <SignWaiver />,
        },
        {
          name: 'SignMandate',
          path: '/sign/mandate',
          element: <SignMandate />,
        },
      ],
    },

    {
      options: {
        isVisibleInNavigation: false,
        isVisibleNavigation: false,
      },
      screens: [
        {
          name: 'Changelog',
          path: '/changelog-hidden-navigation',
          element: <Changelog />,
        },
      ],
    },

    ...getStacksPublic({ onRedirectPath, onLoginPath }),
  ];
};
