import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Field, Row, Text } from 'octiv-components';
import {
  genderOptions,
  passwordMinLength,
  paymentTypes,
} from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  banks,
  chosenPackage,
  isFetchingBanks,
  isFetchingProgrammes,
  isGoCardless,
  isSepa,
  isStripeConnect,
  isLoading,
  isUser,
  locationOptions,
  onClickTerms,
  programmes,
  signUpPaymentOptions,
  signUpDebitDayOptions,
  signUpRequiredFields,
  isSignUpUseContracts,
  isSignUpUseWaivers,
  ...props
}) => {
  const { t } = useTranslation();
  const signupSettings = {
    isMandatoryAddress: signUpRequiredFields?.includes?.('address'),
    isMandatoryPassportOrId: signUpRequiredFields?.includes?.('id_number'),
  };

  const isLimitedPackage = !!(chosenPackage?.type?.id === 3);

  return (
    <Formik
      {...props}
      initialValues={{
        programmeId: undefined,
        ...(!isUser
          ? {
              name: undefined,
              surname: undefined,
              genderId: undefined,
              dateOfBirth: undefined,
              email: undefined,
              mobile: undefined,
              emergencyContactName: undefined,
              emergencyContactMobile: undefined,
              password: undefined,
              address: undefined,
              idNumber: undefined,
            }
          : {}),
        ...(!isLimitedPackage
          ? {
              paymentDetails: {
                debitStatusId: undefined,
                bankId: undefined,
                accountTypeId: undefined,
                accountNumber: undefined,
                accountHolderName: undefined,
                iban: undefined,
                bic: undefined,
                address: undefined,
                branchCode: undefined,
                debitDayId: undefined,
                autoInvoicingDay: undefined,
                autoInvoicingDueDay: undefined,
              },
            }
          : {}),
        ...(isSignUpUseContracts
          ? {
              termsAndConditionsForContract: false,
            }
          : {}),

        ...(isSignUpUseWaivers
          ? {
              termsAndConditionsForWaiver: false,
            }
          : {}),
      }}
      validationSchema={Yup.object().shape({
        programmeId: Yup.string().required(t('required')),
        ...(!isUser
          ? {
              name: Yup.string().required(t('required')),
              surname: Yup.string().required(t('required')),
              email: Yup.string()
                .email(t('invalidEmail'))
                .required(t('required')),
              mobile: Yup.string().required(t('required')),
              emergencyContactName: Yup.string(),
              emergencyContactMobile: Yup.string(),
              genderId: Yup.string(),
              dateOfBirth: Yup.date()
                .min(moment('1900-01-01').toDate(), t('invalidDate'))
                .max(moment().toDate(), t('invalidDate')),
              password: Yup.string()
                .required(t('required'))
                .min(passwordMinLength, t('passwordsMustBeAtLeast8Characters')),
              address: Yup.string().when('isMandatoryAddress', {
                is: () => signupSettings.isMandatoryAddress,
                then: Yup.string().required(t('required')),
              }),
              idNumber: Yup.string().when('isMandatoryPassportOrId', {
                is: () => signupSettings.isMandatoryPassportOrId,
                then: Yup.string().required(t('required')),
              }),
            }
          : {}),
        ...(!isLimitedPackage
          ? {
              paymentDetails: Yup.object().shape({
                debitStatusId: Yup.string().required(t('required')),
                ...(!isGoCardless &&
                  !isSepa &&
                  !isStripeConnect && {
                    bankId: Yup.string().when('debitStatusId', {
                      is: (value) => value === '2',
                      then: Yup.string().required(t('required')),
                    }),
                    accountTypeId: Yup.string().when('debitStatusId', {
                      is: (value) => value === '2',
                      then: Yup.string().required(t('required')),
                    }),
                    accountNumber: Yup.string().when('debitStatusId', {
                      is: (value) => value === '2',
                      then: Yup.string().required(t('required')),
                    }),
                  }),
                ...(!isGoCardless &&
                  !isStripeConnect && {
                    accountHolderName: Yup.string().when('debitStatusId', {
                      is: (value) => value === '2',
                      then: Yup.string().required(t('required')),
                    }),
                  }),
                ...(isSepa && {
                  iban: Yup.string().when('debitStatusId', {
                    is: (value) => value === '2',
                    then: Yup.string().required(t('required')),
                  }),
                  bic: Yup.string().when('debitStatusId', {
                    is: (value) => value === '2',
                    then: Yup.string().required(t('required')),
                  }),
                  address: Yup.string().when('debitStatusId', {
                    is: (value) => value === '2',
                    then: Yup.string(),
                  }),
                }),
                branchCode: Yup.string(),
                debitDayId: Yup.string().when('debitStatusId', {
                  is: (value) => value === '2',
                  then: Yup.string().required(t('required')),
                }),
              }),
            }
          : {}),
        ...(isSignUpUseContracts
          ? {
              termsAndConditionsForContract: Yup.bool()
                .oneOf([true], t('termsContractMustBeAccepted'))
                .required(t('required')),
            }
          : {}),

        ...(isSignUpUseWaivers
          ? {
              termsAndConditionsForWaiver: Yup.bool()
                .oneOf([true], t('termsWaiverMustBeAccepted'))
                .required(t('required')),
            }
          : {}),
      })}
    >
      {({
        handleSubmit,
        dirty,
        values: { paymentDetails: { debitStatusId } = {} },
      }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={isUser ? 4 : 3} md={6}>
              <Field
                isSelect
                isLoading={isFetchingProgrammes}
                label={t('programme')}
                name='programmeId'
                options={
                  programmes &&
                  programmes?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))
                }
              />
            </Col>

            {!isUser && (
              <>
                <Col lg={3} md={6}>
                  <Field label={t('firstName')} name='name' />
                </Col>

                <Col lg={3} md={6}>
                  <Field label={t('surname')} name='surname' />
                </Col>

                <Col lg={3} md={6}>
                  <Field
                    isSelect
                    label={t('gender')}
                    name='genderId'
                    options={genderOptions}
                  />
                </Col>

                <Col lg={3} md={6}>
                  <Field
                    isDate
                    label={t('dateOfBirth')}
                    maxDate={moment()}
                    minDate={moment('1900-01-01')}
                    name='dateOfBirth'
                  />
                </Col>

                <Col lg={3} md={6}>
                  <Field label={t('emailAddress')} name='email' />
                </Col>

                <Col lg={3} md={6}>
                  <Field label={t('mobileNumber')} name='mobile' />
                </Col>

                <Col lg={3} md={6}>
                  <Field
                    label={t('emergencyContactName')}
                    name='emergencyContactName'
                  />
                </Col>

                <Col lg={3} md={6}>
                  <Field
                    label={t('emergencyContactMobile')}
                    name='emergencyContactMobile'
                  />
                </Col>

                <Col lg={3} md={6}>
                  <Field
                    helper={t('passwordsMustBeAtLeast8Characters')}
                    label={t('password')}
                    name='password'
                    textHelperProps={{ mb: 4 }}
                    type='password'
                  />
                </Col>

                {signupSettings?.isMandatoryAddress ? (
                  <Col lg={3} md={6}>
                    <Field label={t('address')} name='address' />
                  </Col>
                ) : null}

                {signupSettings?.isMandatoryPassportOrId ? (
                  <Col lg={3} md={6}>
                    <Field
                      label={`${t('socialSecurityNumber')}`}
                      name='idNumber'
                    />
                  </Col>
                ) : null}
              </>
            )}

            {!isLimitedPackage && (
              <>
                <Col lg={3} md={6}>
                  <Field
                    isSelect
                    label={t('paymentMethod')}
                    name='paymentDetails.debitStatusId'
                    options={[
                      {
                        label: t('cashEftCard'),
                        value: paymentTypes.CASH.toString(),
                      },
                      {
                        label: t('debitOrder'),
                        value: paymentTypes.DEBIT_ORDER.toString(),
                      },
                      {
                        label: t('cardSubscription'),
                        value: paymentTypes.CARD.toString(),
                      },
                    ].filter((item) => {
                      return (
                        signUpPaymentOptions.includes(item.value) &&
                        chosenPackage?.supportedPaymentMethods?.includes(
                          parseInt(item.value, 10)
                        )
                      );
                    })}
                  />
                </Col>

                {debitStatusId === paymentTypes.DEBIT_ORDER.toString() && (
                  <>
                    <Col lg={3} md={6}>
                      <Field
                        isSelect
                        label={t('debitDate')}
                        name='paymentDetails.debitDayId'
                        options={[
                          { label: '1st', value: 1 },
                          { label: '5th', value: 6 },
                          { label: '15th', value: 2 },
                          { label: '25th', value: 3 },
                          { label: '27th', value: 5 },
                          { label: '28th', value: 7 },
                          { label: t('lastDayMonth'), value: 4 },
                        ].filter((item) =>
                          signUpDebitDayOptions.includes(item.value.toString())
                        )}
                      />
                    </Col>

                    {!isGoCardless && !isSepa && !isStripeConnect && (
                      <>
                        <Col lg={3} md={6}>
                          <Field
                            isSelect
                            isLoading={isFetchingBanks}
                            label={t('bank')}
                            name='paymentDetails.bankId'
                            options={banks.map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))}
                          />
                        </Col>

                        <Col lg={3} md={6}>
                          <Field
                            isSelect
                            label={t('accountType')}
                            name='paymentDetails.accountTypeId'
                            options={[
                              {
                                label: t('accountTypeChequeCurrent'),
                                value: 1,
                              },
                              {
                                label: t('accountTypeSavings'),
                                value: 2,
                              },
                              { label: t('accountTypeBond'), value: 4 },
                              {
                                label: t('accountTypeSubscription'),
                                value: 5,
                              },
                              {
                                label: t('accountTypeTransmission'),
                                value: 3,
                              },
                            ]}
                          />
                        </Col>

                        <Col lg={3} md={6}>
                          <Field
                            label={t('accountNumber')}
                            name='paymentDetails.accountNumber'
                          />
                        </Col>

                        <Col lg={3} md={6}>
                          <Field
                            label={t('accountName')}
                            name='paymentDetails.accountHolderName'
                          />
                        </Col>

                        <Col lg={3} md={6}>
                          <Field
                            label={t('accountBranchCode')}
                            name='paymentDetails.branchCode'
                          />
                        </Col>
                      </>
                    )}

                    {isSepa && (
                      <>
                        <Col lg={3} md={6}>
                          <Field
                            label={t('accountName')}
                            name='paymentDetails.accountHolderName'
                          />
                        </Col>

                        <Col lg={3} md={6}>
                          <Field
                            label={t('internationalBankAccountNumber')}
                            name='paymentDetails.iban'
                          />
                        </Col>

                        <Col lg={3} md={6}>
                          <Field
                            label={t('bankIdentifierCode')}
                            name='paymentDetails.bic'
                          />
                        </Col>

                        <Col>
                          <Field
                            as='textarea'
                            label={t('address')}
                            name='paymentDetails.address'
                          />
                        </Col>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {Boolean(isSignUpUseContracts) && (
              <Col>
                <Field
                  isCheckbox
                  helper={t('digitalSignature')}
                  name='termsAndConditionsForContract'
                  options={[
                    { label: t('iAcceptTheTermsOfTheMale'), value: true },
                  ]}
                  renderRight={() => (
                    <Text
                      isBold
                      color='primary'
                      ml={1}
                      mt='1px'
                      textDecoration='underline'
                      onClick={() => onClickTerms({ type: 'contract' })}
                    >
                      {t('contract')}
                    </Text>
                  )}
                />
              </Col>
            )}

            {Boolean(isSignUpUseWaivers) && (
              <Col>
                <Field
                  isCheckbox
                  helper={t('digitalSignature')}
                  name='termsAndConditionsForWaiver'
                  options={[
                    { label: t('iAcceptTheTermsOfTheFemale'), value: true },
                  ]}
                  renderRight={() => (
                    <Text
                      isBold
                      color='primary'
                      ml={1}
                      mt='1px'
                      textDecoration='underline'
                      onClick={() => onClickTerms({ type: 'waiver' })}
                    >
                      {t('waiver')}
                    </Text>
                  )}
                />
              </Col>
            )}

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('proceed')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
