import { Formik } from 'formik';
import moment from 'moment';
import { Alert, Button, Col, Field, Row, Step, Text } from 'octiv-components';
import ColorPicker from 'octiv-components/colourPicker';
import i18n from 'octiv-config/i18n/index';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { thresholds } from 'octiv-utilities/Constants';
import {
  generateTimeOptions,
  getDateReadableDayMonthYear,
  getDateTime,
  getDateYearMonthDay,
  getFullName,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  recurringInterval,
  fromDate: propsFromDate,
  generatedToDate,
  initialValues = {},
  isFetchingLocationTags,
  isFetchingPackages,
  isFetchingUsersInstructors,
  isLoading,
  locationOptions,
  locationTags,
  onSetLocation,
  packages,
  type,
  usersInstructors,
  ...props
}) => {
  const { t } = useTranslation();

  const { id, isInstructor } = useActiveUserTenant();

  const isCreate = !initialValues.name;
  const isDuplicate = !isCreate && type === 'create';

  // If class is being duplicated the startDate has to be >= today. StartDate cannot be in the past.
  const startDate = isDuplicate
    ? moment().format('YYYY-MM-DD')
    : initialValues.recurringStartDate;

  const hasAutoCancellation = !!(
    initialValues?.autoCancelThresholdMin > 0 ||
    initialValues?.minBookedMembersCount > 0
  );

  return (
    <Formik
      {...props}
      initialValues={{
        isSession: isCreate
          ? 0
          : typeof initialValues.isSession === 'number'
          ? initialValues.isSession
          : Number(isInstructor),
        name: initialValues.name || undefined,
        locationId: initialValues.locationId || undefined,
        instructorId:
          initialValues.instructorId || (isInstructor ? id : undefined),
        supportingInstructorId:
          initialValues.supportingInstructorId || undefined,
        isDisplayInstructorName:
          typeof initialValues.isDisplayInstructorName === 'number'
            ? initialValues.isDisplayInstructorName
            : 1,
        isFree:
          typeof initialValues.isFree === 'number' ? initialValues.isFree : 0,
        tagIds:
          initialValues.tags && initialValues.tags[0]
            ? initialValues.tags.map((item) => item.id)
            : undefined,
        description: initialValues.description || undefined,
        typeId: initialValues.type?.id || 1,
        onceOffDate: getDateYearMonthDay({
          date: initialValues?.recurringStartDate,
          canFallback: true,
        }),
        recurringDays: initialValues.recurringDays || [],
        recurringStartDate: getDateYearMonthDay({
          date: startDate,
          canFallback: true,
        }),
        fromDate: getDateYearMonthDay({ date: propsFromDate }) || undefined,
        recurringEndDate:
          getDateYearMonthDay({ date: initialValues.recurringEndDate }) ||
          undefined,
        startTime: initialValues.startTime
          ? isCreate
            ? initialValues.startTime
            : getDateTime({ date: initialValues.startTime })
          : undefined,
        endTime: initialValues.endTime
          ? getDateTime({ date: initialValues.endTime })
          : undefined,
        isVisibleInApp:
          typeof initialValues.isVisibleInApp === 'number'
            ? initialValues.isVisibleInApp
            : 1,
        isVirtual:
          typeof initialValues.isVirtual === 'number'
            ? initialValues.isVirtual
            : 0,
        limit: initialValues.limit || undefined,
        bookingThreshold: initialValues.bookingThreshold || 1,
        cancellationThreshold: initialValues.cancellationThreshold || 1,
        meetingUrl: initialValues.meetingUrl || undefined,
        packageIds: initialValues.packages
          ? initialValues.packages.map((item) => item.id)
          : [],
        hasAutoCancellation,
        autoCancelThresholdMin: initialValues?.autoCancelThresholdMin || 0,
        minBookedMembersCount: initialValues?.minBookedMembersCount || 0,
        colourCustomisation: initialValues?.settings?.colours?.border ? 1 : 0,
        color: initialValues?.settings?.colours?.border ?? null,
      }}
      validationSchema={Yup.object().shape({
        isSession: Yup.number().required(t('required')),
        name: Yup.string().required(t('required')),
        locationId: Yup.string().required(t('required')),
        instructorId: Yup.string().required(t('required')),
        supportingInstructorId: Yup.string().nullable(),
        isDisplayInstructorName: Yup.string().required(t('required')),
        isFree: Yup.string().required(t('required')),
        description: Yup.string(),
        typeId: Yup.string().required(t('required')),
        onceOffDate: Yup.string().when('typeId', {
          is: (value) => Number(value) === 1,
          then: Yup.string().required(t('required')),
        }),
        recurringDays: Yup.array().when('typeId', {
          is: (value) => Number(value) === 2,
          then: Yup.array().required(t('required')).min(1, t('required')),
        }),
        recurringStartDate: Yup.string(),
        recurringEndDate: Yup.string(),
        startTime: Yup.string().required(t('required')),
        endTime: Yup.string().required(t('required')),
        isVisibleInApp: Yup.string().required(t('required')),
        isVirtual: Yup.string().required(t('required')),
        limit: Yup.string().required(t('required')),
        bookingThreshold: Yup.string().required(t('required')),
        cancellationThreshold: Yup.string().required(t('required')),
        meetingUrl: Yup.string(),
        packageIds: Yup.array().required(t('required')).min(1, t('required')),
        hasAutoCancellation: Yup.boolean(),
        colourCustomisation: Yup.boolean(),
        color: Yup.string().nullable(),
        autoCancelThresholdMin: Yup.number().when('hasAutoCancellation', {
          is: (value) => value === true,
          then: Yup.number()
            .typeError(t('mustBeANumber'))
            .integer(t('mustBeAWholeNumber'))
            .required(t('required')),
          otherwise: Yup.number(),
        }),
        minBookedMembersCount: Yup.number().when('hasAutoCancellation', {
          is: (value) => value === true,
          then: Yup.number()
            .typeError(t('mustBeANumber'))
            .integer(t('mustBeAWholeNumber'))
            // .min(1, t('mustBeGreaterThanZero'))
            .required(t('required')),
          otherwise: Yup.number(),
        }),
      })}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        const { isSession, typeId, locationId, fromDate, startTime, endTime } =
          values;
        const typeString = isSession
          ? t('session').toLowerCase()
          : t('class').toLowerCase();
        const typeStringCaptial = isSession ? t('session') : t('class');

        const isOnceOff = typeId === 1;
        const isBiWeekly = typeId === 3 || recurringInterval === 2;

        const isUpdatingRecurringClass = !!fromDate && !isOnceOff;
        const implementedFromDate = getDateReadableDayMonthYear({
          date: fromDate,
        });
        const implementedFromText = isUpdatingRecurringClass
          ? t('implementedFromDate', { date: implementedFromDate })
          : undefined;

        return (
          <form onSubmit={handleSubmit}>
            {isUpdatingRecurringClass && (
              <Alert
                mb={4}
                subtitle={t('updatesWillBeAppliedTo', {
                  markedWith: implementedFromText,
                })}
                title={t('pleaseNote')}
                variant='info'
              />
            )}

            <Step
              hasBorder
              boxContainerProps={{ mb: 4 }}
              title={t('generalDetails')}
              value={1}
            >
              <Row>
                {isCreate && isInstructor ? (
                  <Col>
                    <Alert
                      subtitle={t('canOnlyCreateSessionsYourself')}
                      title={t('pleaseNote')}
                      variant='info'
                    />
                  </Col>
                ) : (
                  isCreate && (
                    <Col>
                      <Field
                        isHorizontal
                        isRadio
                        label={t('classType')}
                        name='isSession'
                        options={[
                          { label: t('class'), value: 0 },
                          { label: t('session'), value: 1 },
                        ]}
                      />
                    </Col>
                  )
                )}
                <Col md={3}>
                  <Field
                    label={`${typeStringCaptial} ${t('name')}`}
                    name='name'
                  />
                </Col>
                <Col md={3}>
                  <Field
                    isSelect
                    doFinally={(newLocationId) => {
                      setFieldValue('tagIds', undefined);
                      onSetLocation({ locationId: newLocationId });
                    }}
                    label={t('location')}
                    name='locationId'
                    options={locationOptions}
                  />
                </Col>

                <Col md={3}>
                  <Field
                    isSelect
                    helper={implementedFromText}
                    isDisabled={isCreate && isInstructor}
                    isLoading={isFetchingUsersInstructors}
                    label={t('trainer')}
                    name='instructorId'
                    options={usersInstructors?.map((item) => ({
                      label: getFullName(item),
                      value: item.id,
                    }))}
                  />
                </Col>

                <Col md={3}>
                  <Field
                    isSelect
                    helper={implementedFromText}
                    isLoading={isFetchingUsersInstructors}
                    label={t('supportingTrainer')}
                    name='supportingInstructorId'
                    options={[
                      {
                        label: t('noSupportingInstructor'),
                        value: null,
                      },
                      ...usersInstructors.map((item) => ({
                        label: getFullName(item),
                        value: item.id,
                      })),
                    ]}
                  />
                </Col>

                <Col md={3}>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('shouldTrainersNameBeDisplayed')}
                    name='isDisplayInstructorName'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                  />
                </Col>

                <Col md={3}>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('isThisClassFree', {
                      classType: typeString,
                    })}
                    name='isFree'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                  />
                </Col>

                <Col md={3}>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('colourCustomisation')}
                    name='colourCustomisation'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                    onChange={(val) =>
                      setFieldValue('colourCustomisation', val)
                    }
                  />
                </Col>

                {values.colourCustomisation === 1 && (
                  <Col md={3}>
                    <Text color='grey1' mb={2} variant='tiny'>
                      {t('chooseAColour')}
                    </Text>
                    <ColorPicker
                      value={values.color || '#ffffff'}
                      onChange={(val) => setFieldValue('color', val)}
                    />
                  </Col>
                )}
                <Col>
                  <Field
                    isMulti
                    isSelect
                    isDisabled={!locationId}
                    isLoading={isFetchingLocationTags}
                    label={t('locationTags')}
                    name='tagIds'
                    options={locationTags?.map((item) => ({
                      label: item.name || item.label,
                      value: item.id || item.value,
                    }))}
                  />
                </Col>

                <Col>
                  <Field
                    as='textarea'
                    label={t('description')}
                    name='description'
                  />
                </Col>
              </Row>
            </Step>

            <Step
              hasBorder
              boxContainerProps={{ mb: 4 }}
              title={t('scheduling')}
              value={2}
            >
              <Row>
                {isCreate && (
                  <Col>
                    <Field
                      isHorizontal
                      isRadio
                      label={t('occurrence')}
                      name='typeId'
                      options={[
                        { label: t('onceOff'), value: 1 },
                        { label: t('recurring'), value: 2 },
                        { label: t('biWeekly'), value: 3 },
                      ]}
                    />
                  </Col>
                )}

                {isOnceOff ? (
                  <Col md={3}>
                    <Field
                      isDate
                      label={t('date')}
                      maxDate={generatedToDate}
                      name='onceOffDate'
                    />
                  </Col>
                ) : (
                  !isBiWeekly && (
                    <Col>
                      <Field
                        isArray
                        isCheckbox
                        isHorizontal
                        label={t('recurringDays')}
                        name='recurringDays'
                        options={[
                          { label: t('monday'), value: 1 },
                          { label: t('tuesday'), value: 2 },
                          { label: t('wednesday'), value: 3 },
                          { label: t('thursday'), value: 4 },
                          { label: t('friday'), value: 5 },
                          { label: t('saturday'), value: 6 },
                          { label: t('sunday'), value: 7 },
                        ]}
                      />
                    </Col>
                  )
                )}

                <Col md={3} xs={6}>
                  <Field
                    isSelect
                    helper={implementedFromText}
                    label={`${typeStringCaptial} ${t('startTime')}`}
                    name='startTime'
                    options={generateTimeOptions({
                      maxTime: endTime,
                    })}
                  />
                </Col>

                <Col md={3} xs={6}>
                  <Field
                    isSelect
                    helper={implementedFromText}
                    isDisabled={!startTime}
                    label={`${typeStringCaptial} ${t('endTime')}`}
                    name='endTime'
                    options={generateTimeOptions({
                      minTime: startTime,
                    })}
                  />
                </Col>

                {!isOnceOff && (
                  <>
                    {(isCreate || isDuplicate) && (
                      <Col md={3}>
                        <Field
                          isDate
                          label={t('startDate')}
                          maxDate={generatedToDate}
                          name='recurringStartDate'
                        />
                      </Col>
                    )}

                    <Col md={3}>
                      <Field
                        isDate
                        label={t('endDate')}
                        name='recurringEndDate'
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Row marginBottom={'1rem'} marginTop={'1rem'}>
                <Col>
                  {isBiWeekly && (
                    <Text isItalic fontSize='1rem' mb={3}>
                      {t('biWeeklyOccurance', {
                        dayOfWeek: moment(values.recurringStartDate).format(
                          'dddd'
                        ),
                      })}
                    </Text>
                  )}

                  <Field
                    isCheckbox
                    marginBottom='12px'
                    name='hasAutoCancellation'
                    options={[
                      {
                        label: t('autoCancellationExists'),
                        value: true,
                      },
                    ]}
                    onChange={(value) => {
                      setFieldValue('hasAutoCancellation', value);
                      if (value === false) {
                        setFieldValue('minBookedMembersCount', 0);
                        setFieldValue('autoCancelThresholdMin', 0);
                      }
                    }}
                  />
                  {values?.hasAutoCancellation ? (
                    <>
                      <Row marginBottom='1rem'>
                        <Col md={3} sm={6}>
                          <Field
                            helper={t('autoCancelIfMemberCountNotReached')}
                            label={t('memberCount')}
                            name='minBookedMembersCount'
                          />
                        </Col>
                        <Col md={3} sm={6}>
                          <Field
                            helper={t('autoCancelIf')}
                            label={t('minutes')}
                            name='autoCancelThresholdMin'
                          />
                        </Col>
                      </Row>
                      <Text isItalic fontSize='1rem'>
                        {t('ifMembersLessThanCountAtTimeDisableInstance', {
                          memberCount: values?.minBookedMembersCount || '{X}',
                          time: values?.autoCancelThresholdMin || '{Y}',
                        })}
                      </Text>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Step>

            <Step
              hasBorder
              boxContainerProps={{ mb: 4 }}
              title={t('bookings')}
              value={3}
            >
              <Row>
                <Col md={3}>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('isThisClassVirtual', {
                      classType: typeString,
                    })}
                    name='isVirtual'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                  />
                </Col>

                <Col md={8}>
                  <Field
                    isHorizontal
                    isRadio
                    label={t('isThisClassVisibleForBookings', {
                      classType: typeString,
                    })}
                    name='isVisibleInApp'
                    options={[
                      { label: t('yes'), value: 1 },
                      { label: t('no'), value: 0 },
                    ]}
                  />
                </Col>

                <Col md={3}>
                  <Field
                    helper={implementedFromText}
                    label={`${typeStringCaptial} ${t('capacity')}`}
                    name='limit'
                  />
                </Col>

                <Col md={3}>
                  <Field
                    isSelect
                    label={t('bookingThreshold')}
                    name='bookingThreshold'
                    options={[
                      { label: i18n.t('noBookingThreshold'), value: 1 },
                      ...thresholds,
                    ]}
                  />
                </Col>

                <Col md={3}>
                  <Field
                    isSelect
                    label={t('cancellationThreshold')}
                    name='cancellationThreshold'
                    options={[
                      { label: i18n.t('noCancellationThreshold'), value: 1 },
                      ...thresholds,
                    ]}
                  />
                </Col>

                <Col md={3}>
                  <Field label={t('meetingUrl')} name='meetingUrl' />
                </Col>

                <Col>
                  <Field
                    hasColumns
                    hasSelectAll
                    isArray
                    isCheckbox
                    isLoading={isFetchingPackages}
                    // TODO: change structure
                    label={t('whichPackagesCanBookThisClass', {
                      classType: typeString,
                    })}
                    name='packageIds'
                    options={packages.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </Col>
              </Row>
            </Step>

            <Button isLoading={isLoading} text={t('submit')} type='submit' />
          </form>
        );
      }}
    </Formik>
  );
};
