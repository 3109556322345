import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useUserBatchesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, sort } = input || {};

  return useQuery(
    ['userBatches', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-batches`,
        params: {
          include: 'invoice',
          filter: {
            tenantId: filter.tenantId || tenantId,
            search: filter.search
              ? encodeURIComponent(filter.search)
              : undefined,
            debitBatchId: filter.debitBatchId,
            isActive: filter.isActive,
          },
          sort,
          ...paging,
        },
      }),
    options
  );
};

export const useUserBatchesCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-batches`,
        data: {
          tenantId: data.tenantId || tenantId,
          userId: data.userId,
          debitBatchId: data.debitBatchId,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userBatches'] });

      options?.onSuccess?.(...res);
    },
  });
};
