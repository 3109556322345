import { Formik } from 'formik';
import { Button, Col, Field, Row, Text } from 'octiv-components';
import { generateTimeOptions, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  initialValues = {},
  isFetchingLocationTags,
  isFetchingUsersInstructors,
  isLoading,
  locationTags,
  usersInstructors,
  ...props
}) => {
  const { t } = useTranslation();

  const hasAutoCancellation = !!(
    initialValues?.autoCancelThresholdMin > 0 ||
    initialValues?.minBookedMembersCount > 0
  );

  return (
    <Formik
      {...props}
      initialValues={{
        name: initialValues.name || undefined,
        instructorId: initialValues.instructorId || undefined,
        supportingInstructorId:
          initialValues.supportingInstructorId || undefined,
        startTime: initialValues.startTime || undefined,
        endTime: initialValues.endTime || undefined,
        limit: initialValues.limit || undefined,
        tagIds:
          initialValues.tags && initialValues.tags[0]
            ? initialValues.tags?.map((item) => item.id)
            : undefined,
        meetingUrl: initialValues.meetingUrl || undefined,
        description: initialValues.description || undefined,
        hasAutoCancellation,
        autoCancelThresholdMin: initialValues?.autoCancelThresholdMin || 0,
        minBookedMembersCount: initialValues?.minBookedMembersCount || 0,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        instructorId: Yup.string().required(t('required')),
        supportingInstructorId: Yup.string().nullable(),
        startTime: Yup.string().required(t('required')),
        endTime: Yup.string().required(t('required')),
        limit: Yup.string().required(t('required')),
        tagIds: Yup.array(),
        meetingUrl: Yup.string(),
        description: Yup.string(),
        hasAutoCancellation: Yup.boolean(),
        autoCancelThresholdMin: Yup.number().when('hasAutoCancellation', {
          is: (value) => value === true,
          then: Yup.number()
            .typeError(t('mustBeANumber'))
            .integer(t('mustBeAWholeNumber'))
            .min(5, t('mustBeGreaterThan', { number: 5 }))
            .required(t('required')),
          otherwise: Yup.number(),
        }),
        minBookedMembersCount: Yup.number().when('hasAutoCancellation', {
          is: (value) => value === true,
          then: Yup.number()
            .typeError(t('mustBeANumber'))
            .integer(t('mustBeAWholeNumber'))
            .min(1, t('mustBeGreaterThanZero'))
            .required(t('required')),
          otherwise: Yup.number(),
        }),
      })}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <Field label={t('name')} name='name' />
            </Col>

            <Col md={4}>
              <Field
                isSelect
                isLoading={isFetchingUsersInstructors}
                label={t('trainer')}
                name='instructorId'
                options={usersInstructors?.map((item) => ({
                  label: getFullName(item),
                  value: item.id,
                }))}
              />
            </Col>

            <Col md={4}>
              <Field
                isSelect
                isLoading={isFetchingUsersInstructors}
                label={t('supportingTrainer')}
                name='supportingInstructorId'
                options={[
                  { label: t('noSupportingInstructor'), value: null },
                  ...(usersInstructors || []).map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col md={4} xs={6}>
              <Field
                isSelect
                label={t('startTime')}
                name='startTime'
                options={generateTimeOptions({
                  maxTime: values?.endTime,
                })}
              />
            </Col>

            <Col md={4} xs={6}>
              <Field
                isSelect
                isDisabled={!values?.startTime}
                label={t('endTime')}
                name='endTime'
                options={generateTimeOptions({
                  minTime: values?.startTime,
                })}
              />
            </Col>

            <Col md={4}>
              <Field label={t('capacity')} name='limit' />
            </Col>

            <Col>
              <Field
                isMulti
                isSelect
                isLoading={isFetchingLocationTags}
                label={t('locationTags')}
                name='tagIds'
                options={locationTags?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Col>

            <Col>
              <Field label={t('meetingUrl')} name='meetingUrl' />
            </Col>

            <Col>
              <Field
                as='textarea'
                label={t('description')}
                name='description'
              />
            </Col>
          </Row>

          <Row marginBottom={'1rem'} marginTop={'1rem'}>
            <Col>
              <Field
                isCheckbox
                marginBottom='12px'
                name='hasAutoCancellation'
                options={[
                  {
                    label: t('autoCancellationExists'),
                    value: true,
                  },
                ]}
                onChange={(value) => {
                  setFieldValue('hasAutoCancellation', value);
                  if (value === false) {
                    setFieldValue('minBookedMembersCount', 0);
                    setFieldValue('autoCancelThresholdMin', 0);
                  }
                }}
              />

              {values?.hasAutoCancellation ? (
                <>
                  <Row marginBottom='1rem'>
                    <Col md={3} sm={6}>
                      <Field
                        helper={t('autoCancelIfMemberCountNotReached')}
                        label={t('memberCount')}
                        name='minBookedMembersCount'
                      />
                    </Col>
                    <Col md={3} sm={6}>
                      <Field
                        helper={t('autoCancelIf')}
                        label={t('minutes')}
                        name='autoCancelThresholdMin'
                        step='5'
                      />
                    </Col>
                  </Row>
                  <Text isItalic fontSize='1rem'>
                    {t('ifMembersLessThanCountAtTimeDisableInstance', {
                      memberCount: values?.minBookedMembersCount || '{X}',
                      time: values?.autoCancelThresholdMin || '{Y}',
                    })}
                  </Text>
                </>
              ) : null}
            </Col>
          </Row>

          <Button
            isLoading={isLoading}
            mt={4}
            text={t('submit')}
            type='submit'
          />
        </form>
      )}
    </Formik>
  );
};
