import echo from 'octiv-config/Echo';

echo.disconnect();
const events = {};

// Add a listener for an event
export const addListener = (event, locationId, callback) => {
  if (!events[event]) {
    events[event] = [];
    echo.private(`Location.${locationId}`).listen(`.${event}`, (data) => {
      events[event].forEach((cb) => cb(data)); // Ensure the callback receives `data`
    });
  }
  events[event].push(callback);
};

// Remove a listener for an event
export const removeListener = (event, callback) => {
  if (events[event]) {
    events[event] = events[event].filter((cb) => cb !== callback);
  }
};
