import { Formik } from 'formik';
import moment from 'moment';
import { Alert, Button, Col, Field, Row } from 'octiv-components';
import { getAge, initialiseUser } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const minorText = `By registering a profile on behalf of the minor, you, as the legal guardian of the minor, represent and warrant the following:

You are the authorised legal guardian, you have the legal capacity to provide consent on behalf of the minor and have not been divested of such legal guardianship and/or authority;

You consent to Octiv processing your minor child’s personal information for the purpose of creating a profile and agree to share with us their personal information, which includes but is not limited to their name, surname, date of birth and ID/passport number;

You consent to Octiv sharing and procuring the minor’s personal information with and from contracted third parties, only insofar as it relates to the administration of the Octiv programme, who may require such information in order to render a service to you or the minor, where applicable, and only if such contracted third party agrees to keep the information confidential;

All the registration information provided by You is true, accurate and complete; and

You will maintain the accuracy of such information and update such registration information as necessary.`;

const DiscoveryVitalityForm = ({ initialValues = {}, isLoading, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        ...initialiseUser(initialValues),
        healthProviderId: 1,
        hasConsented: initialValues.healthProviderId === 1,
      }}
      validationSchema={Yup.object().shape({
        dateOfBirth: Yup.date()
          .min(moment('1900-01-01').toDate(), t('invalidDate'))
          .max(moment().toDate(), t('invalidDate'))
          .required(t('required')),
        idNumber: Yup.string().required(t('required')),
        hasConsented: Yup.bool()
          .oneOf([true], t('required'))
          .required(t('required')),
      })}
    >
      {({ handleSubmit, values: { dateOfBirth } }) => {
        const isMinor = !!dateOfBirth && getAge({ dateOfBirth }) < 18;

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Field
                  isDate
                  label={t('dateOfBirth')}
                  maxDate={moment()}
                  minDate={moment('1900-01-01')}
                  name='dateOfBirth'
                />
              </Col>

              <Col md={6}>
                <Field label='RSA ID/Passport Number' name='idNumber' />
              </Col>

              {isMinor && (
                <Col>
                  <Alert
                    subtitle={minorText}
                    title='Legal Guardian Consent'
                    variant='warning'
                  />
                </Col>
              )}

              {!!dateOfBirth && (
                <Col>
                  <Field
                    isCheckbox
                    name='hasConsented'
                    options={[
                      {
                        label: `I${
                          isMinor ? ', the legal guardian,' : ''
                        } consent to sharing this information with Discovery Vitality to award Vitality points`,
                        value: true,
                      },
                    ]}
                  />
                </Col>
              )}

              <Col>
                <Button
                  isLoading={isLoading}
                  text={t('submit')}
                  type='submit'
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};

export default DiscoveryVitalityForm;
