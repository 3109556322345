import { Formik } from 'formik';
import { Box, Button, Col, Field, Row, Text } from 'octiv-components';
import ColorPicker from 'octiv-components/colourPicker';
import { languageOptions } from 'octiv-utilities/Constants';
import { getTheme } from 'octiv-utilities/Theme';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        theme: {
          isDark:
            initialValues?.theme?.isDark === false ||
            initialValues?.theme?.isDark === 'false' ||
            initialValues?.theme?.isDark === 0 ||
            initialValues?.theme?.isDark === '0'
              ? 0
              : 1,
          primaryDark: initialValues?.theme?.primaryDark || undefined,
          buttonDark: initialValues?.theme?.buttonDark || undefined,
          primaryLight: initialValues?.theme?.primaryLight || undefined,
          buttonLight: initialValues?.theme?.buttonLight || undefined,
        },
        hiddenFeatures: initialValues.hiddenFeatures || [],
        logo: initialValues.logo || undefined,
        localeLanguage: initialValues.localeLanguage,
      }}
      validationSchema={Yup.object().shape({
        theme: Yup.object().shape({
          isDark: Yup.bool(),
          primaryDark: Yup.string().matches(
            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
            `${t('format')}: #ffffff`
          ),
          buttonDark: Yup.string().matches(
            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
            `${t('format')}: #ffffff`
          ),
          primaryLight: Yup.string().matches(
            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
            `${t('format')}: #ffffff`
          ),
          buttonLight: Yup.string().matches(
            /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
            `${t('format')}: #ffffff`
          ),
        }),
        hiddenFeatures: Yup.array(),
        logo: Yup.string(),
      })}
    >
      {({ handleSubmit, dirty, values, errors, setFieldValue }) => {
        const theme = useMemo(
          () =>
            getTheme({
              primaryDark:
                values?.theme?.primaryDark && !errors?.theme?.primaryDark
                  ? values?.theme?.primaryDark
                  : undefined,
              buttonDark:
                values?.theme?.buttonDark && !errors?.theme?.buttonDark
                  ? values?.theme?.buttonDark
                  : undefined,
              primaryLight:
                values?.theme?.primaryLight && !errors?.theme?.primaryLight
                  ? values?.theme?.primaryLight
                  : undefined,
              buttonLight:
                values?.theme?.buttonLight && !errors?.theme?.buttonLight
                  ? values?.theme?.buttonLight
                  : undefined,
            }),
          [
            errors?.theme?.buttonDark,
            errors?.theme?.buttonLight,
            errors?.theme?.primaryDark,
            errors?.theme?.primaryLight,
            values?.theme?.buttonDark,
            values?.theme?.buttonLight,
            values?.theme?.primaryDark,
            values?.theme?.primaryLight,
          ]
        );

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Text mb={1} variant='subheading'>
                  {t('webApplicationTheme')}
                </Text>

                <Text color='grey1' mb={2}>
                  {t('memberApplicationUsesDeviceTheme')}
                </Text>

                <Field
                  isRadio
                  name='theme.isDark'
                  options={[
                    { label: t('darkTheme'), value: 1 },
                    { label: t('lightTheme'), value: 0 },
                  ]}
                />
              </Col>

              <Col>
                <Text mb={1} mt={1} variant='subheading'>
                  {t('brandColors')}
                </Text>

                <Text color='grey1'>{t('addColorsForLightAndDarkThemes')}</Text>
              </Col>

              <Col md={4}>
                <Text color='grey1' mb={2} variant='tiny'>
                  {t('darkThemePrimaryColor')}
                </Text>
                <ColorPicker
                  value={values.theme.primaryDark || '#000000'}
                  onChange={(val) => setFieldValue('theme.primaryDark', val)}
                />
              </Col>

              <Col md={4}>
                <Text color='grey1' mb={2} variant='tiny'>
                  {t('darkThemeButtonTextColor')}
                </Text>
                <ColorPicker
                  value={values.theme.buttonDark || '#000000'}
                  onChange={(val) => setFieldValue('theme.buttonDark', val)}
                />
              </Col>

              <Col md={4}>
                <Box bg={theme?.dark?.app?.colors?.grey7} flex={1} p={4}>
                  <Box
                    hasRadius
                    isFlex
                    alignItems='center'
                    bg={theme?.dark?.app?.colors?.primary}
                    flex={1}
                    height={10}
                    justifyContent='center'
                  >
                    <Text
                      color={theme?.dark?.app?.colors?.button}
                      textAlign='center'
                    >
                      {t('darkTheme')}
                    </Text>
                  </Box>
                </Box>
              </Col>

              <Col md={4}>
                <Text color='grey1' mb={2} variant='tiny'>
                  {t('lightThemePrimaryColor')}
                </Text>
                <ColorPicker
                  value={values.theme.primaryLight || '#000000'}
                  onChange={(val) => setFieldValue('theme.primaryLight', val)}
                />
              </Col>

              <Col md={4}>
                <Text color='grey1' mb={2} variant='tiny'>
                  {t('lightThemeButtonTextColor')}
                </Text>
                <ColorPicker
                  value={values.theme.buttonLight || '#000000'}
                  onChange={(val) => setFieldValue('theme.buttonLight', val)}
                />
              </Col>

              <Col md={4}>
                <Box bg={theme?.light?.app?.colors?.grey7} flex={1} p={4}>
                  <Box
                    hasRadius
                    isFlex
                    alignItems='center'
                    bg={theme?.light?.app?.colors?.primary}
                    flex={1}
                    height={10}
                    justifyContent='center'
                  >
                    <Text
                      color={theme?.light?.app?.colors?.button}
                      textAlign='center'
                    >
                      {t('lightTheme')}
                    </Text>
                  </Box>
                </Box>
              </Col>

              <Col>
                <Text mb={1} variant='subheading'>
                  {t('tenantLogo')}
                </Text>

                <Text color='grey1' mb={2}>
                  {t('tenantLogoDescription')}
                </Text>

                <Field isDrop name='logo' />
              </Col>

              <Col>
                <Text mb={1} variant='subheading'>
                  {t('hiddenFeatures')}
                </Text>

                <Text color='grey1' mb={2}>
                  {t('hiddenFeaturesDescription')}
                </Text>

                <Field
                  isArray
                  isCheckbox
                  name='hiddenFeatures'
                  options={[
                    { label: t('comms'), value: 'comms' },
                    { label: t('leads'), value: 'leads' },
                    { label: t('scheduling'), value: 'scheduling' },
                    { label: t('store'), value: 'store' },
                    { label: t('tasks'), value: 'tasks' },
                    { label: t('workouts'), value: 'workouts' },
                    { label: t('injuries'), value: 'injuries' },
                  ]}
                />
              </Col>

              <Col lg={4} md={6}>
                <Text mb={1} variant='subheading'>
                  {t('language')}
                </Text>
                <Field
                  isSelect
                  label={t('languagePreference')}
                  name='localeLanguage'
                  options={languageOptions}
                />
              </Col>

              <Col>
                <Button
                  isDisabled={!dirty}
                  isLoading={isLoading}
                  text={t('submit')}
                  type='submit'
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};
