import { Alert, Box, Button, Container, Divider } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useMemberModal } from 'octiv-context/MemberModal';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { usePaging, useQuery } from 'octiv-hooks';
import { useLocationsFind } from 'octiv-hooks/requests/Locations';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import {
  useUsersCreateExport,
  useUsersFindMembers,
  useUsersFindStaff,
} from 'octiv-hooks/requests/Users';
import { downloadFile, getSelectedRows } from 'octiv-utilities/Functions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import MemberBulkActions from '../shared/MemberBulkActions';
import MembersWrapper from '../shared/MembersWrapper';
import FormQuery from './FormQuery';
import Table from './Table';

const MembersManage = () => {
  const { t } = useTranslation();

  const initialUserTenantId = useQuery('userTenantId');
  const initialUserStatusId = useQuery('statusId');

  const { isSuperAdmin } = useSignedInUser();
  const {
    selectedLocation,
    tenant: { locationOptionsAll },
  } = useActiveUserTenant();
  const { setMemberModal } = useMemberModal();
  const hasAccess = useContext(AccessContext);

  const [members, setMembers] = useState([]);
  const [paging, setPaging] = usePaging();
  const [query, setQuery] = useState({
    userTenantStatusId:
      initialUserStatusId !== null ? Number(initialUserStatusId) : 2,
    userTenantLocationId: selectedLocation?.id,
    userTenantDebitStatusId: undefined,
    userTenantProgrammeId: undefined,
    userTenantAssignedTrainerUserId: undefined,
    userTenantPackageId: undefined,
    search: undefined,

    sort: 'name',
  });

  const selectedData = getSelectedRows({ data: members });

  const locationsFind = useLocationsFind(undefined, {
    enabled: isSuperAdmin,
  });

  const usersFindMembers = useUsersFindMembers(
    {
      filter: { deleted: 0, ...query },
      include: 'userTenant.userContract,userTenant.userPackages,isOverdue',
      sort: query.sort,
      paging,
    },
    {
      onSuccess: (response) => setMembers(response?.data),
    }
  );

  const usersFindStaff = useUsersFindStaff({
    filter: {
      userTenantLocationId: query.userTenantLocationId,
      isMinimalData: false,
    },
    paging: { perPage: -1 },
  });

  const usersFindMembersPending = useUsersFindMembers({
    filter: {
      userTenantLocationId: query.userTenantLocationId,
      userTenantStatusId: 1,
    },
    paging: { perPage: -1 },
  });

  const packagesFind = usePackagesFind({
    filter: { isActive: 1 },
    paging: { perPage: -1 },
  });

  const usersCreateExport = useUsersCreateExport({
    onSuccess: downloadFile,
  });

  useEffect(() => {
    if (initialUserTenantId) {
      setMemberModal({ userTenantId: initialUserTenantId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMemberModal]);

  return (
    <MembersWrapper selectedData={selectedData}>
      {({ onClickAction, programmesFind, isContainerLoading }) => (
        <Container
          appBarProps={{
            title: t('manageMembers'),
            breadcrumbs: [t('members')],
            children: (
              <Box ml='auto'>
                <Box isFlex>
                  {hasAccess.featureMembersExport && (
                    <Button
                      hasBorder
                      mr={2}
                      text={t('csvExport')}
                      onClick={() =>
                        usersCreateExport.mutate({
                          filter: query,
                          sort: query.sort,
                        })
                      }
                    />
                  )}

                  {hasAccess.screenMembersCreate && (
                    <Button text={t('createNew')} to='/members/create' />
                  )}
                </Box>
              </Box>
            ),
          }}
          isLoading={
            isContainerLoading ||
            usersCreateExport.isLoading ||
            usersFindMembers.isFetching
          }
        >
          {query.userTenantStatusId !== 1 &&
            usersFindMembersPending.data?.data?.length > 0 && (
              <Alert
                mb={4}
                subtitle={t('membersInPendingState', {
                  count: usersFindMembersPending.data?.data?.length,
                })}
                variant='warning'
                onClick={() =>
                  setQuery((prev) => ({
                    ...prev,
                    userTenantStatusId: 1,
                  }))
                }
              />
            )}

          <FormQuery
            initialValues={query}
            isFetchingPackages={packagesFind.isFetching}
            isFetchingProgrammes={programmesFind.isFetching}
            isFetchingStaff={usersFindStaff.isFetching}
            locationOptions={
              isSuperAdmin
                ? locationsFind?.data?.data.map((location) => ({
                    label: location?.name,
                    value: location?.id,
                  }))
                : locationOptionsAll
            }
            packages={packagesFind.data?.data}
            programmes={programmesFind.data?.data}
            staff={usersFindStaff.data?.data}
            onSubmit={(values) =>
              setQuery((prev) => ({
                ...values,
                sort: prev.sort,
              }))
            }
          />

          <Divider pb={4} />

          <MemberBulkActions
            mb={4}
            queryStatusId={query.userTenantStatusId}
            selectedData={selectedData}
            onClickAction={onClickAction}
          />

          <Table
            data={members || []}
            highlightTestFunc={({ original: { userTenant } }) =>
              userTenant.isOverdue === true
            }
            isLoading={usersFindMembers.isFetching}
            paging={usersFindMembers.data?.paging}
            query={query}
            setPaging={setPaging}
            setQuery={setQuery}
            setSelected={setMembers}
            onClickAction={onClickAction}
            onClickRow={({ original }) => {
              setMemberModal({ userTenantId: original?.userTenant?.id });
            }}
            onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
          />
        </Container>
      )}
    </MembersWrapper>
  );
};

export default MembersManage;
