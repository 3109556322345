import './Styles.css';

import { Alert, ProcessingBar } from 'octiv-components';
import {
  useFinancesCreatePaystackInitializeRecurringCharge,
  useFinancesCreateStripeConnectLocationSetupIntentLink,
  useFinancesFindLocationPaymentTokens,
} from 'octiv-hooks/requests/Finances';
import { paymentGateways } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const [clickAction, setClickAction] = useState('');

  const findLocationPaymentTokens = useFinancesFindLocationPaymentTokens();

  const hasNotOnboarded = findLocationPaymentTokens?.data?.some(
    (item) =>
      !item.paymentToken &&
      item.location.billingPaymentGatewayId !== paymentGateways.NO_PAYMENT
  );

  const postInitializeRecurringChargeRequest =
    useFinancesCreatePaystackInitializeRecurringCharge({
      enabled: clickAction === 'openPaystackOnboardingLink',
      onSuccess: (response) => {
        setClickAction('');
        window.location.assign(response.authorizationUrl);
      },
    });

  const postSetupIntentLinkRequest =
    useFinancesCreateStripeConnectLocationSetupIntentLink({
      enabled: clickAction === 'openStripeConnectOnboardingLink',
      onSuccess: (response) => {
        setClickAction('');
        window.location.assign(response);
      },
    });

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'openPaystackOnboardingLink':
        postInitializeRecurringChargeRequest.mutate({
          locationId: id,
          paymentTokenType: 'location',
        });
        break;

      case 'openStripeConnectOnboardingLink':
        postSetupIntentLinkRequest.mutate({ locationId: id });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {(postSetupIntentLinkRequest.isLoading ||
        postInitializeRecurringChargeRequest.isLoading) && <ProcessingBar />}

      <Setting title={t('octivBilling')}>
        {hasNotOnboarded && (
          <Alert
            mb={4}
            subtitle={t('locationNotOnboardedForBilling')}
            variant='danger'
          />
        )}

        <Table
          hasAlternateBackground
          data={findLocationPaymentTokens.data || []}
          isLoading={findLocationPaymentTokens.isLoading}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
