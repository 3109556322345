import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        userIds: initialValues.userIds,
        startDate: undefined,
        endDate: undefined,
        isSend: 0,
      }}
      validationSchema={Yup.object().shape({
        userIds: Yup.array(),
        startDate: Yup.string().required(t('required')),
        endDate: Yup.string(),
        isSend: Yup.bool().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field isDate label={t('startDate')} name='startDate' />
            </Col>

            <Col>
              <Field isDate label={t('endDate')} name='endDate' />
            </Col>

            <Col>
              <Field
                isHorizontal
                isRadio
                label={t('send')}
                name='isSend'
                options={[
                  {
                    label: t('yes'),
                    value: 1,
                  },
                  {
                    label: t('no'),
                    value: 0,
                  },
                ]}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
