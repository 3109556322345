import {
  Box,
  Card,
  Image,
  ProcessingSuspense,
  SendComm,
  Text,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import { canSetMemberModal, getFullName } from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, isLoading }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const sendCommMembers = useMemo(() => {
    return data?.map(({ user }) => ({
      id: user?.id,
      name: user?.name,
      surname: user?.surname,
    }));
  }, [data]);

  return (
    <Card
      boxChildrenContainerProps={{
        height: 61,
        overflowY: 'scroll',
        className: 'hide-scrollbar',
      }}
      boxTitleContainerRenderRight={() => (
        <SendComm isIcon members={!isLoading && sendCommMembers} ml='auto' />
      )}
      textTitleProps={{ to: '/members/injuries' }}
      title={t('recentInjuries')}
    >
      {isLoading ? (
        <ProcessingSuspense
          items={[
            { width: '40%' },
            { width: '60%', mb: 4 },
            { width: '40%' },
            { width: '60%', mb: 4 },
          ]}
        />
      ) : !data?.[0] ? (
        <Text color='grey2'>{t('noRecentInjuries')}</Text>
      ) : (
        data.map(({ user, updates }, index) => (
          <Box
            isFlex
            alignItems='center'
            key={`${index.toString()}`}
            mt={index > 0 ? 3 : 0}
          >
            <Image
              hasRadius
              usePlaceholderUser
              mr={2}
              size={9}
              src={user.image}
            />

            <Box>
              <Text
                isBold
                onClick={
                  canSetMemberModal({ userTenant: user?.userTenant, hasAccess })
                    ? () =>
                        setMemberModal({ userTenantId: user?.userTenant?.id })
                    : null
                }
              >
                {getFullName(user)}
              </Text>
              <Text color='grey1' variant='caption'>
                {updates[updates.length - 1].content}
              </Text>
            </Box>
          </Box>
        ))
      )}
    </Card>
  );
};
