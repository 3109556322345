import {
  useSettingsFindSignUp,
  useSettingsUpdateSignUp,
} from 'octiv-hooks/requests/Settings';
import React from 'react';

import Setting from '../../components/Setting';
import Form from './Form';

export default () => {
  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = useSettingsFindSignUp({});

  const { isLoading: isUpdatingSettings, mutate: putSettingsRequest } =
    useSettingsUpdateSignUp({ onSuccess: getSettingsRequest });

  const handleSubmit = (values) => putSettingsRequest(values);

  return (
    <Setting isLoading={isFetchingSettings}>
      <Form
        initialValues={
          {
            signUpBillingDayOptions: ['1', '5'], // TODO: remove this once the data comes from the API
            ...settings,
          } || {}
        }
        isLoading={isUpdatingSettings}
        onSubmit={handleSubmit}
      />
    </Setting>
  );
};
