import './Styles.css';

import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';

const ColorPicker = ({ value, onChange, width = '110px', height = '35px' }) => {
  const theme = useContext(ThemeContext);

  return (
    <input
      className='custom-color-picker'
      style={{
        width,
        height,
        border: '1px solid',
        borderColor: theme?.isDark ? 'white' : 'black',
        borderRadius: '10px',
      }}
      type='color'
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default ColorPicker;
