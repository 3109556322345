import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useUserPackagesFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {}, paging = {}, ...queryParams } = input || {};

  return useQuery(
    ['userPackages', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-packages`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            packageId: filter.packageId,
            userId: filter.userId,
            startsBetween: filter.startsBetween,
            endsBetween: filter.endsBetween,
            isActive: filter.isActive,
            isPackageActive: filter.isPackageActive,
            userTenantStatusId: filter?.userTenantStatusId,
          },
          ...queryParams,
          ...paging,
        },
      }),
    options
  );
};

export const useUserPackagesCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-packages`,
        data: {
          userId: data.userId,
          packageId: data.packageId,
          startingOn: data.startingOn,
          endingOn: data.endingOn,
          invoice: data.invoice,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesCreateExport = (options) => {
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { filter = {}, paging = {}, ...queryParams } = input || {};

      return request({
        method: 'get',
        url: `/api/user-packages/export`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            locationId: filter.locationId,
            packageId: filter.packageId,
            userId: filter.userId,
            startsBetween: filter.startsBetween,
            endsBetween: filter.endsBetween,
            isActive: filter.isActive,
            isPackageActive: filter.isPackageActive,
          },
          ...queryParams,
          ...paging,
        },
      });
    },
  });
};

export const useUserPackagesFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['userPackages', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/user-packages/${id}`,
      }),
    options
  );
};

export const useUserPackagesUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/user-packages/${id}`,
        data: {
          startingOn: data.startingOn,
          endingOn: data.endingOn,
          sessionsAvailable: data.sessionsAvailable,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/user-packages/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesCreateBuy = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-packages/buy`,
        data: {
          tenantId: data.tenantId || tenantId,
          packageId: data.packageId,
          paymentDetails: data.paymentDetails,
          termsAndConditionsForWaiver: data.termsAndConditionsForWaiver ? 1 : 0,
          termsAndConditionsForContract: data.termsAndConditionsForContract
            ? 1
            : 0,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesUpdateDeactivate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'put',
        url: `/api/user-packages/${id}/deactivate`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesUpdateMemberTopUp = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/user-packages/${id}/member-top-up`,
        data: { sessions: data.sessions },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesUpdateTrainerTopUp = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/user-packages/${id}/coach-top-up`,
        data: {
          paymentType: data.paymentType,
          sessions: data.sessions,
          isSend: data.isSend,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesBulkCreate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/user-packages/bulk-create`,
        data: {
          userIds: data.userIds,
          packageId: data.packageId,
          startingOn: data.startingOn,
          endingOn: data.endingOn,
          invoice: data.invoice,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useUserPackagesBulkUpdate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      return request({
        method: 'post',
        url: `/api/user-packages/bulk-update`,
        data: {
          tenantId: input.tenantId || tenantId,
          userIds: input.userIds,
          packageIds: input.packageIds,
          startingOn: input.startingOn,
          endingOn: input.endingOn,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['userPackages'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
      queryClient.invalidateQueries({ queryKey: ['userTenants'] });

      options?.onSuccess?.(...res);
    },
  });
};
