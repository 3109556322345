import {
  Alert,
  Box,
  Button,
  ConfirmationDialog,
  Container,
  Icon,
  Text,
} from 'octiv-components';
import { usePaging } from 'octiv-hooks';
import {
  useAccessControlCreateAccount,
  useAccessControlDeleteAccount,
  useAccessControlFindAccounts,
} from 'octiv-hooks/requests/AccessControl';
import { useAcsEvents } from 'octiv-hooks/useAcsEvents';
import { AcsEvent, acsPartners } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import TableAccessAccounts from './TableAccessAccounts';

export default ({
  setQuery,
  locationOptionsAll,
  query,
  setIsActive,
  setManageDeviceDetails,
}) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [credentialToDelete, setCredentialToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState({
    status: false,
    id: null,
  });

  const [paging, setPaging] = usePaging(10);
  const apiFind = useAccessControlFindAccounts({
    filter: query,
    paging,
  });

  useAcsEvents(query?.locationId, [AcsEvent.SYNC_COMPLETE], () => {
    apiFind.refetch();
  });

  const apiCreate = useAccessControlCreateAccount({
    onSuccess: (res) => {
      window.open(res.url, '_blank');
    },
  });

  const apiDelete = useAccessControlDeleteAccount({
    meta: { useOnSuccessToast: true },
    onSuccess: () => {
      setIsDeleting({ status: true, id: credentialToDelete });
    },
  });

  const handleActionClick = ({
    action,
    id,
    isConnected,
    providerName,
    userDetails,
  }) => {
    switch (action) {
      case 'delete':
        setCredentialToDelete(id);
        setOpenDialog(true);
        break;
      case 'manage':
        setIsActive(2);
        setManageDeviceDetails({
          providerName,
          userDetails,
          isConnected,
        });
        setQuery((previousQuery) => ({
          ...previousQuery,
          accountId: id,
        }));
        break;
      default:
        break;
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDeleteCredential = () => {
    if (credentialToDelete) {
      apiDelete.mutate({
        id: credentialToDelete,
      });
    }
    setOpenDialog(false);
  };

  return (
    <Container
      appBarProps={{
        title: t('connectedAccounts'),
        breadcrumbs: [t('accessControl')],
        children: (
          <Box ml='auto'>
            <Box isFlex>
              <Button
                hasBorder
                mr={2}
                text={t('addAccount')}
                onClick={() =>
                  apiCreate.mutate({
                    locationId: query.locationId,
                    providerId: acsPartners.SEAM,
                  })
                }
              />{' '}
            </Box>
          </Box>
        ),
      }}
    >
      <ConfirmationDialog
        isDanger
        confirmText={t('delete')}
        message={t('deleteAcsAccountConfirmation')}
        open={openDialog}
        securityPrompt={t('deleteAccount')}
        title={t('deleteAccount')}
        onCancel={handleDialogClose}
        onConfirm={handleDeleteCredential}
      />
      <FormQuery
        initialValues={query}
        locationOptions={locationOptionsAll}
        onSubmit={setQuery}
      />
      <Box height={4} />

      {apiFind.data?.data?.length > 0 || apiFind.isFetching ? (
        <TableAccessAccounts
          data={apiFind.data?.data || []}
          isDeleting={isDeleting}
          isLoading={apiFind.isFetching}
          locationOptions={locationOptionsAll}
          paging={apiFind.data?.paging}
          setPaging={setPaging}
          onClickAction={handleActionClick}
        />
      ) : (
        <>
          <Alert
            mb={4}
            subtitle={t('accountSyncDelay')}
            title={t('pleaseNote')}
            variant='warning'
          />
          <Container
            isFlex
            alignItems='center'
            border={[1]}
            borderColor='grey4'
            flexDirection='column'
            height='60vh'
            justifyContent='center'
          >
            <Icon customSize='5rem' mb={3} name='people_alt' />
            <Text isBold fontSize='2rem' mb={2} textAlign='center'>
              {t('noAccountsYet')}
            </Text>
            <Text fontSize='1rem' textAlign='center'>
              {t('pairThirdPartyAccount')}
            </Text>
          </Container>
        </>
      )}
    </Container>
  );
};
