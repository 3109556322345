import { Container } from 'octiv-components';
import { useFinancesCreateStripeConnectDebitOrderSetupIntentLink } from 'octiv-hooks/requests/Finances';
import React from 'react';
import { useParams } from 'react-router-dom';

export default () => {
  const params = useParams();

  const {
    isLoading: isCreatingSetupIntentLink,
    mutate: postSetupIntentLinkRequest,
  } = useFinancesCreateStripeConnectDebitOrderSetupIntentLink({
    enabled: true,
    onSuccess: (response) => {
      window.location.assign(response);
    },
  });

  React.useEffect(() => {
    postSetupIntentLinkRequest({
      userId: params?.userId,
      locationId: params?.locationId,
    });
  }, [params?.userId, params?.locationId, postSetupIntentLinkRequest]);

  return <Container isLoading={isCreatingSetupIntentLink} />;
};
