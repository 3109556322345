import { Formik } from 'formik';
import moment from 'moment';
import { Button, Col, Field, Row } from 'octiv-components';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, userTenant, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        ...(initialValues.userIds ? { userIds: initialValues.userIds } : {}),
        startDate:
          initialValues.startsAt || getDateYearMonthDay({ canFallback: true }),
        releaseDate: initialValues.releaseAt || undefined,
        proRataFee: initialValues.proRataFee || undefined,
        isExtendPackageEndDate: initialValues.isExtendPackage === 1 ? 1 : 0,
        note: initialValues.note || undefined,
      }}
      validationSchema={Yup.object().shape({
        startDate: Yup.string().required(t('required')),
        releaseDate: Yup.string(),
        ...(userTenant?.debitStatusId !== 7
          ? {
              proRataFee: Yup.string(),
              isExtendPackageEndDate: Yup.bool(),
            }
          : {}),
        note: Yup.string(),
      })}
    >
      {({ handleSubmit, values: { startDate, releaseDate } }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isDate
                label={t('startDate')}
                maxDate={
                  releaseDate
                    ? moment(releaseDate).subtract(1, 'day').toDate()
                    : undefined
                }
                name='startDate'
              />
            </Col>

            <Col>
              <Field
                isDate
                label={t('releaseDate')}
                minDate={
                  moment(startDate).isAfter(moment())
                    ? moment(startDate).add(1, 'day').toDate()
                    : moment().add(1, 'day').toDate()
                }
                name='releaseDate'
              />
            </Col>

            {releaseDate && userTenant?.debitStatusId !== 7 && (
              <>
                <Col>
                  <Field
                    isRadio
                    label={t('whatWouldYouLikeToDoWithPackageEndDates')}
                    name='isExtendPackageEndDate'
                    options={[
                      {
                        label: t('leaveAsTheyAre'),
                        value: 0,
                      },
                      {
                        label: t('extendByNumberOfDaysOnHold'),
                        value: 1,
                      },
                    ]}
                  />
                </Col>

                <Col>
                  <Field
                    label={t('firstDebitOrderInvoiceAmount')}
                    name='proRataFee'
                  />
                </Col>
              </>
            )}

            <Col>
              <Field
                as='textarea'
                label={t('note')}
                name='note'
                placeholder={t('whyPlacedOnHold')}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
