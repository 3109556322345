import React from 'react';

export default ({ code, size }) => {
  const iconUrl = `${window.location.origin}/assets/img/card-${code}.svg`;

  return (
    <img
      alt={`company-${code}`}
      src={iconUrl}
      style={{ width: size, height: 'auto' }}
    />
  );
};
