import { Formik } from 'formik';
import { Button, Col, Field, Row } from 'octiv-components';
import { toSentenceCase } from 'octiv-utilities/Casing';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingAccountCapabilities,
  isLoading,
  accountCapabilities,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        accountCapabilityIds: isFetchingAccountCapabilities
          ? []
          : accountCapabilities
              .filter((item) => item.requested)
              .map((item) => item.id),
      }}
      validationSchema={Yup.object().shape({
        accountCapabilityIds: Yup.array()
          .required(t('required'))
          .min(1, t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                hasSelectAll
                isArray
                isCheckbox
                isLoading={isFetchingAccountCapabilities}
                label={t('accountCapabilities')}
                name='accountCapabilityIds'
                options={accountCapabilities.map(({ id }) => ({
                  label:
                    id === 'us_bank_transfer_payments'
                      ? 'US bank transfer payments'
                      : id === 'us_bank_account_ach_payments'
                      ? 'US bank account ach payments'
                      : toSentenceCase(id),
                  value: id,
                }))}
              />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
