import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Box, Button, Text } from 'octiv-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContentContainer } from './Styled';

const ConfirmationDialog = ({
  open,
  title,
  message = null,
  onCancel,
  onConfirm,
  confirmText = 'confirm',
  securityPrompt = null,
  isConfirm = false,
  isDanger = false,
  isInfo = false,
}) => {
  const { t } = useTranslation();
  const [securityInput, setSecurityInput] = useState('');

  useEffect(() => {
    if (!open) {
      setSecurityInput('');
    }
  }, [open]);

  const isConfirmDisabled =
    !!securityPrompt && securityInput !== securityPrompt;

  const handleInputChange = (event) => {
    setSecurityInput(event.target.value);
  };

  const handlePaste = (event) => {
    event.preventDefault();
  };

  // Determine the button color based on props
  const confirmButtonColor = isConfirm
    ? 'success'
    : isDanger
    ? 'danger'
    : 'primary';

  if (isInfo) {
    return (
      <Dialog open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        {message && (
          <DialogContent>
            <Text variant='subheading'>{message}</Text>
          </DialogContent>
        )}
        <DialogActions sx={{ marginBottom: 1, marginRight: 1 }}>
          <Button color='primary' onClick={onCancel}>
            {t('okay')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      {message && (
        <DialogContent>
          <Text variant='subheading'>{message}</Text>
        </DialogContent>
      )}

      {securityPrompt && (
        <DialogContentContainer>
          <Box mb={3}>
            <Text variant='subheading'>{t('securityPhrasePrompt')}</Text>
            <Text isBold color={confirmButtonColor} variant='subheading'>
              {securityPrompt}
            </Text>
          </Box>

          <TextField
            fullWidth
            placeholder={t('securityPhrasePlaceholder')}
            value={securityInput}
            variant='outlined'
            onChange={handleInputChange}
            onPaste={handlePaste}
          />
        </DialogContentContainer>
      )}
      <DialogActions sx={{ marginBottom: 1, marginRight: 1 }}>
        <Button color='secondary' onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button
          color={confirmButtonColor}
          disabled={isConfirmDisabled}
          onClick={onConfirm}
        >
          {t(confirmText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
