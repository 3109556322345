import { AcsProviderImages, Images } from 'octiv-assets';
import {
  Box,
  Button,
  Icon,
  Image,
  ProcessingSpinner,
  Table,
  Text,
} from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import { getFullName, toCamelCase } from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  data,
  loggedInUserTypeId,
  onClickAction,
  locationOptions,
  isDeleting,
  ...props
}) => {
  const { t } = useTranslation();
  const { xsDown } = useMediaQuery();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        Cell: ({ row: { original } }) => (
          <Box isFlex alignItems='center'>
            <Image
              hasRadius
              usePlaceholderUser
              backgroundSize='contain'
              mr={3}
              size={xsDown ? 6 : 8}
              src={
                original.type
                  ? AcsProviderImages[toCamelCase(original.type)]
                  : Images.user
              }
            />
            <Box isFlex flexDirection='column' justifyContent='center'>
              <Text>{getFullName(original)}</Text>
              <Text color='grey1' variant='small'>
                {original.userIdentifier?.username ||
                  original.userIdentifier?.email}
              </Text>
            </Box>
          </Box>
        ),
      },
      {
        Header: t('location'),
        accessor: 'locationId',
        Cell: ({ row: { original } }) =>
          locationOptions.find((item) => item.value === original.locationId)
            ?.label,
      },
      {
        Header: t('dateAdded'),
        accessor: 'createdAt',
      },
      {
        Header: t('status'),
        Cell: ({ row: { original } }) => (
          <Box isFlex alignItems='center' minHeight={9}>
            <Icon
              color={original?.errors?.length > 0 ? 'danger' : 'success'}
              customSize='0.7rem'
              mr={1}
              name='circle'
              px={1}
            />
            <Text isFlex>
              {original?.errors?.length > 0 ? t('offline') : t('connected')}
            </Text>
          </Box>
        ),
      },
      {
        accessor: 'id',
        Cell: ({ row: { original } }) => {
          // Check if this specific row is being deleted
          const isRowDeleting =
            isDeleting.status && isDeleting.id === original.id;

          return (
            <Box
              isFlex
              justifyContent={isRowDeleting ? 'center' : 'flex-end'}
              px={2}
            >
              {isRowDeleting ? (
                <Box isFlex alignItems='center'>
                  <ProcessingSpinner mr={4} size={8} />
                  <Text color='grey2'>{t('deletingAccount')}</Text>
                </Box>
              ) : (
                <>
                  <Button
                    hasBorder
                    mr={2}
                    text={t('manageDevices')}
                    onClick={() =>
                      onClickAction({
                        action: 'manage',
                        id: original.id,
                        providerName: original.type,
                        isConnected: !(original?.errors?.length > 0),
                        userDetails:
                          original.userIdentifier?.username ||
                          original.userIdentifier?.email,
                      })
                    }
                  />
                  <Button
                    hasBorder
                    ml={2}
                    text={t('deleteAccount')}
                    onClick={() =>
                      onClickAction({
                        action: 'delete',
                        id: original.id,
                      })
                    }
                  />
                </>
              )}
            </Box>
          );
        },
      },
    ],
    [locationOptions, xsDown, onClickAction, t, isDeleting]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
