import { Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('firstName'),
        accessor: 'firstName',
      },
      {
        Header: t('surname'),
        accessor: 'surname',
      },
      {
        Header: 'Member ID',
        accessor: 'memberId',
      },
      {
        Header: 'Member Address',
        accessor: 'memberAddress',
      },
      {
        Header: t('gender'),
        accessor: 'gender',
      },
      {
        Header: t('email'),
        accessor: 'email',
      },
      {
        Header: t('mobile'),
        accessor: 'mobile',
      },
      {
        Header: t('dateOfBirth'),
        accessor: 'dateOfBirth',
      },
      {
        Header: t('location'),
        accessor: 'location',
      },
      {
        Header: t('package'),
        accessor: 'package',
      },
      {
        Header: t('packageStartDate'),
        accessor: 'packageStartDate',
      },
      {
        Header: t('packageEndDate'),
        accessor: 'packageEndDate',
      },
      {
        Header: t('programme'),
        accessor: 'programme',
      },
      {
        Header: 'Contract Start Date',
        accessor: 'contractStartDate',
      },
      {
        Header: 'Contract End Date',
        accessor: 'contractEndDate',
      },
      {
        Header: t('address'),
        accessor: 'address',
      },
      {
        Header: t('socialSecurityNumber'),
        accessor: 'idNumber',
      },
      {
        Header: t('status'),
        accessor: 'status',
      },
    ],
    [t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
