import i18n from 'octiv-config/i18n';

export const isProduction = process.env.REACT_APP_ENV === 'production';
export const isStaging =
  process.env.REACT_APP_ENV === 'staging' &&
  window.location.origin.includes('octiv-s.vercel.app'); // Temporary workaround to look at frontend URL
// TODO: setup REDACT_APP_ENV to 'uat' in the appropriate place, to make this check consistent with the others
export const isUat = window.location.origin.includes('octiv-uat.vercel.app');

export const isCleanSunrise = window.location.origin.includes(
  'octiv-clean-sunrise.vercel.app'
);
export const isMythicBreeze = window.location.origin.includes(
  'octiv-mythic-breeze.vercel.app'
);
export const isLivelyDusk = window.location.origin.includes(
  'octiv-lively-dusk.vercel.app'
);
export const isDemoAccount = window.location.origin.includes(
  'octiv-demo.vercel.app'
);

export const isSandbox = window.location.origin.includes(
  'octiv-sandbox.vercel.app'
);

export const isDevelopment = !isProduction && !isStaging && !isUat;
export const domain = isProduction
  ? 'https://app.octivfitness.com'
  : isUat
  ? 'https://octiv-uat.vercel.app'
  : isSandbox
  ? 'https://octiv-sandbox.vercel.app'
  : isLivelyDusk
  ? 'https://octiv-lively-dusk.vercel.app'
  : isDemoAccount
  ? 'https://octiv-demo.vercel.app'
  : isCleanSunrise
  ? 'https://octiv-clean-sunrise.vercel.app'
  : isMythicBreeze
  ? 'https://octiv-mythic-breeze.vercel.app'
  : isDevelopment
  ? 'https://octiv-d.vercel.app'
  : isStaging
  ? 'https://octiv-s.vercel.app'
  : 'http://localhost:3000';

export const apiBaseUrl = isUat
  ? 'https://api.uat.octivfitness.com'
  : isSandbox
  ? 'https://api.sandbox.octivfitness.com'
  : isLivelyDusk
  ? 'https://api.lively-dusk.octivfitness.com'
  : isMythicBreeze
  ? 'https://api.mythic-breeze.octivfitness.com'
  : isCleanSunrise
  ? 'https://api.clean-sunrise.octivfitness.com'
  : isDemoAccount
  ? 'https://api.demo.octivfitness.com'
  : isDevelopment
  ? 'https://api.dev.octivfitness.com'
  : isStaging
  ? 'https://api.stg.octivfitness.com'
  : 'https://api.octivfitness.com';

export const urlPrivacyPolicy = 'https://octivfitness.com/privacy-policy';

export const urlSupport = 'https://octivfitness.com/contact';

export const measures = [
  {
    measureId: 14,
    description: i18n.t('timeSpeed'),
    fields: [
      {
        short: 'min',
        long: i18n.t('minutes'),
        max: undefined,
        decimal: false,
      },
      {
        short: 'sec',
        long: i18n.t('seconds'),
        max: 59,
        decimal: false,
      },
    ],
  },
  {
    measureId: 2,
    description: i18n.t('timeEndurance'),
    fields: [
      {
        short: 'min',
        long: i18n.t('minutes'),
        max: undefined,
        decimal: false,
      },
      {
        short: 'sec',
        long: i18n.t('seconds'),
        max: 59,
        decimal: false,
      },
    ],
  },
  {
    measureId: 1,
    description: i18n.t('kilograms'),
    fields: [
      {
        short: 'kg',
        long: i18n.t('kilograms'),
        max: undefined,
        decimal: true,
      },
    ],
  },
  {
    measureId: 15,
    description: i18n.t('pounds'),
    fields: [
      {
        short: 'lbs',
        long: i18n.t('pounds'),
        max: undefined,
        decimal: true,
      },
    ],
  },
  {
    measureId: 10,
    description: i18n.t('calories'),
    fields: [
      {
        short: 'cal',
        long: i18n.t('calories'),
        max: undefined,
        decimal: false,
      },
    ],
  },
  {
    measureId: 5,
    description: i18n.t('rounds'),
    fields: [
      {
        short: 'rnds',
        long: i18n.t('rounds'),
        max: undefined,
        decimal: false,
      },
    ],
  },
  {
    measureId: 4,
    description: i18n.t('repetitions'),
    fields: [
      {
        short: 'reps',
        long: i18n.t('repetitions'),
        max: undefined,
        decimal: false,
      },
    ],
  },
  {
    measureId: 9,
    description: i18n.t('roundsRepetitions'),
    fields: [
      {
        short: 'rnds',
        long: i18n.t('rounds'),
        max: undefined,
        decimal: false,
      },
      {
        short: 'reps',
        long: i18n.t('repetitions'),
        max: undefined,
        decimal: false,
      },
    ],
  },
  {
    measureId: 6,
    description: i18n.t('metres'),
    fields: [
      {
        short: 'm',
        long: i18n.t('metres'),
        max: undefined,
        decimal: false,
      },
    ],
  },
  {
    measureId: 11,
    description: i18n.t('metresCentimetres'),
    fields: [
      {
        short: 'm',
        long: i18n.t('metres'),
        max: undefined,
        decimal: false,
      },
      {
        short: 'cm',
        long: i18n.t('centimetres'),
        max: 99,
        decimal: false,
      },
    ],
  },
  {
    measureId: 7,
    description: i18n.t('kilometres'),
    fields: [
      {
        short: 'km',
        long: i18n.t('kilometres'),
        max: undefined,
        decimal: false,
      },
    ],
  },
  {
    measureId: 8,
    description: i18n.t('laps'),
    fields: [
      {
        short: 'laps',
        long: i18n.t('laps'),
        max: undefined,
        decimal: false,
      },
    ],
  },
  {
    measureId: 13,
    description: i18n.t('noMeasure'),
    fields: [
      {
        short: 'none',
        long: i18n.t('noMeasure'),
        max: undefined,
        decimal: false,
      },
    ],
  },
];

export const daysOfMonthNumberOptions = [
  { label: '1st', value: 1 },
  { label: '2nd', value: 2 },
  { label: '3rd', value: 3 },
  { label: '4th', value: 4 },
  { label: '5th', value: 5 },
  { label: '6th', value: 6 },
  { label: '7th', value: 7 },
  { label: '8th', value: 8 },
  { label: '9th', value: 9 },
  { label: '10th', value: 10 },
  { label: '11th', value: 11 },
  { label: '12th', value: 12 },
  { label: '13th', value: 13 },
  { label: '14th', value: 14 },
  { label: '15th', value: 15 },
  { label: '16th', value: 16 },
  { label: '17th', value: 17 },
  { label: '18th', value: 18 },
  { label: '19th', value: 19 },
  { label: '20th', value: 20 },
  { label: '21st', value: 21 },
  { label: '22nd', value: 22 },
  { label: '23rd', value: 23 },
  { label: '24th', value: 24 },
  { label: '25th', value: 25 },
  { label: '26th', value: 26 },
  { label: '27th', value: 27 },
  { label: '28th', value: 28 },
];

export const daysOfMonthStringOptions = [
  ...daysOfMonthNumberOptions.map((dayOfMonth) => ({
    label: dayOfMonth.label,
    value: dayOfMonth.value.toString(),
  })),
  { label: i18n.t('secondLastDayMonth'), value: 'second_last_day_of_month' },
  { label: i18n.t('lastDayMonth'), value: 'last_day_of_month' },
];

export const thresholds = [
  // { label: i18n.t('noThreshold'), value: 1 },
  { label: `30${i18n.t('min')}`, value: 30 },
  { label: '1h', value: 60 },
  { label: `1h 30${i18n.t('min')}`, value: 90 },
  { label: '2h', value: 120 },
  { label: `2h 30${i18n.t('min')}`, value: 150 },
  { label: '3h', value: 180 },
  { label: `3h 30${i18n.t('min')}`, value: 210 },
  { label: '4h', value: 240 },
  { label: `4h 30${i18n.t('min')}`, value: 270 },
  { label: '5h', value: 300 },
  { label: `5h 30${i18n.t('min')}`, value: 330 },
  { label: '6h', value: 360 },
  { label: `6h 30${i18n.t('min')}`, value: 390 },
  { label: '7h', value: 420 },
  { label: `7h 30${i18n.t('min')}`, value: 450 },
  { label: '8h', value: 480 },
  { label: `8h 30${i18n.t('min')}`, value: 510 },
  { label: '9h', value: 540 },
  { label: `9h 30${i18n.t('min')}`, value: 570 },
  { label: '10h', value: 600 },
  { label: `10h 30${i18n.t('min')}`, value: 630 },
  { label: '11h', value: 660 },
  { label: `11h 30${i18n.t('min')}`, value: 690 },
  { label: '12h', value: 720 },
  { label: `1 ${i18n.t('day').toLowerCase()}`, value: 1440 },
  { label: `2 ${i18n.t('days').toLowerCase()}`, value: 2880 },
  { label: `3 ${i18n.t('days').toLowerCase()}`, value: 4320 },
];

export const genderOptions = [
  { label: i18n.t('male'), value: 1 },
  { label: i18n.t('female'), value: 2 },
  { label: i18n.t('notSpecified'), value: 3 },
];

export const paymentGateways = {
  NO_PAYMENT: 1,
  SAGE: 2,
  THREE_PEAKS: 3,
  NETCASH: 4,
  GO_CARDLESS: 5,
  STRIPE: 6,
  PAYSTACK: 7,
  SEPA: 8,
  STRIPE_CONNECT: 9,
  PAYFAST: 10,
};

export const paymentTypes = {
  CASH: 1,
  DEBIT_ORDER: 2,
  NONE: 4,
  UPFRONT: 5,
  ONLINE: 6,
  CARD: 7,
};

export const userStatus = {
  PENDING: 1,
  ACTIVE: 2,
  SUSPENDED: 3,
  DEACTIVATED: 4,
  READY_FOR_TRANSFER: 5,
  ON_HOLD: 6,
};

export const userTypeId = {
  SUPER_ADMIN: 1,
  HEAD_COACH: 2,
  GYM_COACH: 3,
  GYM_MEMBER: 4,
  BOX_ADMIN: 5,
  BOX_FACILITY_ADMIN: 6,
  REGION_ADMIN: 7,
  LOCATION_CHECK_IN: 8,
  LEAD_MEMBER: 9,
  DISCOVERY_ADMIN: 10,
};

export const acsPartners = {
  SEAM: 1,
};

export const acsMethods = {
  CODE: 'code',
  CARD: 'card',
  MOBILE: 'mobile_key',
};

export const AcsEvent = {
  USER_ADDED: 'access-control.system.user-added',
  USER_ADDED_TO_GROUP: 'access-control.system.user-added-to-group',
  USER_DELETED: 'access-control.system.user-deleted',
  USER_REMOVED_FROM_GROUP: 'access-control.system.user-removed-from-group',
  USER_SUSPENDED: 'access-control.system.user-suspended',
  USER_UNSUSPENDED: 'access-control.system.user-unsuspended',
  SYNC_COMPLETE: 'access-control.system.sync-complete',
  CREDENTIALS_CREATED: 'access-control.credential-created',
  CREDENTIALS_DELETED: 'access-control.credential-deleted',
};

export const tagsTypes = {
  PACKAGE: 'package',
  PAYMENT_PROCESSOR: 'payment_processor',
  LOCATION: 'location',
};

export const additionalFeatures = {
  ACCESS_CONTROL: 'access_control',
};

export const locationAdditionalFeatureOptions = [
  {
    label: i18n.t('accessControl'),
    value: additionalFeatures.ACCESS_CONTROL,
  },
];

export const passwordMinLength = 8;

export const milestones = [25, 50, 75, 100, 150, 200, 500, 1000];

export const paymentStatuses = {
  UNPAID: 'unpaid',
  PENDING: 'pending',
  PAID: 'paid',
};

export const languageOptions = [
  { label: `${i18n.t('english')} (EN)`, value: 'en' },
  { label: `${i18n.t('french')} (FR)`, value: 'fr' },
  { label: `${i18n.t('german')} (DE)`, value: 'de' },
];
