import { Formik } from 'formik';
import { Button, Col, Field, Row, Text } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues = {}, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        signUpBillingDayOptions: initialValues.signUpBillingDayOptions || [],
        signUpPaymentOptions: initialValues.signUpPaymentOptions || [],
        signUpDebitDayOptions: initialValues.signUpDebitDayOptions || [],
        signUpRequiredFields: initialValues.signUpRequiredFields || [],
        isSignUpUseContracts: initialValues.isSignUpUseContracts || 0,
        isSignUpUseWaivers: initialValues.isSignUpUseWaivers || 0,
        proRateStrategy: initialValues.proRateStrategy || 'manual',
        isMandatoryPassportOrId: initialValues.isMandatoryPassportOrId,
        isMandatoryAddress: initialValues.isMandatoryAddress,
      }}
      validationSchema={Yup.object().shape({
        signUpBillingDayOptions: Yup.array()
          .required(t('required'))
          .min(1, t('required')),
        signUpPaymentOptions: Yup.array()
          .required(t('required'))
          .min(1, t('required')),
        signUpDebitDayOptions: Yup.array()
          .required(t('required'))
          .min(1, t('required')),
        signUpRequiredFields: Yup.array(),
        isSignUpUseContracts: Yup.bool(),
        isSignUpUseWaivers: Yup.bool(),
        proRateStrategy: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit, dirty }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                isArray
                isCheckbox
                label={t('selectPaymentMethodsForSignUpWidget')}
                name='signUpPaymentOptions'
                options={[
                  { label: t('cashEftOnlinePayment'), value: '1' },
                  { label: t('debitOrder'), value: '2' },
                  { label: t('cardSubscription'), value: '7' },
                ]}
              />
            </Col>

            <Col>
              <Field
                isArray
                isCheckbox
                label={t('selectDebitDayOptionsForSignUpWidget')}
                name='signUpDebitDayOptions'
                options={[
                  { label: '1st', value: '1' },
                  { label: '5th', value: '6' },
                  { label: '15th', value: '2' },
                  { label: '25th', value: '3' },
                  { label: '27th', value: '5' },
                  { label: '28th', value: '7' },
                  { label: t('lastDayMonth'), value: '4' },
                ]}
              />
            </Col>

            <Col>
              <Field
                isHorizontal
                isRadio
                label={t('doesLocationUseContracts')}
                name='isSignUpUseContracts'
                options={[
                  { label: t('yes'), value: 1 },
                  { label: t('no'), value: 0 },
                ]}
              />
            </Col>

            <Col>
              <Field
                isHorizontal
                isRadio
                label={t('doesLocationUseWaivers')}
                name='isSignUpUseWaivers'
                options={[
                  { label: t('yes'), value: 1 },
                  { label: t('no'), value: 0 },
                ]}
              />
            </Col>

            <Col>
              <Text color='grey1'>{t('isMandatoryHeading')}</Text>

              <Field
                isArray
                isCheckbox
                name='signUpRequiredFields'
                options={[
                  {
                    label: t('memberAddress'),
                    value: 'address',
                  },
                  {
                    label: t('socialSecurityNumber'),
                    value: 'id_number',
                  },
                ]}
              />
            </Col>

            <Col>
              <Field
                isRadio
                label={t('whatProrateStrategyDoesTenantUse')}
                name='proRateStrategy'
                options={[
                  {
                    label: t('prorateStrategyManual'),
                    value: 'manual',
                  },
                  {
                    label: t('prorateStrategyAutomatic'),
                    value: 'automatic',
                  },
                ]}
              />
            </Col>

            <Col>
              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('submit')}
                type='submit'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
