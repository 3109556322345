import axios from 'axios';
import rename from 'deep-rename-keys';
import i18n from 'octiv-config/i18n';
import qs from 'qs';

import { apiBaseUrl, isDevelopment } from './Constants';
import { isLocalStorageAvailable } from './Functions';

export const request = async ({
  onSuccessToast,
  useSuccessToastCommon,
  omitAuthToken,
  returnFullResponse = false, // Optional param for now - Tech debt, need to refine the filtering of the responses on this file
  ...config
}) => {
  try {
    let switchUserToken = null;
    let jwt = null;

    if (isLocalStorageAvailable()) {
      switchUserToken = await sessionStorage.getItem('switchUserToken');
      jwt = await localStorage.getItem('jwt');
    }

    const response = await axios({
      baseURL: apiBaseUrl,
      timeout: 120000,
      ...config,
      paramsSerializer(params) {
        return qs.stringify(params, { encode: false });
      },
      headers: {
        'Content-Type': 'application/json',
        'X-CamelCase': true,
        // TODO: remove before merging
        'bypass-tunnel-reminder': '*',
        ...((switchUserToken !== null || jwt !== null) && {
          Authorization: omitAuthToken
            ? undefined
            : `Bearer ${switchUserToken || jwt}`,
        }),
        ...config.headers,
      },
    });

    if (returnFullResponse) {
      return response.data;
    }

    let responseData = null;
    let responsePaging;

    if (response && response.data && response.data.data) {
      responseData = response.data.data;
      const meta = response.data?.meta;

      if (meta?.currentPage) {
        responsePaging = {
          perPage: meta.perPage,
          currentPage: meta.currentPage,
          numberOfResults: meta.total,
          numberOfPages: meta.lastPage,
          currentPageOffsetStart: meta.from,
          currentPageOffsetEnd: meta.to,
          nextPage:
            meta.currentPage < meta.lastPage ? meta.currentPage + 1 : undefined,
          previousPage: meta.currentPage > 0 ? meta.currentPage - 1 : undefined,
        };
      }
    } else if (response && response.data !== null) {
      responseData = response.data;
    }

    let validatedData = responseData;
    if (responseData && typeof responseData === 'object') {
      validatedData = rename(responseData, (param) => {
        if (param === 'isLimitInterFacilityBookings')
          return 'isLimitInterLocationBookings';
        if (param === 'headCoachName') return 'ownerName';
        if (param === 'headCoachSurname') return 'ownerSurname';
        if (param === 'headCoachGenderId') return 'ownerGenderId';
        if (param === 'headCoachEmail') return 'ownerEmail';
        if (param === 'headCoachMobile') return 'ownerMobile';
        if (param === 'headCoachDateOfBirth') return 'ownerDateOfBirth';
        if (param === 'coach') return 'trainer';
        if (param === 'coachId') return 'trainerId';
        if (param === 'supportingCoach') return 'supportingTrainer';
        if (param === 'supportingCoachId') return 'supportingTrainerId';
        if (param === 'classCoach') return 'classTrainer';
        if (param === 'assignedCoach') return 'assignedTrainer';
        if (param === 'assignedCoachId') return 'assignedTrainerId';
        if (param === 'isDisplayCoachName') return 'isDisplayTrainerName';
        if (param === 'cancelledByCoach') return 'cancelledByTrainer';
        if (param === 'coachesClassFee') return 'trainersClassFee';
        if (param === 'coachesSessionFee') return 'trainersSessionFee';
        if (param === 'coachNotes') return 'trainerNotes';
        if (param === 'box') return 'tenant';
        if (param === 'boxId') return 'tenantId';
        if (param === 'boxIds') return 'tenantIds';
        if (param === 'boxesCount') return 'tenantsCount';
        if (param === 'boxDetails') return 'tenantDetails';
        if (param === 'boxStatusId') return 'tenantStatusId';
        if (param === 'boxBillingCurrencyId') return 'tenantBillingCurrencyId';
        if (param === 'facilityAccessPrivileges')
          return 'locationAccessPrivileges';
        if (param === 'boxFacilities' || param === 'facilities')
          return 'locations';
        if (param === 'boxFacilitiesCount') return 'locationsCount';
        if (param === 'boxFacility') return 'location';
        if (param === 'boxFacilityId') return 'locationId';
        if (param === 'boxFacilityIds') return 'locationIds';
        if (param === 'defaultBoxFacility') return 'defaultLocation';
        if (param === 'boxFacilityCategory') return 'locationCategory';
        if (param === 'boxFacilityCategoryId') return 'locationCategoryId';
        if (param === 'boxFacilityPaymentGatewayId')
          return 'locationPaymentGatewayId';
        if (param === 'boxFacilityAccessPrivileges')
          return 'locationAccessPrivileges';
        if (param === 'boxSettings') return 'tenantSettings';
        if (param === 'boxFacilitiesSettings') return 'locationsSettings';
        if (param === 'username') return 'email';
        if (param === 'startingOn') return 'startDate';
        if (param === 'endingOn') return 'endDate';
        if (param === 'wod') return 'workout';
        if (param === 'wodId') return 'workoutId';
        if (param === 'wodExercises') return 'workoutExercises';
        if (param === 'wodExercise') return 'workoutExercise';
        if (param === 'wodCaptureId') return 'workoutResultId';
        if (param === 'captureId') return 'resultId';
        if (param === 'captureExercises') return 'resultExercises';
        if (param === 'wodCaptureExercises') return 'resultExercises';
        if (param === 'captureLikes') return 'resultLikes';
        if (param === 'captureComments') return 'resultComments';
        if (param === 'membership') return 'userTenant';
        if (param === 'memberships') return 'userTenants';
        if (param === 'membershipId') return 'userTenantId';
        if (param === 'membershipIds') return 'userTenantIds';
        if (param === 'headCoachUserTenant') return 'headTrainerUserTenant';
        if (
          param === 'supportingCoachUserTenant' ||
          param === 'supportingCoachMembership'
        )
          return 'supportingTrainerUserTenant';
        if (param === 'hasMemberUserTenant') return 'hasMemberUserTenant';
        if (param === 'hasStaffUserTenant') return 'hasStaffUserTenant';

        return param;
      });
    }

    responseData = validatedData;

    if (responsePaging) {
      responseData = { data: validatedData, paging: responsePaging };
    }

    if (isDevelopment) {
      console.log(config.url, responseData);
    }

    return responseData;
  } catch ({ response, message }) {
    if (message === 'cancelled') {
      return null;
    }

    let errorResponse;

    if (response?.data?.errors || response?.data?.message) {
      errorResponse = {
        status: response.status,
        message: i18n.t('error'),
        messageBody: response?.data?.errors || response?.data?.message,
      };
    } else {
      // 1504 is a custom error code for an Axios timeout
      // 1500 is a custom error code for a bad response
      errorResponse = {
        status: !response ? 1504 : 1500,
        message: i18n.t('unexpectedError'),
        messageBody: i18n.t('pleaseTryAgainLater'),
      };
    }

    const hasRequestedInvalidPage =
      errorResponse.messageBody === 'Page Not Found';
    const hasInactiveAccount =
      errorResponse.messageBody ===
      'Your account is not in an active state, please contact your studio for assistance.';

    const hasInvalidToken =
      errorResponse.message === 'Invalid JWT Token' ||
      errorResponse.message === 'Expired JWT Token' ||
      errorResponse.message === 'JWT Token not found';
    const hasNewEmail = errorResponse.message.includes(
      'Unable to load an user with property "username"'
    );

    if (hasInvalidToken) {
      errorResponse.message = i18n.t('invalidAuthenticationToken');
    }

    if (hasNewEmail) {
      errorResponse.message = i18n.t('invalidAuthenticationEmail');
    }

    if (hasInactiveAccount || hasNewEmail || hasInvalidToken) {
      // onLogoui18n.t();
    } else if (hasRequestedInvalidPage) {
      // } else if (hasRequestedInvalidPage && !!onPageNotFound) {
      // onPageNotFound();
      return null;
    }

    throw new Error(JSON.stringify(errorResponse));
  }
};
