import { Formik } from 'formik';
import { Button, Col, Field, Row, Text } from 'octiv-components';
import { genderOptions } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  demo,
  isLoading,
  locationOptions,
  isRequestDemo,
  onClickTerms,
  isSignUpUseWaivers,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      initialValues={{
        name: undefined,
        surname: undefined,
        email: undefined,
        mobile: undefined,
        genderId: undefined,
        locationId:
          locationOptions.length === 1 ? locationOptions[0]?.value : undefined,
        notes: undefined,
        ...(isRequestDemo &&
          isSignUpUseWaivers && { termsAndConditionsForWaiver: false }),
        gRecaptchaResponse: undefined,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        surname: Yup.string().required(t('required')),
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
        mobile: Yup.string().required(t('required')),
        genderId: Yup.string(),
        locationId: Yup.string().required(t('required')),
        notes: Yup.string(),
        ...(isRequestDemo &&
          isSignUpUseWaivers && {
            termsAndConditionsForWaiver: Yup.bool()
              .oneOf([true], t('termsWaiverMustBeAccepted'))
              .required(t('required')),
          }),
        gRecaptchaResponse: Yup.string().required(t('required')).nullable(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                label={t('firstName')}
                name='name'
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                label={t('surname')}
                name='surname'
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                label={t('emailAddress')}
                name='email'
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                label={t('mobileNumber')}
                name='mobile'
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                label={t('gender')}
                name='genderId'
                options={genderOptions}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col>
              <Field
                hasAlternateBackground
                as='textarea'
                label={t('notes')}
                name='notes'
              />
            </Col>

            {isRequestDemo && isSignUpUseWaivers && (
              <Col>
                <Field
                  isCheckbox
                  helper={t('digitalSignature')}
                  name='termsAndConditionsForWaiver'
                  options={[{ label: t('iAcceptTheTermsOfThe'), value: true }]}
                  renderRight={() => (
                    <Text
                      isBold
                      color='primary'
                      ml={1}
                      mt='1px'
                      textDecoration='underline'
                      onClick={onClickTerms}
                    >
                      {t('waiver')}
                    </Text>
                  )}
                />
              </Col>
            )}

            <Col>
              <Field isReCaptcha name='gRecaptchaResponse' />
            </Col>

            <Col>
              <Button isLoading={isLoading} text={t('submit')} type='submit' />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
