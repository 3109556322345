import { Container } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { paymentGateways } from 'octiv-utilities/Constants';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Adhoc from './adhoc';
import GoCardless from './goCardless';
import LocationBilling from './locationBilling';
import Netcash from './netcash';
import Sepa from './sepa';
import StripeConnect from './stripeConnect';
import ThreePeaks from './threePeaks';

export default () => {
  const { t } = useTranslation();

  const {
    tenant: { locations, isSepa },
  } = useActiveUserTenant();

  const hasLocationBillingGateway = !!locations.find(
    (item) => item.billingPaymentGatewayId !== paymentGateways.NO_PAYMENT
  );

  const hasLocationGoCardless = !!locations.find(
    (item) => item.paymentGatewayId === paymentGateways.GO_CARDLESS
  );

  const hasLocationNetcash = !!locations.find(
    (item) => item.paymentGatewayId === paymentGateways.NETCASH
  );

  const hasLocationThreePeaks = !!locations.find(
    (item) => item.paymentGatewayId === paymentGateways.THREE_PEAKS
  );

  const hasLocationStripeConnect = !!locations.find(
    (item) => item.paymentGatewayId === paymentGateways.STRIPE_CONNECT
  );

  return (
    <Container
      appBarProps={{
        title: t('paymentGateways'),
        breadcrumbs: [t('settings')],
      }}
    >
      {hasLocationBillingGateway && <LocationBilling />}

      {hasLocationGoCardless && <GoCardless />}

      {isSepa && <Sepa />}

      {hasLocationNetcash && <Netcash />}

      {hasLocationThreePeaks && <ThreePeaks />}

      {hasLocationStripeConnect && <StripeConnect />}

      {!hasLocationStripeConnect && (
        <Adhoc
          hasLocationGoCardless={hasLocationGoCardless}
          hasLocationNetcash={hasLocationNetcash}
        />
      )}
    </Container>
  );
};
