import moment from 'moment';
import { Box, Image, ProcessingSpinner, Text, Tooltip } from 'octiv-components';
import { useMediaQuery } from 'octiv-hooks';
import { useClassDatesFindByIdClassBookingDetails } from 'octiv-hooks/requests/ClassDates';
import {
  getBookingsData,
  getDateFromTime,
  getEventString,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useState } from 'react';

const CalendarEvent = ({
  boxContainerProps,
  data = {},
  isHorizontal = false,
  isPast,
  showTime,
}) => {
  const { xsDown } = useMediaQuery();
  const [bookings, setBookings] = useState([]);

  const {
    bookingsCount,
    name,
    endTime,
    id,
    limit,
    startTime,
    instructor,
    waitingListCount,
    class: classData,
  } = data;
  const isVisibleTime = showTime && !!startTime;

  const { isFetching: isFetchingBookings, refetch: getBookingsRequest } =
    useClassDatesFindByIdClassBookingDetails(
      {
        id,
      },
      {
        // NOTE: added && false, to prevent unnecessarily prefetching this when it needs to be fetched on hover of a class anyway
        enabled: !!id && bookingsCount > 0 && false,
        onSuccess: (response) => {
          setBookings(
            getBookingsData({ allBookings: response?.data || response })
              .booked || []
          );
        },
      }
    );

  if (!id) return null;

  const attendancePercentage =
    (bookingsCount / limit) * 100 > 100 ? 100 : (bookingsCount / limit) * 100;

  const textProps = isHorizontal
    ? { mr: 2, ...(xsDown ? { variant: 'caption' } : {}) }
    : { variant: 'caption' };

  return (
    <Box
      hasRadius
      bg='grey7'
      height='100%'
      position='relative'
      {...boxContainerProps}
    >
      {!xsDown && (
        <Tooltip
          delayShow={500}
          placement='top'
          render={() => (
            <Box maxWidth={250}>
              <Text isBold variant='caption'>
                {name}
              </Text>

              <Text color='grey1' variant='caption'>
                {`${startTime.slice(0, -3)} - ${endTime.slice(0, -3)} (${moment(
                  getDateFromTime({ time: endTime })
                ).diff(
                  moment(getDateFromTime({ time: startTime })),
                  'minutes'
                )}m)`}
              </Text>

              {isFetchingBookings ? (
                <ProcessingSpinner mt={2} />
              ) : (
                <Box isFlex flexWrap='wrap'>
                  {bookings.map(({ user, nonMember }) => {
                    const person = user || nonMember;

                    return (
                      <Box
                        isFlex
                        alignItems='center'
                        key={person?.id}
                        mr={4}
                        mt={2}
                      >
                        <Image
                          hasRadius
                          usePlaceholderUser
                          isLead={person?.type === 'leadMember'}
                          isNonMember={person?.type === 'nonMember'}
                          mr={1}
                          size={5}
                          sizeRadius={1}
                          src={person?.image}
                        />

                        <Text color='grey1' variant='tiny'>
                          {getFullName(person || {})}
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          )}
          trigger='hover'
          triggerProps={{
            display: 'initial',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            position: 'absolute',
            zIndex: 2,
          }}
          onVisibilityChange={(isVisible) =>
            isVisible ? getBookingsRequest() : null
          }
        />
      )}

      <Box
        hasRadius
        bg='grey6'
        borderColor={
          classData?.settings?.colours?.border
            ? classData?.settings?.colours?.border
            : attendancePercentage === 100
            ? 'danger'
            : attendancePercentage > 74
            ? 'warning'
            : 'grey4'
        }
        borderStyle='solid'
        borderWidth={classData?.settings?.colours?.border ? '3px' : '1px'}
        className='hide-scrollbar'
        height='100%'
        opacity={isPast ? 0.75 : 1}
        overflow='scroll'
        position='relative'
      >
        <Box
          alignItems={isHorizontal ? 'center' : undefined}
          isFlex={isHorizontal}
          minHeight={isHorizontal ? 14 : undefined}
          position='relative'
          px={2}
          py={1}
          zIndex={1}
        >
          {isVisibleTime && (
            <Text
              isBold
              {...textProps}
              variant={isHorizontal ? (xsDown ? 'caption' : undefined) : 'tiny'}
            >
              {getEventString({ startTime, endTime })}
            </Text>
          )}

          <Text
            color='primary'
            mt={isVisibleTime && !isHorizontal ? '2px' : undefined}
            {...textProps}
          >
            {name}
          </Text>

          <Text color='grey1' whiteSpace='pre-line' {...textProps}>
            {!!limit &&
              `${bookingsCount}/${limit}${
                waitingListCount > 0
                  ? ` + ${waitingListCount} WL${isHorizontal ? ' - ' : `\n`}`
                  : ' - '
              }`}

            {getFullName({ ...instructor, isShortened: true })}
          </Text>
        </Box>

        {!!limit && (
          <Box
            hasRadius
            bottom={0}
            left={0}
            overflow='hidden'
            position='absolute'
            right={0}
            top={0}
            zIndex={0}
          >
            <Box
              bg={
                attendancePercentage === 100
                  ? 'danger20'
                  : attendancePercentage > 74
                  ? 'warning20'
                  : 'grey5'
              }
              bottom={0}
              left={0}
              position='absolute'
              top={0}
              width={`${attendancePercentage}%`}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CalendarEvent;
