import { Box, Icon, Image, Table, Text } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, onClickAction, loggedInUserTypeId, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('name'),
        Cell: ({ row: { original } }) => {
          return (
            <Box isFlex alignItems='center'>
              <Image
                hasRadius
                usePlaceholderUser
                mr={2}
                size={9}
                src={original?.image}
              />

              <Text as='span'>{original?.name}</Text>
            </Box>
          );
        },
      },
      {
        Header: t('dateAdded'),
        accessor: 'createdAt',
      },
      {
        Header: t('status'),
        Cell: ({ row: { original } }) => (
          <Box isFlex alignItems='center' minHeight={9}>
            <Icon
              color={original?.properties?.online ? 'success' : 'danger'}
              customSize='0.7rem'
              mr={1}
              name='circle'
              px={1}
            />
            {/* Translations */}
            <Text isFlex>
              <Text isFlex>
                {original?.properties?.online ? t('online') : t('offline')}
              </Text>
            </Text>
          </Box>
        ),
      },
      {
        Header: t('capabilities'),
        Cell: ({ row: { original } }) => {
          const capabilities = [];

          if (original.capabilities?.canRemotelyUnlock) {
            capabilities.push(t('remoteUnlock'));
          }
          if (original.capabilities?.canProgramOnlineAccessCodes) {
            capabilities.push(t('accessCodes'));
          }

          return <Text>{capabilities.join(', ')}</Text>;
        },
      },

      // {
      //   Header: t('actions'),
      //   accessor: 'id',
      //   Cell: ({ row: { original } }) => {
      //     return (
      //       <Tooltip
      //         stopPropagation
      //         options={[{ label: t('viewEdit'), value: 'viewEdit' }]}
      //         onClick={({ value }) => {
      //           onClickAction({
      //             action: value,
      //             id: original.id,
      //             locationId: original.locationId,
      //           });
      //         }}
      //       />
      //     );
      //   },
      // },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
