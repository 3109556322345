import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { paymentGateways } from 'octiv-utilities/Constants';
import { getPaymentGatewayName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isFetchingRegions,
  isFetchingTenants,
  tenants,
  regions,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        regionId: Yup.string(),
        tenantId: Yup.string(),
        healthProviderId: Yup.string(),
        search: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingRegions}
                label={t('region')}
                name='regionId'
                options={[
                  { label: t('all'), value: undefined },
                  ...regions.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingTenants}
                label={t('tenant')}
                name='tenantId'
                options={[
                  { label: t('all'), value: undefined },
                  ...tenants.map((item) => ({
                    label: item.name,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('healthProvider')}
                name='healthProviderId'
                options={[
                  { label: t('all'), value: undefined },
                  { label: 'Discovery Vitality', value: 1 },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('billingPaymentGateway')}
                name='billingPaymentGatewayId'
                options={[
                  { label: t('all'), value: undefined },
                  {
                    label: 'No Payment Gateway',
                    value: paymentGateways.NO_PAYMENT,
                  },
                  {
                    label: getPaymentGatewayName({
                      id: paymentGateways.PAYFAST,
                    }),
                    value: paymentGateways.PAYFAST,
                  },
                  {
                    label: getPaymentGatewayName({
                      id: paymentGateways.PAYSTACK,
                    }),
                    value: paymentGateways.PAYSTACK,
                  },
                  {
                    label: getPaymentGatewayName({
                      id: paymentGateways.STRIPE_CONNECT,
                    }),
                    value: paymentGateways.STRIPE_CONNECT,
                  },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                hasAlternateBackground
                hasSubmitButtonSearch
                label={t('search')}
                name='search'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
