import { Container, Divider } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging } from 'octiv-hooks';
import {
  useFinancesCreateStripeConnectDebitOrderSetupIntentLink,
  useFinancesDeleteStripeConnectSetupIntent,
  useFinancesfindStripeConnectDebitOrderMembers,
  useFinancesUpdateStripeConnectSendSetupIntentLink,
} from 'octiv-hooks/requests/Finances';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
  });

  const [paging, setPaging] = usePaging();
  const [userId, setUserId] = useState(undefined);
  const [clickAction, setClickAction] = useState('');

  const { data: debitOrderMembers, isFetching: isFetchingDebitOrderMembers } =
    useFinancesfindStripeConnectDebitOrderMembers({
      filter: query,
      paging,
    });

  const {
    isLoading: isCreatingSetupIntentLink,
    mutate: postSetupIntentLinkRequest,
  } = useFinancesCreateStripeConnectDebitOrderSetupIntentLink({
    enabled: !!userId && clickAction === 'openLink',
    onSuccess: (response) => {
      setUserId(null);
      setClickAction('');
      window.open(response);
    },
  });

  const {
    isLoading: isUpdatingSetupIntentSendLink,
    mutate: putSetupIntentSendLinkRequest,
  } = useFinancesUpdateStripeConnectSendSetupIntentLink({
    enabled: !!userId && clickAction === 'sendLink',
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: () => {
      setUserId(null);
      setClickAction('');
    },
  });

  const { isLoading: isDeletingSetupIntent, mutate: deleteSetupIntentRequest } =
    useFinancesDeleteStripeConnectSetupIntent({
      meta: {
        useOnSuccessToast: true,
      },
    });

  const onClickAction = ({ action, id, token, viewMandateLink }) => {
    switch (action) {
      case 'view':
        setClickAction('view');
        setClickAction('');
        window.open(viewMandateLink);
        break;

      case 'sendLink':
        setClickAction('sendLink');
        putSetupIntentSendLinkRequest({
          userIds: [id],
        });

        break;

      case 'openLink':
        setClickAction('openLink');
        postSetupIntentLinkRequest({
          userId: id,
          locationId: selectedLocation.id,
        });
        break;

      case 'delete':
        if (window.confirm(t('areYouSureProceed'))) {
          deleteSetupIntentRequest({ token });
        }
        break;

      default:
        break;
    }
  };

  return (
    <Container
      appBarProps={{
        title: t('directDebitMandates'),
        breadcrumbs: [t('accounts')],
      }}
      isLoading={
        isFetchingDebitOrderMembers ||
        isCreatingSetupIntentLink ||
        isUpdatingSetupIntentSendLink ||
        isDeletingSetupIntent
      }
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptions}
        onSubmit={setQuery}
      />

      <Divider pb={4} />

      <Table
        data={debitOrderMembers?.data || []}
        isLoading={isFetchingDebitOrderMembers}
        paging={debitOrderMembers?.paging}
        setPaging={setPaging}
        onClickAction={onClickAction}
      />
    </Container>
  );
};
