import { useField, useFormikContext } from 'formik';
import { Box, Button, Dropzone, Editor, Text } from 'octiv-components';
import ReCaptcha from 'octiv-config/ReCaptcha';
import { useMediaQuery } from 'octiv-hooks';
import React, { useContext, useState } from 'react';
import { ChromePicker } from 'react-color';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import Icon from '../icon';
import DateInput from './dateInput';
import RadioCheckbox from './RadioCheckbox';
import SelectInput from './SelectInput';
import AsyncSelectInput from './SelectInputAsync';
import TextInput from './TextInput';

const Field = ({
  isColor,
  boxContainerProps,
  buttonSubmitProps,
  canSetEmptyStringNull,
  canSetEmptyStringUndefined,
  canShowErrorUntouched,
  canSubmitOnChange,
  doFinally,
  hasAlternateBackground,
  hasSubmitButton,
  hasSubmitButtonSearch,
  helper,
  iconRightProps,
  isArray,
  isCheckbox,
  isDate,
  hasCancelIcon = true,
  isDisabled,
  isDrop,
  isEditor,
  isLoading,
  isRadio,
  isReCaptcha,
  isSelect,
  isAsyncSelect,
  label,
  name,
  onClickIconRight,
  options = undefined,
  placeholder,
  renderBelowLabel,
  textHelperProps,
  textLabelProps,
  ...props
}) => {
  const { t } = useTranslation();
  const { xsDown } = useMediaQuery();

  const theme = useContext(ThemeContext);
  const { submitForm } = useFormikContext();
  const [{ value }, { touched, error }, { setValue, setTouched }] =
    useField(name);
  const [isFocused, setIsFocused] = useState(false);
  const hasError = canShowErrorUntouched
    ? error !== undefined
    : touched && error !== undefined;

  const containerProps = {
    ...(isDisabled || isLoading ? { isDisabled: true } : {}),
  };

  const onFocus = () => setIsFocused(true);

  const onBlur = () => {
    setIsFocused(false);
    setTouched(true);
  };

  const onChange = (newValue) => {
    let valueToSet = newValue;

    if (
      newValue === '' &&
      (canSetEmptyStringUndefined || canSetEmptyStringNull)
    ) {
      valueToSet = canSetEmptyStringUndefined ? undefined : null;
    }

    if (canSubmitOnChange) {
      (async () => {
        await setValue(valueToSet);

        setTimeout(submitForm, 100);
      })();
    } else {
      setValue(valueToSet);
    }

    if (doFinally) doFinally(valueToSet);
  };

  const onChangeBlur = (newValue) => {
    onChange(newValue);

    setTimeout(onBlur, 100);
  };

  const onDrop = (files) => {
    if (files[0]) {
      onChange(files[0]);
    }
  };

  const renderLabel = (textProps) =>
    (label || hasError) && (
      <>
        <Text
          variant={
            isFocused ||
            value ||
            placeholder ||
            isSelect ||
            isAsyncSelect ||
            isRadio ||
            isCheckbox ||
            isEditor
              ? 'tiny'
              : 'body'
          }
          {...textProps}
          {...textLabelProps}
          color={hasError ? 'danger' : textLabelProps?.color || 'grey1'}
        >
          {label}
          {hasError && `${label ? ' - ' : ''}${error}`}
        </Text>

        {renderBelowLabel && renderBelowLabel()}
      </>
    );

  const renderHelperText = () =>
    helper ? (
      <Text color='info' mt={1} variant='tiny' {...textHelperProps}>
        {helper}
      </Text>
    ) : null;

  if (isRadio || isCheckbox) {
    return (
      <Box {...containerProps} {...boxContainerProps}>
        <RadioCheckbox
          isArray={isArray}
          isDisabled={isDisabled}
          renderLabel={renderLabel}
          value={value}
          onChange={onChange}
          {...props}
          isCheckbox={isCheckbox}
          isLoading={isLoading}
          isRadio={isRadio}
          options={options}
        />

        {renderHelperText()}
      </Box>
    );
  }

  if (isColor) {
    return (
      <Box position='absolute' zIndex={1} {...props.boxContainerProps}>
        <ChromePicker
          disableAlpha
          color={value || '#000000'}
          onChangeComplete={(color) => onChange(color.hex)}
        />
      </Box>
    );
  }

  if (isEditor) {
    return (
      <Box {...containerProps} {...boxContainerProps}>
        {renderLabel({ mb: 1 })}

        <Editor
          onBlur={onBlur}
          onChange={(event, editor) => onChange(editor.getData())}
          {...props}
          data={value}
          disabled={isDisabled || isLoading}
        />
      </Box>
    );
  }

  if (isDrop) {
    return (
      <Box {...containerProps} {...boxContainerProps}>
        {renderLabel({ mb: 1 })}

        <Dropzone
          {...props}
          disabled={isDisabled || isLoading}
          value={value}
          onDrop={onDrop}
          onRemoveImage={() => onChange('')}
        />
      </Box>
    );
  }

  if (isReCaptcha) {
    return (
      <Box {...containerProps} {...boxContainerProps}>
        {renderLabel({ mb: 1 })}

        <ReCAPTCHA
          sitekey={ReCaptcha.siteKey}
          theme={theme.isDark ? 'dark' : 'light'}
          onChange={onChange}
        />
      </Box>
    );
  }

  return (
    <>
      <Box
        hasRadiusTop
        isFlex
        bg={
          hasAlternateBackground
            ? isFocused
              ? 'grey5'
              : 'grey6'
            : isFocused
            ? 'grey4'
            : 'grey5'
        }
        borderBottom={[2]}
        borderColor={hasError ? 'danger' : isFocused ? 'grey1' : 'grey2'}
        position='relative'
        style={{
          // Set font-size for library's that use 'inherit'
          fontSize: xsDown ? '16px' : '0.875rem',
        }}
        {...containerProps}
        {...boxContainerProps}
      >
        <Box
          hasRadiusBottomRight
          hasRadiusTopLeft
          bg={
            hasAlternateBackground
              ? isFocused
                ? 'grey5'
                : 'grey6'
              : isFocused
              ? 'grey4'
              : 'grey5'
          }
          pb='1px'
          pointerEvents='none'
          position='absolute'
          pt={
            isFocused || value || placeholder || isSelect || isAsyncSelect
              ? 2
              : 5
          }
          px={3}
        >
          {renderLabel()}
        </Box>

        {isDate ? (
          <DateInput
            onChange={onChangeBlur}
            {...props}
            isDisabled={isDisabled || isLoading}
            value={value}
          />
        ) : isSelect ? (
          <SelectInput
            onBlur={onBlur}
            onChange={onChangeBlur}
            onFocus={onFocus}
            {...props}
            isDisabled={isDisabled || isLoading}
            isLoading={isLoading}
            options={options}
            value={value}
          />
        ) : isAsyncSelect ? (
          <AsyncSelectInput
            {...props}
            isLoading={isLoading}
            loadOptions={options}
            placeholder={placeholder}
          />
        ) : (
          <TextInput
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.value)}
            onFocus={onFocus}
            {...props}
            disabled={isDisabled || isLoading}
            placeholder={placeholder}
            value={value || ''}
          />
        )}
        {hasCancelIcon &&
          (iconRightProps ||
            onClickIconRight ||
            (isDate && value && !isDisabled)) && (
            <Box
              m={3}
              mt='auto'
              onClick={
                onClickIconRight ||
                (isDate && value && !isDisabled
                  ? () => onChangeBlur(undefined)
                  : undefined)
              }
            >
              <Icon name='cancel' scale='small' {...iconRightProps} />
            </Box>
          )}

        {(hasSubmitButton || hasSubmitButtonSearch) && (
          <Button
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}
            borderTopLeftRadius={0}
            type='submit'
            {...(hasSubmitButtonSearch
              ? {
                  minWidth: 20,
                  icon: 'search',
                }
              : {
                  minWidth: 30,
                  text: t('submit'),
                })}
            {...buttonSubmitProps}
          />
        )}
      </Box>

      {renderHelperText()}
    </>
  );
};

export default Field;
