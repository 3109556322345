import echo from 'octiv-config/Echo';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const location = useLocation();
  const isConnected = useRef(false); // Tracks connection status

  useEffect(() => {
    const isAccessControlPage = location.pathname.includes('access-control');

    if (isAccessControlPage) {
      if (!isConnected.current) {
        echo.connect();
        isConnected.current = true; // Mark as connected
      }
    } else if (isConnected.current) {
      echo.disconnect();
      isConnected.current = false; // Mark as disconnected
    }
  }, [location.pathname]);

  return (
    <WebSocketContext.Provider value={null}>
      {children}
    </WebSocketContext.Provider>
  );
};
