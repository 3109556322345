import moment from 'moment';
import { Container } from 'octiv-components';
import Text from 'octiv-components/text/index';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import {
  useFinancesCreatePaystackSendCardLink,
  useFinancesCreateStripeConnectSendCardLink,
  useFinancesCreateUserInvoicesCharge,
  useFinancesFindSubscriptions,
} from 'octiv-hooks/requests/Finances';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormQuery from './FormQuery';
import Table from './Table';

export default () => {
  const { t } = useTranslation();

  const {
    selectedLocation,
    tenant: { locationOptions, isPaystack, isStripeConnect },
    toCurrency,
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
    billingDate: moment().toDate(),
    search: undefined,
    sort: 'user_name',
  });

  const subscriptionsFind = useFinancesFindSubscriptions({
    filter: query,
    paging: {
      perPage: -1,
    },
    sort: query.sort,
  });

  const financesChargeInvoice = useFinancesCreateUserInvoicesCharge({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const paystackSendCardLink = useFinancesCreatePaystackSendCardLink({
    meta: {
      enabled: isPaystack,
      useOnSuccessToast: true,
    },
  });

  const stripeConnectSendCardLink = useFinancesCreateStripeConnectSendCardLink({
    meta: {
      enabled: isStripeConnect,
      useOnSuccessToast: true,
    },
  });

  const sendCardOnboardingLink = isPaystack
    ? paystackSendCardLink
    : stripeConnectSendCardLink;

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'sendCardOnboardingLink':
        sendCardOnboardingLink.mutate({
          locationId: query?.locationId,
          userId: id,
        });
        break;

      case 'retryPayment':
        financesChargeInvoice.mutate({
          id,
        });
        break;
      default:
        break;
    }
  };

  const subscriptionsTotal = subscriptionsFind?.data?.data.reduce(
    (a, b) => a + Number(b.invoice.amount),
    0
  );

  return (
    <Container
      appBarProps={{
        title: t('subscriptions'),
        breadcrumbs: [t('accounts')],
      }}
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptions}
        onSubmit={(values) =>
          setQuery((prev) => ({
            ...values,
            sort: prev.sort,
          }))
        }
      />

      <br />

      <Table
        data={subscriptionsFind?.data?.data}
        isLoading={
          subscriptionsFind?.isFetching || financesChargeInvoice?.isFetching
        }
        onClickAction={onClickAction}
        onSortBy={(values) => setQuery((prev) => ({ ...prev, ...values }))}
      />

      {subscriptionsTotal !== 0 && !subscriptionsFind?.isFetching && (
        <Text mt={4} textAlign='right' variant='heading'>
          {`${t('total')}: ${toCurrency({
            value: subscriptionsTotal,
          })}`}
        </Text>
      )}
    </Container>
  );
};
