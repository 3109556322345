import { Container, Modal, Text } from 'octiv-components';
import { useQuery, useToggle } from 'octiv-hooks';
import { useLeadsCreateSignup } from 'octiv-hooks/requests/Leads';
import useWidgetTenant from 'octiv-hooks/useWidgetTenant';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FullscreenProcessingSpinner from '../components/FullscreenProcessingSpinner';
import WidgetHeader from '../components/Header';
import FormDetails from './FormDetails';

export default () => {
  const { t } = useTranslation();

  const tracking = useQuery('tracking');
  const isDemo = useQuery('isDemo');
  const isRequestDemo = isDemo === 'true';

  const [formValues, setFormValues] = useState({});
  const [resetForm, setResetForm] = useState(null);
  const [toggleTerms, setToggleTerms, resetToggleTerms] = useToggle();

  const { tenantId, locationOptions, ...tenant } = useWidgetTenant();

  const { isLoading: isCreatingLead, mutate: postLeadRequest } =
    useLeadsCreateSignup({
      meta: {
        useOnSuccessToast: true,
      },
      onSuccessToast: { title: t('submissionSuccessful') },
      onSuccess: (response) => {
        const { redirectUrl } = response;
        if (redirectUrl) {
          const values = formValues;

          const lead = {
            name: encodeURIComponent(values.name),
            surname: encodeURIComponent(values.surname),
            email: encodeURIComponent(values.email),
            mobile: encodeURIComponent(values.mobile),
            gender: encodeURIComponent(
              values.genderId
                ? values.gender === 1
                  ? 'male'
                  : 'female'
                : 'notSpecified'
            ),
            location: encodeURIComponent(
              locationOptions.find(({ value }) => value === values.locationId)
                .label
            ),
            notes: encodeURIComponent(values.notes),
          };

          window.location.assign(
            `${redirectUrl}${redirectUrl.includes('?') ? '&' : '?'}leadName=${
              lead.name
            }&leadSurname=${lead.surname}&leadEmail=${lead.email}&leadMobile=${
              lead.mobile
            }&leadGender=${lead.gender}&leadLocation=${lead.location}${
              values.notes ? `&leadNotes=${lead.notes}` : ''
            }${tracking ? `&tracking=${tracking}` : ''}`
          );
        } else {
          resetForm?.();
        }
      },
    });

  const onSubmit = (values, { resetForm: resetFormFunc }) => {
    setFormValues(values);
    setResetForm(resetFormFunc);

    postLeadRequest({
      tenantId,
      isRequestDemo,
      ...values,
    });
  };

  if (!tenantId) {
    return <FullscreenProcessingSpinner />;
  }

  return (
    <>
      {toggleTerms.isVisible && (
        <Modal isSidebar title={t('waiver')} onClose={resetToggleTerms}>
          {tenant.waiverTermsAndConditions && (
            <Text
              as='div'
              dangerouslySetInnerHTML={{
                __html: tenant.waiverTermsAndConditions,
              }}
            />
          )}
        </Modal>
      )}

      <Container isLoading={isCreatingLead}>
        <WidgetHeader tenant={tenant} />

        <FormDetails
          isLoading={isCreatingLead}
          isRequestDemo={isRequestDemo}
          isSignUpUseWaivers={tenant.isSignUpUseWaivers}
          locationOptions={locationOptions}
          onClickTerms={setToggleTerms}
          onSubmit={onSubmit}
        />
      </Container>
    </>
  );
};
