import * as Sentry from '@sentry/react';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import {
  ConnectComponentsProvider,
  ConnectPayments,
  ConnectPayouts,
} from '@stripe/react-connect-js';
import {
  Card,
  Container,
  Divider,
  ProcessingSpinner,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useFinancesCreateStripeConnectAccountSession } from 'octiv-hooks/requests/Finances';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import FormQuery from './FormQuery';

export default () => {
  const { t, i18n } = useTranslation();
  const { colors } = useContext(ThemeContext);

  const {
    selectedLocation,
    tenant: { locationOptions },
  } = useActiveUserTenant();

  const [query, setQuery] = useState({
    locationId: selectedLocation?.id,
  });

  const stripeConnectInstance = React.useRef();

  const financesStripeConnectAccountSession =
    useFinancesCreateStripeConnectAccountSession();

  const fetchClientSecret = React.useCallback(async () => {
    let clientSecret;

    try {
      const response = await financesStripeConnectAccountSession.mutateAsync({
        locationId: query.locationId,
      });

      clientSecret = response?.clientSecret;
      // eslint-disable-next-line no-empty
    } catch (error) {
      Sentry.captureException(error);
    }

    return clientSecret;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.locationId]);

  const stripeLocale = (() => {
    switch (i18n.language) {
      case 'en':
        return 'en-US';
      case 'fr':
        return 'fr-FR';
      case 'de':
        return 'de-DE';
      default:
        return 'en-US';
    }
  })();

  React.useEffect(() => {
    stripeConnectInstance.current = loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_CONNECT_PUBLISHABLE_KEY,
      fetchClientSecret,
      appearance: {
        variables: {
          actionPrimaryColorText: colors.primary,
          actionSecondaryColorText: colors.primary20,
          badgeBorderRadius: '6px',
          borderRadius: '6px',
          buttonBorderRadius: '6px',
          buttonPrimaryColorBackground: colors.primary,
          buttonPrimaryColorBorder: colors.primary,
          buttonPrimaryColorText: colors.primaryForeground,
          buttonSecondaryColorBackground: colors.grey6,
          buttonSecondaryColorBorder: colors.primary,
          buttonSecondaryColorText: colors.primary,
          colorBackground: colors.grey6,
          colorBorder: colors.grey1,
          colorDanger: colors.danger,
          colorPrimary: colors.primary,
          colorText: colors.font,
          fontFamily: '"Inter", sans-serif',
          fontSizeBase: '14px',
          formBorderRadius: '6px',
          formHighlightColorBorder: colors.primary,
          overlayBorderRadius: '6px',
          spacingUnit: '10px',
        },
      },
      locale: stripeLocale,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.locationId]);

  return (
    <Container
      appBarProps={{
        title: `${t('payouts')}`,
        breadcrumbs: [t('accounts')],
      }}
    >
      <FormQuery
        initialValues={query}
        locationOptions={locationOptions}
        onSubmit={setQuery}
      />

      <Divider pb={4} />

      {stripeConnectInstance.current &&
      financesStripeConnectAccountSession.data?.clientSecret ? (
        <ConnectComponentsProvider
          connectInstance={stripeConnectInstance.current}
        >
          <Card>
            <Text mb={2} variant='heading'>
              {t('recentPayouts')}
            </Text>
            <ConnectPayouts />
          </Card>

          <Divider pb={8} />

          <Card>
            <Text mb={2} variant='heading'>
              {t('recentPayments')}
            </Text>

            <ConnectPayments />
          </Card>
        </ConnectComponentsProvider>
      ) : (
        <ProcessingSpinner mx='auto' size={6} />
      )}
    </Container>
  );
};
