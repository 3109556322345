import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useBulkUploadCreateUsers = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();
      formData.append('tenantId', data.tenantId || tenantId);
      formData.append('csvFile', data.csvFile);

      return request({
        method: 'post',
        url: `/api/bulk-upload/users`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bulkUpload'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useBulkUploadCreateBankingDetails = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();
      formData.append('tenantId', data.tenantId || tenantId);
      formData.append('isUpload', true);
      formData.append('csvFile', data.csvFile);

      return request({
        method: 'post',
        url: `/api/bulk-upload/banking-details`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['bulkUpload'] });

      options?.onSuccess?.(...res);
    },
  });
};
