import { Box, FieldDebounced, Table, Text } from 'octiv-components';
import {
  getDateReadableDayMonthYear,
  getPaymentGatewayName,
} from 'octiv-utilities/Functions';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, regions, onSetLocationPayments, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: t('location'),
        accessor: 'name',
      },
      {
        Header: t('tenant'),
        id: 'tenantName',
        accessor: 'tenant',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: t('region'),
        id: 'region',
        Cell: ({ row: { original } }) => {
          return regions?.find?.((r) => r.id === original.tenant.regionId)
            ?.name;
        },
      },
      {
        Header: t('tenantAddedOn'),
        id: 'tenantAddedOn',
        accessor: 'tenant',
        Cell: ({ value }) =>
          getDateReadableDayMonthYear({ date: value?.createdAt }),
      },
      {
        Header: t('category'),
        accessor: 'category.name',
      },
      {
        Header: t('#members'),
        accessor: 'activeMembersCount',
      },
      {
        Header: t('healthProviders'),
        accessor: 'healthProviders',
        Cell: ({ value }) => value?.map((item) => item.name).join(', '),
      },
      {
        Header: t('amount'),
        id: 'amount',
        Cell: ({
          row: {
            original: {
              id,
              billingPaymentGatewayId,
              financePaymentToken,
              tenant,
            },
          },
        }) =>
          financePaymentToken ? (
            <Box py={2}>
              <FieldDebounced
                key={id}
                mb={1}
                placeholder={tenant?.tenantBillingCurrency?.name ?? ''}
                onChange={(value) =>
                  onSetLocationPayments({
                    locationId: id,
                    value,
                  })
                }
              />

              <Text as='span' color='grey1' mt={1} variant='caption'>
                {getPaymentGatewayName({
                  id: billingPaymentGatewayId,
                })}
              </Text>
            </Box>
          ) : (
            <>
              <Box>
                <Text as='span' color='danger'>
                  {t('noLocationBillingDetails')}
                </Text>
              </Box>

              <Box>
                <Text as='span' color='grey1' mt={1} variant='caption'>
                  {getPaymentGatewayName({
                    id: billingPaymentGatewayId,
                  })}
                </Text>
              </Box>
            </>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, regions]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
