import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useLocationsFind = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const {
    filter = {},
    paging = {},
    include = undefined,
    append = undefined,
  } = input || {};

  return useQuery(
    ['locations', 'find', input],
    () =>
      request({
        method: 'get',
        url: `/api/locations`,
        params: {
          filter: {
            tenantId: filter.tenantId || tenantId,
            regionId: filter.regionId,
            billingPaymentGatewayId: filter.billingPaymentGatewayId,
            paymentGatewayId: filter.paymentGatewayId,
            tenantStatusId: filter.tenantStatusId,
            healthProviderId: filter.healthProviderId,
            search: filter.search,
            isActive: filter.isActive,
          },
          include,
          append,
          ...paging,
        },
      }),
    options
  );
};

export const useLocationsCreate = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/locations`,
        data: {
          tenantId: data.tenantId || tenantId,
          name: data.name,
          prefix: data.prefix,
          businessName: data.businessName,
          timezoneId: data.timezoneId,
          categoryId: data.categoryId,
          canDebit: data.canDebit,
          billingPaymentGatewayId: data.billingPaymentGatewayId,
          paymentGatewayId: data.paymentGatewayId,
          sageMerchantAccountNumber: data.sageMerchantAccountNumber,
          sageAccountServiceKey: data.sageAccountServiceKey,
          sageDebitOrderServiceKey: data.sageDebitOrderServiceKey,
          threePeaksDevId: data.threePeaksDevId,
          threePeaksDevToken: data.threePeaksDevToken,
          threePeaksCref: data.threePeaksCref,
          healthProviderIds: data.healthProviderIds,
          additionalFeatures: data.additionalFeatures,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationsFindByIdExportParameters = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['locations', 'findByIdExportParameters', input],
    () =>
      request({
        method: 'get',
        url: `/api/locations/${id}/export-parameters`,
      }),
    options
  );
};

export const useLocationsUpdateExportParameters = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'put',
        url: `/api/locations/${id}/export-parameters`,
        data: {
          bankUserCode: data.bankUserCode,
          bankUserName: data.bankUserName,
          bankNominatedAccount: data.bankNominatedAccount,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationsFindByIdAttendanceCode = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['locations', 'findByIdAttendanceCode', input],
    () =>
      request({
        method: 'get',
        url: `/api/locations/${id}/attendance-code`,
      }),
    options
  );
};

export const useLocationsFindById = (input, options) => {
  const { id } = input || {};

  return useQuery(
    ['locations', 'findById', input],
    () =>
      request({
        method: 'get',
        url: `/api/locations/${id}`,
      }),
    options
  );
};

export const useLocationsUpdate = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      return request({
        method: 'post',
        url: `/api/locations/${id}`,
        data: {
          name: data.name,
          prefix: data.prefix,
          businessName: data.businessName,
          timezoneId: data.timezoneId,
          categoryId: data.categoryId,
          canDebit: data.canDebit === 1 ? 1 : 0,
          billingPaymentGatewayId: data.billingPaymentGatewayId,
          paymentGatewayId: data.paymentGatewayId,
          sageMerchantAccountNumber: data.sageMerchantAccountNumber,
          sageAccountServiceKey: data.sageAccountServiceKey,
          sageDebitOrderServiceKey: data.sageDebitOrderServiceKey,
          threePeaksDevId: data.threePeaksDevId,
          threePeaksDevToken: data.threePeaksDevToken,
          threePeaksCref: data.threePeaksCref,
          healthProviderIds: data.healthProviderIds,
          additionalFeatures: data.additionalFeatures,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationsDelete = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id } = input || {};

      return request({
        method: 'delete',
        url: `/api/locations/${id}`,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useLocationsCreateProcessPayments = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/locations/process-payments`,
        data: { billingAmounts: data.billingAmounts },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });

      options?.onSuccess?.(...res);
    },
  });
};
