import { ProcessingBar, Table, Text } from 'octiv-components';
import { useFinancesCreateStripeConnectAccountLinkUrl } from 'octiv-hooks/requests/Finances';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const STRIPE_ONBOARDING_STATUS = {
  notOnboarded: 'notOnboarded',
};

export default ({ data, onClickAction, ...props }) => {
  const { t } = useTranslation();

  const financesCreateStripeConnectAccountLinkUrl =
    useFinancesCreateStripeConnectAccountLinkUrl({
      onSuccess: (response) => {
        window.location.assign(response);
      },
    });

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('location'),
        accessor: 'location',
        Cell: ({ value }) => value.name,
      },
      {
        Header: t('status'),
        Cell: ({
          row: {
            original: { onboardingStatus },
          },
        }) => t(`stripeConnectOnboardingStatuses.${onboardingStatus}`),
      },
      {
        Header: t('action'),
        Cell: ({
          row: {
            original: { location, onboardingStatus, hasOnboarded },
          },
        }) => (
          <>
            {(onboardingStatus === STRIPE_ONBOARDING_STATUS.notOnboarded ||
              !hasOnboarded) && (
              <Text
                as='span'
                color='danger'
                mr={2}
                onClick={
                  financesCreateStripeConnectAccountLinkUrl.isLoading
                    ? null
                    : () => {
                        financesCreateStripeConnectAccountLinkUrl.mutate({
                          locationId: location.id,
                        });
                      }
                }
              >
                {onboardingStatus === STRIPE_ONBOARDING_STATUS.notOnboarded
                  ? t('onboardNow')
                  : t('completeOnboarding')}
              </Text>
            )}

            {onboardingStatus !== STRIPE_ONBOARDING_STATUS.notOnboarded && (
              <Text
                as='span'
                color='info'
                onClick={() =>
                  onClickAction({
                    action: 'manageAccountCapabilities',
                    locationId: location.id,
                  })
                }
              >
                {t('manageAccountCapabilities')}
              </Text>
            )}
          </>
        ),
      },
    ],
    [t, financesCreateStripeConnectAccountLinkUrl, onClickAction]
  );

  return (
    <>
      {financesCreateStripeConnectAccountLinkUrl.isLoading && <ProcessingBar />}
      <Table {...props} columns={tableColumns} data={tableData} />{' '}
    </>
  );
};
